define("ticust-s2h-web-application/initializers/mixins/ti-web-shared/toastMixin", ["exports", "ti-web-shared/mixins/toastMixin"], function (_exports, _toastMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'mixins/ti-web-shared/toastMixin',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _toastMixin.default.reopen({
    closeLabelKey: 'shared.components.modals.title.closeButton',
    validStrings: ['INVALID_TO_HEADER'],
    errorCode: undefined,
    showErrorAndLog: function showErrorAndLog(intlKey, message, error, params) {
      if (error && error.response) {
        this.set('errorCode', error.response ? error.response.code : error);
      }
      this.showErrorToast(intlKey, params);
      this.get('logger').error(message, error);
    },
    showErrorToast: function showErrorToast(intlKey, params) {
      this._setupToastOptions(intlKey, "Error", params);
    },
    _createToast: function _createToast(intlKey, type, options, addAlertRole, addCloseLabel, setFocusOnCloseButton, params) {
      var alertDialog = null;
      if (type === 'Error') {
        alertDialog = this.get('validStrings').includes(this.get('errorCode')) ?
        // eslint-disable-next-line max-len
        this.get('toast').error(this.get('intl').t(intlKey, params) + ' (code ' + this.get('errorCode') + ')', '', options) : this.get('toast').error(this.get('intl').t(intlKey, params), '', options);
      } else if (type === 'Success') {
        alertDialog = this.get('toast').success(this.get('intl').t(intlKey, params), '', options);
      } else if (type === 'Warning') {
        alertDialog = this.get('toast').warning(this.get('intl').t(intlKey, params), '', options);
      } else {
        alertDialog = this.get('toast').info(this.get('intl').t(intlKey, params), '', options);
      }
      if (alertDialog) {
        this._addAttributes(alertDialog, addAlertRole, addCloseLabel, setFocusOnCloseButton);
      }
    }
  });
});