define("ticust-s2h-web-application/initializers/ticust-web-common/side-panel/side-bar-profile", ["exports", "ticust-web-common/components/side-panel/side-bar-profile"], function (_exports, _sideBarProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
  _sideBarProfile.default.reopen({
    ajax: Ember.inject.service(),
    _invalidateSession: function _invalidateSession() {
      var _this = this;
      var settingsConfig = this.get('settingConfig.signOut');
      var company = this.get('identity.userCompany');
      var rootUrl = this.get('rootURL');
      this.get('session').invalidate().then(function () {
        //SDH OVERRIDE - BACKEND TOKEN INVALIDATION :
        _this.get('ajax').raw('/api/customer/authentication/sso/logout', {
          method: 'POST'
        }).then(function () {
          if (settingsConfig.type !== 'internal') {
            // Redirect to external route
            window.location.href = settingsConfig.route;
          } else {
            //SDH OVERIDE - DO NOT REDIRECT TO LOGIN PAGE, BUT TO LOGOUT PAGE : 
            window.location.href = window.location.origin + rootUrl + 'auth/logout?company=' + company;
          }
        });
      });
    }
  });
});