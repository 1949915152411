define("ticust-s2h-web-application/translations/fr-fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "authentication": {
      "error": {
        "AUTHENTICATION_FAILED": "L'authentification a échoué",
        "BAD_CREDENTIALS": "Erreur: nom d'utilisateur ou mot de passe incorrect",
        "UNKNOWN": "Une erreur inconnue s'est produite. Veuillez réessayer ultérieurement"
      },
      "login": {
        "company": "Organisation",
        "forgot": "J'ai perdu mes données de connexion",
        "message-info": {
          "error": {
            "invalid-form": "Au moins un champ du formulaire n'est pas rempli.",
            "invalid-input": {
              "company": "L'organisation est obligatoire.",
              "password": "Le mot de passe est obligatoire.",
              "username": "Le nom d'utilisateur est obligatoire."
            }
          }
        },
        "password": "Mot de passe",
        "title": "Identification utilisateur",
        "username": "Nom d'utilisateur",
        "valid": "Me connecter"
      },
      "prospect": {
        "create": {
          "birthDate": "Date de naissance",
          "company": "Organisation",
          "email": "Adresse électronique",
          "firstName": "Prénom",
          "lastName": "Nom de famille",
          "link": "Rejoignez-nous",
          "user": {
            "password": "Mot de passe",
            "title": "Créer un mot de passe"
          }
        },
        "message": {
          "save": {
            "error": "Une erreur s'est produite lors de la sauvegarde du prospect. Veuillez réessayer plus tard.",
            "has-no-company": "Cette organisation n'existe pas",
            "info": "Le prospect a été enregistré.",
            "user": "L'utilisateur a été créé.",
            "wrong-email": "Cet e-mail est déjà utilisé. Veuillez en utiliser un autre."
          },
          "validation": {
            "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
          }
        },
        "new": {
          "buttons": {
            "cancel": "Annuler",
            "confirm": "Confirmer",
            "create": "Valider"
          }
        },
        "title": "Nouvel utilisateur"
      },
      "pwdreinit": {
        "cancel": "Annuler",
        "email": "Email",
        "header": "Veuillez saisir votre adresse email pour recevoir le lien de réinitialisation",
        "message-info": {
          "error": {
            "invalid-form": "Le formulaire est mal remplis.",
            "invalid-input": {
              "email": "L'email saisie est incorrecte."
            }
          },
          "info": {
            "success": "Si un compte est associé à l'adresse '{email}', vous recevrez un courriel contenant un lien pour réinitialiser votre mot de passe."
          }
        },
        "title": "Réinitialisation du mot de passe",
        "validate": "Valider"
      },
      "pwdrenew": {
        "email": "Email",
        "firstnewpassword": "Veuillez saisir votre nouveau mot de passe",
        "message-info": {
          "error": {
            "echec": "Votre mot de passe n'a pas pu être modifié.",
            "invalid-form": "Le formulaire est mal rempli. Veuillez vérifier les champs.",
            "invalid-input": {
              "firstPassword": "Le premier mot de passe n'est pas rempli.",
              "secondPassword": "Le second mot de passe n'est pas rempli."
            }
          },
          "info": {
            "success": "Votre mot de passe a été correctement changé."
          }
        },
        "password": "Mot de passe",
        "secondnewpassword": "Veuillez confirmer votre nouveau mot de passe",
        "title": "Changement de mot de passe",
        "validate": "Valider"
      }
    },
    "common": {
      "application": {
        "title": "Trusted Interactions"
      },
      "file-upload": {
        "error": {
          "addFile": "Une erreur est apparue, la pièce jointe n'a pas été ajoutée. Veuillez réessayer ultérieurement.",
          "contentType": "Le type de la pièce jointe n'est pas autorisé.",
          "length": "La taille de la pièce jointe est supérieure à la taille maximale autorisée."
        }
      },
      "filter": {
        "datePrefix": "Date",
        "dateTimeLocale": "fr",
        "hide": "Masquer",
        "lessCriteria": "Voir moins de critères",
        "message-info": {
          "error": {
            "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
          }
        },
        "moreCriteria": "Voir plus de critères",
        "preset": "Prédéfinis :",
        "reset": "Réinitialiser les critères",
        "search": "Lancer la recherche",
        "show": "Afficher"
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Conseiller } CLIENT {Interlocuteur } other {}}{gender, select, MR {Mr }  MME {Mme } other {}}{lastname} {firstname}",
        "unknown-person": "Personne inconnue"
      },
      "message-info": {
        "error": {
          "403": "Vous n'avez pas les droits pour voir cette page.",
          "unknown": "Une erreur est apparue au chargement de la page. Veuillez réessayer ultérieurement."
        }
      },
      "notification": {
        "CUST_RESET_PASSWORD": {
          "label": "Changement de mot de passe",
          "variables": {
            "URL_LINK": "URL_LIEN"
          }
        },
        "LOWER_CONFIDENTIALITY_ACCEPTED": {
          "label": "Acceptation abaissement confidentialité Conversation",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "CIVILITE_INTERLOCUTEUR",
            "INTERLOCUTOR_FIRSTNAME": "PRENOM_INTERLOCUTEUR",
            "INTERLOCUTOR_LASTNAME": "NOM_INTERLOCUTEUR",
            "INTERLOCUTOR_NATURE": "NATURE_INTERLOCUTEUR"
          }
        },
        "LOWER_CONFIDENTIALITY_REFUSED": {
          "label": "Refus abaissement confidentialité Conversation",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "CIVILITE_INTERLOCUTEUR",
            "INTERLOCUTOR_FIRSTNAME": "PRENOM_INTERLOCUTEUR",
            "INTERLOCUTOR_LASTNAME": "NOM_INTERLOCUTEUR",
            "INTERLOCUTOR_NATURE": "NATURE_INTERLOCUTEUR"
          }
        },
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": {
          "label": "Nouveau message Interlocuteur reçu",
          "variables": {
            "CONVERSATION_SUBJECT": "OBJET_CONVERSATION",
            "MESSAGE_EXTRACT": "EXTRAIT_MESSAGE",
            "URL_LINK": "URL_LIEN"
          }
        }
      },
      "page-error-message": "Une erreur inconnue est survenue. Merci de réessayer plus tard ou de contacter le service de support",
      "pagination-component": {
        "currentPage": "Page actuelle",
        "firstPage": "Première page",
        "lastPage": "Dernière page",
        "nextPage": "Page suivante",
        "page": "Page",
        "previousPage": "Page précédente"
      },
      "panel-collapse": {
        "close": "Replier",
        "open": "Déplier",
        "title-default": "Erreur titre du panel manquant"
      },
      "result-list": {
        "action": {
          "show-grid": "Affichage en vignettes",
          "show-list": "Affichage en lignes"
        },
        "results": {
          "error": "Une erreur est survenue pendant le chargement. Veuillez réessayer ultérieurement.",
          "none": "Aucun résultat disponible"
        },
        "sort": {
          "by": "Trier par"
        }
      },
      "selectors": {
        "actor-selector": {
          "error": "Une erreur est apparue pendant la recherche.",
          "loading": "Chargement en cours...",
          "no-results": "Aucun destinataire ne correspond à cette recherche",
          "required": "Un destinataire doit être sélectionné"
        },
        "theme-selector": {
          "error": "Une erreur est apparue pendant la recherche.",
          "loading": "Chargement en cours...",
          "no-results": "Aucun thème ne correspond à cette recherche",
          "required": "Un thème doit être sélectionné"
        }
      },
      "sidemenu": {
        "label": "Menu",
        "minimize": "Réduire le menu",
        "pulldown": "Dérouler le menu",
        "search": "Rechercher"
      },
      "spellchecker": {
        "languages": {
          "en": "Anglais",
          "fr": "Français"
        },
        "message-info": {
          "error": {
            "unknown": "Une erreur est apparue."
          }
        }
      },
      "variables": {
        "notificationCommon": {
          "EVENT_DATE": "DATE_EVT",
          "RECIPIENT_CIVILITY": "CIVILITE_DESTINATAIRE",
          "RECIPIENT_FIRSTNAME": "PRENOM_DESTINATAIRE",
          "RECIPIENT_LASTNAME": "NOM_DESTINATAIRE"
        }
      }
    },
    "common-projects": {
      "closed": "Projet Fermé",
      "components": {
        "annex-area-button": {
          "openConversation": {
            "buttonLabel": "Voir la conversation",
            "title": "Conversation"
          }
        },
        "audit-component": {
          "results": {
            "error": "Une erreur de chargement est survenue",
            "loading": "Chargement en cours..."
          },
          "table": {
            "no-result": "Aucun résultat"
          }
        },
        "buttons": {
          "close": "Fermer"
        },
        "file-uploader-label": "Sauvegarder",
        "file-uploading": "Sauvegarde en cours ...",
        "header": "Historique",
        "project-nature-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement des résultats...",
          "no-results": "Aucune entité correspond à ce terme",
          "required": "Vous devez sélectionner une entité"
        },
        "table": {
          "action": "Action",
          "date": "Date",
          "newValue": "Nouvelle valeur",
          "no-result": "Aucun résultat",
          "oldValue": "Ancienne valeur",
          "user": "Utilisateur"
        }
      },
      "document": {
        "audit": {
          "action": {
            "fileInvalid": "Marqué comme invalide",
            "fileValid": "Marqué comme valide",
            "init": "Initialisation",
            "status": "Statut changé",
            "uploaded": "Dépôt"
          },
          "button": {
            "close": "Fermer"
          },
          "column": {
            "action": "Action",
            "actor": "Conseiller",
            "date": "Date",
            "detail": "Détail",
            "type": "Type"
          },
          "userType": {
            "ADVISER": "Conseiller",
            "CLIENT": "Client"
          }
        },
        "button": {
          "add": "Ajouter un nouveau fichier",
          "audit": "Voir l'historique du document",
          "cancel": "Annuler",
          "copy": "Ajouter un fichier à partir d'un fichier existant",
          "create": {
            "copy": "Copier un fichier",
            "new": "Document vide",
            "upload": "Dépôt d’un fichier"
          },
          "download": "Télécharger tous les fichiers du document",
          "edit": {
            "show": "Editer"
          },
          "hideButtons": "Masquer tous les boutons pour le document et ses fichiers.",
          "info": {
            "hide": "Cacher les informations supplémentaires du document",
            "show": "Voir plus d'informations sur le document"
          },
          "markAsNotNeeded": "Déjà présent",
          "markAsNotValid": "Marquer le document comme Non Conforme",
          "mergeFiles": "Merge files",
          "save": "Valider",
          "showButtons": "Afficher tous les boutons pour le document et ses fichiers.",
          "undoAsNotNeeded": "Annuler déjà présent",
          "upload": "Ajouter un fichier"
        },
        "create": {
          "new": {
            "title": "Nouveau Document"
          },
          "title": "Ajouter un nouveau document"
        },
        "fields": {
          "description": "Description",
          "nature": "Nature",
          "providerType": "Fournisseur",
          "signatureRequired": "Signature requise",
          "title": "Nom"
        },
        "message-info": {
          "error": {
            "duplicate-title": "Le titre du document existe déjà.",
            "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer.",
            "markAsNotNeeded": "Une erreur s'est produite lors de la mise à jour du document en tant que document déjà présent, veuillez réessayer plus tard.",
            "markAsNotValid": "Une erreur est apparue, le document n'a pas été marqué comme Non Conforme. Veuillez réessayer ultérieurement.",
            "markAsValid": "Une erreur est apparue, le document n'a pas été marqué comme Conforme. Veuillez réessayer ultérieurement.",
            "save": "Une erreur est apparue pendant la sauvegarde du document. Veuillez réessayer ultérieurement.",
            "undoNotNeeded": "Une erreur s'est produite lors du retour à l'état Déjà présent, veuillez réessayer plus tard."
          },
          "info": {
            "markAsNotNeeded": "Le document a été marqué comme étant déjà présent.",
            "markAsNotValid": "Le document a été marqué comme Non Conforme",
            "markAsValid": "Le document a été marqué comme Conforme",
            "onlyAdviserAllowed": "* Seul le document avec le fournisseur du conseiller peut être défini comme signature requise",
            "save": "Le document a été sauvegardé.",
            "undoNotNeeded": "Le document Statut Déjà Présent a été annulé."
          }
        },
        "nature": {
          "ADVERTISEMENT": "Publicitaire",
          "BANK_STATEMENT": "Relevé bancaire",
          "BIRTH_CERTIFICATE": "Acte de naissance",
          "COMPROMISE_SALE": "Compromis de vente",
          "IBAN": "IBAN",
          "IDENTIFICATION": "Pièce d'identité",
          "INSURANCE": "Assurance",
          "LOAN_OFFER": "Offre de prêt",
          "PAYSLIP": "Fiche de paie",
          "POLICE_RECORD": "Casier judiciaire",
          "TAX_NOTICE": "Avis d'imposition",
          "UNKNOWN": "Inconnue",
          "label": "Nature : "
        },
        "providerType": {
          "ACTOR": "Conseiller",
          "INTERLOCUTOR": "Client",
          "UNKNOWN": "Inconnu",
          "label": "Fournisseur : "
        },
        "refusedReason": {
          "BLURRY": "Le document est flou.",
          "ERROR": "Le document est hors sujet.",
          "ILLEGIBLE": "Le document est illisible.",
          "label": "Motif du refus :"
        },
        "status": {
          "KO": "Non conforme",
          "NOT_NEEDED": "Déjà présent",
          "OK": "Conforme",
          "TO_ACKNOWLEDGE": "A valider",
          "TO_UPLOAD": "Nouveau",
          "UPLOADED": "Téléchargé"
        },
        "statusMessage": {
          "KO": "Ce document a été marqué comme Non Conforme. Veuillez soumettre un autre fichier s'il vous plait.",
          "NOT_NEEDED": "Ce document est déjà présent.",
          "TO_ACKNOWLEDGE": "Ce document doit être validé.",
          "TO_UPLOAD": "Ce document doit être déposé."
        },
        "type": {
          "FREE": "Libre",
          "STRUCTURED": "Structuré",
          "UNKNOWN": "Inconnu",
          "label": "Type de document : "
        }
      },
      "file": {
        "button": {
          "delete": "Supprimer le fichier",
          "download": "Télécharger le fichier",
          "markAsNotValid": "Marquer le fichier comme Non Conforme",
          "markAsValid": "Marquer le fichier comme Conforme",
          "preview": "Prévisualiser le fichier",
          "sign": "Signez ce fichier"
        },
        "help": {
          "signature-required": "Le dossier doit être signé",
          "signed": "Le dossier est déjà signé"
        },
        "message-info": {
          "error": {
            "delete": "Une erreur est apparue, le fichier n'a pas été supprimé. Veuillez réessayer ultérieurement.",
            "get-e-signiture-url": "Une erreur s'est produite lors de l'obtention de l'URL pour signer le fichier. Veuillez réessayer ultérieurement.",
            "mark-as-not-valid": "Une erreur est apparue, le fichier n'a pas été marqué comme Non Conforme. Veuillez réessayer ultérieurement.",
            "mark-as-valid": "Une erreur est apparue, le fichier n'a pas été marqué comme Conforme. Veuillez réessayer ultérieurement."
          },
          "info": {
            "delete": "Le fichier a été supprimé.",
            "mark-as-not-valid": "Le fichier a été marqué comme Non Conforme.",
            "mark-as-valid": "Le fichier a été marqué comme Conforme.",
            "pop-up": {
              "delete": "Voulez-vous vraiment supprimer ce fichier ?"
            }
          }
        },
        "status": {
          "KO": "Invalide",
          "OK": "Valide",
          "TO_SIGN": "Pour signer",
          "UPLOADED": "En validation"
        }
      },
      "file-upload": {
        "error": {
          "contentType": "Ce type de fichier n'est pas autorisé."
        },
        "info": {
          "uploadSucess": "Le fichier a été téléchargé."
        }
      },
      "folder": "Dossier",
      "list": {
        "tabs": {
          "personal": "Personnels",
          "shared": "Partagés"
        },
        "title": "Projets de {interlocutorName}"
      },
      "modal-copy": {
        "buttons": {
          "cancel": "Annuler",
          "copy": "Copier"
        },
        "document": "Sélectionner le document où copier",
        "file": "Choisir le fichier à copier",
        "label": {
          "search": "Rechercher"
        },
        "message-info": {
          "error": {
            "post-400": "Une erreur est survenue lors de la copie du fichier, veuillez vérifier le statut du document ou que vous ne possédez pas un fichier portant ce nom.",
            "unknown": "Une erreur est survenue lors de la copie du fichier. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "{nb, plural, =1 {Le fichier copié a été enregistré.} other {Les fichiers copiés ont été enregistrés.}}",
            "saveAttachment": "Le fichier copié a été enregistré.",
            "uploading": "Le fichier est en cours de téléchargement..."
          }
        }
      },
      "modal-merge": {
        "buttons": {
          "cancel": "Annuler",
          "confirm": "Confirmer",
          "move-down": "Descendre",
          "move-up": "Monter",
          "preview": "Pré visualiser"
        },
        "filename": "Nom du fichier de destination : ",
        "header": "Fusion de fichiers",
        "list": "Liste de fichiers à fusionner : ",
        "message-info": {
          "error": {
            "generic": "Une erreur est produite lors de la fusion du fichier. Veuillez réessayer ultérieurement."
          },
          "info": {
            "info": "Certains fichiers peuvent ne pas être fusionnés.",
            "loading": "Le fichier est en cours de fusion, veuillez patienter...",
            "saved": "Le fichier a été fusionné et sauvegardé."
          },
          "invalid-input": {
            "filename-duplicate": "Le nom de fichier existe déjà dans le document.",
            "filename-empty": "Le nom de fichier ne peut pas être vide.",
            "filename-long": "Le nom du fichier est trop long.",
            "list-empty": "Il faut sélectionner au moins deux fichiers.",
            "list-too-large": "Le poids de tous les fichiers est trop élevé."
          }
        }
      },
      "modal-share": {
        "buttons": {
          "cancel": "Annuler",
          "save": "Sauvegarder"
        },
        "message-info": {
          "error": {
            "loading": "Une erreur est apparue pendant le chargement des interlocuteurs de confiance. Veuillez réessayer ultérieurement.",
            "save": "Une erreur est apparue, le partage n'a pas été enregistré. Veuillez réessayer ultérieurement."
          },
          "info": {
            "empty-result": "Vous ne possédez pas d'interlocuteur de confiance.",
            "save": "Les informations de partage ont été sauvegardées."
          }
        },
        "placeholder": "Droits",
        "title": "Modification des droits du projet"
      },
      "modal-upload": {
        "message-info": {
          "error": {
            "failed-due-bad-request": "Une erreur est apparue pendant le téléchargement du fichier. s'il vous plaît vérifier la taille ou le type du fichier.",
            "failed-to-upload": "Une erreur est apparue pendant le téléchargement du fichier. Veuillez réessayer ultérieurement.",
            "post-400": "Une erreur est survenue lors du télechargement du fichier, veuillez vérifier le statut du document ou que vous ne possédez pas un fichier portant ce nom.",
            "unknown": "Une erreur est survenue lors du télechargement du fichier. Veuillez réessayer ultérieurement.",
            "wrong-format": "Le format d'un fichier pour lequel une signature est requise ne peut être qu'un PDF"
          },
          "info": {
            "file-uploading": "Sauvegarde en cours ...",
            "save": "Le fichier a été téléchargé."
          }
        }
      },
      "page-error-message": "Une erreur inconnue est survenue. Merci de réessayer plus tard ou de contacter le service de support",
      "preview-component": {
        "buttons": {
          "close": "Fermer"
        },
        "error": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
        "loading": "Chargement en cours...",
        "title": {
          "filedBy": "Déposé par :",
          "filedOn": "Déposé le :"
        }
      },
      "project": {
        "assignation": "Ce projet est géré par : ",
        "audit": {
          "action": {
            "CREATE": "Création du projet",
            "SHARED_INTERLOCUTOR_ADD": "Ajout de l'interlocuteur {interlocutorName} en {newProfileName}",
            "SHARED_INTERLOCUTOR_REMOVE": "Retrait de l'interlocuteur {interlocutorName}",
            "SHARED_INTERLOCUTOR_UPDATE": "Modification de l'interlocuteur {interlocutorName} de {previousProfileName} à {newProfileName}",
            "STATUS_CLOSED": "Clôture du projet",
            "STATUS_REOPENED": "Réouverture du projet",
            "UPDATE": "Mise à jour du projet",
            "auditToAdviser": "Envoi de la notification au conseiller {receiverName}",
            "auditToCustomer": "Envoi d'un avis au client {receiverName}"
          },
          "button": {
            "close": "Fermer"
          },
          "column": {
            "action": "Action",
            "date": "Date",
            "detail": "Détail",
            "sender": "Auteur"
          }
        },
        "button": {
          "audit": "Voir l'historique de ce projet",
          "back": "Revenir à la liste des projets",
          "close": "Clore le projet",
          "download": "Télécharger le projet",
          "edit": "Editer le projet",
          "hideInformations": "Réduire les informations",
          "notify-to-adviser": "Informer mon Conseiller que mon projet a été mis à jour",
          "notify-to-customer": "Informer mon Client que son projet a été mis à jour",
          "open": "Ouvrir le projet",
          "openConversation": "Ouvrir la conversation correspondante",
          "reopen": "Réouvrir le projet",
          "share": "Gérer la publication du projet",
          "showInformations": "Voir plus d'informations"
        },
        "closedDate": "Fermé le",
        "createdBy": "Par",
        "creationDate": "Créé le",
        "finishDate": "Fin prévisionnelle au",
        "lastUpdateDate": "Modifié le {date} par {name}",
        "message-error": {
          "download": "Une erreur est apparue, le projet n'a pas été téléchargé. Veuillez réessayer ultérieurement."
        },
        "message-info": {
          "error": {
            "LIMITED_CLICK_FOR_ADVISER": "Vous avez déjà notifié votre client il y a moins de {limit, plural, =1 {# heure} other {# heures}}.",
            "LIMITED_CLICK_FOR_CUSTOMER": "Vous avez déjà notifié votre conseiller il y a moins de {limit, plural, =1 {# heure} other {# heures}}.",
            "close": "Une erreur est apparue, le fichier n'a été pas clos. Veuillez réessayer ultérieurement.",
            "reopen": "Une erreur est apparue, le fichier n'a pas été ré-ouvert. Veuillez réessayer ultérieurement.",
            "unknown": "Une erreur s'est produite lors de l'envoi de la notification, veuillez réessayer plus tard."
          },
          "info": {
            "close": "Le projet a été clos.",
            "notify-update": "La notification a été envoyée.",
            "pop-up": {
              "close": "Voulez-vous vraiment clore ce projet ?",
              "reopen": "Voulez-vous vraiment ré-ouvrir ce projet ?"
            },
            "reopen": "Le projet a été ré-ouvert."
          }
        },
        "new": "Nouveau",
        "reopenDate": "Réouvert le",
        "shared": {
          "byMe": "Ce projet est actuellement publié à {to} avec le profil : ",
          "not": "Ce projet n'est actuellement pas publié à d'autres clients.",
          "right": {
            "FULL_ACCESS": "Accès total",
            "NO_ACCESS": "Aucun accès",
            "READ_ONLY": "Lecture seule"
          },
          "toMe": "Ce projet a été publié par {from} avec le profil : "
        },
        "statistics": {
          "messages": {
            "KO": "{nb, plural, =1 {# document rejeté} other {# documents rejetés}}",
            "OK": "{nb, plural, =1 {# document approuvé} other {# documents approuvés}}",
            "TO_ACKNOWLEDGE": "{nb, plural, =1 {# document} other {# documents}} à valider",
            "UPLOADED": "{nb, plural, =1 {# document téléchargé} other {# documents téléchargés}}"
          }
        },
        "type": {
          "FREE": "Libre",
          "STRUCTURED": "Structuré"
        }
      },
      "project-card": {
        "invalid-documents": "{numDocs, plural, =1 {# document a été marqué comme invalide} other {# documents ont été marqués comme invalides}}",
        "project-closed-alt": "Projet fermé",
        "project-shared-alt": "Projet partagé"
      },
      "project-element-list": {
        "sort": {
          "creationDate": {
            "asc": "Les plus anciens",
            "desc": "Les plus récents"
          },
          "lastUpdateDate": {
            "asc": "Mise à jour la plus ancienne",
            "desc": "Mise à jour la plus récente"
          },
          "sortBy": "Trier par :"
        },
        "status-filters": {
          "all": "Tous",
          "all-help": "Tous les projets",
          "closed": "Fermés",
          "closed-help": "Projets clôturés",
          "opened": "Ouverts",
          "opened-help": "Projets ouverts"
        }
      },
      "project-group-title": {
        "button": {
          "close": "Replier",
          "open": "Déplier"
        }
      },
      "project-part": {
        "close": "Fermer cette section",
        "open": "Ouvrir cette section"
      },
      "project-part-validator": {
        "error": {
          "duplicate-title": "Le nom du template de dossier existe déjà pour ce projet."
        }
      },
      "project-template-duplicate-validator": {
        "error": {
          "duplicate-title": "Le libellé du modèle de projet existe déjà."
        }
      },
      "project-template-part-document-validator": {
        "error": {
          "duplicate-title": "Le nom du template de document existe déjà pour ce dossier."
        }
      },
      "selectors": {
        "document-nature-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        },
        "project-nature-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        },
        "project-part-document-file-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        },
        "project-part-document-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        }
      },
      "side-menu": {
        "new-project": "Nouveau Projet",
        "projects": "Projets"
      },
      "title": "Projet"
    },
    "common-projects.selectors.actor-selector.error": "Une erreur est apparue, la recherche n'a pas été réalisée. Veuillez réessayer ultérieurement.",
    "common.application.title": "Trusted Interactions",
    "common.file-upload.error.addFile": "Une erreur est apparue, la pièce jointe n'a pas été ajoutée. Veuillez réessayer ultérieurement.",
    "common.file-upload.error.contentType": "Le type de la pièce jointe n'est pas autorisé.",
    "common.file-upload.error.length": "Le fichier que vous souhaitez télécharger est trop volumineux.",
    "common.message-info.error.403": "Vous n'avez pas les droits pour voir cette page.",
    "common.message-info.error.unknown": "Une erreur est survenue. Merci de réessayer ultérieurement.",
    "common.page-error-message": "Une erreur est survenue. Merci de réessayer ultérieurement.",
    "common.pagination-component.currentPage": "Page actuelle",
    "common.pagination-component.firstPage": "Première page",
    "common.pagination-component.lastPage": "Dernière page",
    "common.pagination-component.nextPage": "Page suivante",
    "common.pagination-component.page": "Page",
    "common.pagination-component.previousPage": "Page précédente",
    "common.result-list.results.error": "Une erreur est apparue au chargement de la page. Veuillez vérifier et réessayer ultérieurement.",
    "common.result-list.results.none": "Aucun résultat",
    "common.result-list.sort.by": "Trier par",
    "common.sidemenu.label": "Menu",
    "common.sidemenu.minimize": "Réduire le menu",
    "common.sidemenu.pulldown": "Dérouler le menu",
    "common.sidemenu.search": "Rechercher",
    "common.spellchecker.languages.de": "Allemand",
    "common.spellchecker.languages.en": "Anglais",
    "common.spellchecker.languages.es": "Espagnol",
    "common.spellchecker.languages.fr": "Français",
    "common.spellchecker.languages.it": "Italien",
    "common.spellchecker.languages.nl": "Néérlandais",
    "common.spellchecker.languages.pt": "Portugais",
    "common.spellchecker.message-info.error.unknown": "Une erreur est survenue. ",
    "common.variables.notificationCommon.EVENT_DATE": "EVENT_DATE",
    "common.variables.notificationCommon.RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
    "common.variables.notificationCommon.RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
    "common.variables.notificationCommon.RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME",
    "disconnection.text.explanation": "Votre session de messagerie sécurisée est maintenant terminée, vous pouvez fermer cette page.",
    "disconnection.text.reminder": "Si vous souhaitez accéder de nouveau à votre messagerie sécurisée, veuillez utiliser le lien d’accès depuis votre espace assuré ou votre application mobile ",
    "disconnection.title": "Déconnexion de votre session de messagerie sécurisée",
    "errors": {
      "accepted": "{description} doit être accepté",
      "after": "{description} doit être après {after}",
      "before": "{description} doit être avant {before}",
      "blank": "{description} ne peut pas être vide",
      "collection": "{description} doit être une collection",
      "confirmation": "{description} ne correspond pas à {on}",
      "date": "{description} doit être une date valide",
      "description": "Ce champ",
      "email": "{description} doit être une adresse email",
      "empty": "{description} ne peut pas être vide",
      "equalTo": "{description} doit être égale à {is}",
      "even": "{description} must be even",
      "exclusion": "{description} est réservé",
      "file-empty": "Aucun fichier n'est sélectionné",
      "greaterThan": "{description} doit être plus grand que {gt}",
      "greaterThanOrEqualTo": "{description} doit être plus grand ou égale à {gte}",
      "inclusion": "{description} n'est pas inclus dans la liste",
      "invalid": "{description} est invalide",
      "lessThan": "{description} doit être plus petit que {lt}",
      "lessThanOrEqualTo": "{description} doit être plus petit ou égale à {lte}",
      "notANumber": "{description} doit être un nombre",
      "notAnInteger": "{description} doit être un nombre",
      "odd": "{description} must be odd",
      "otherThan": "{description} doit être différent de {value}",
      "phone": "{description} doit être un numéro de téléphone",
      "positive": "{description} doit être positif",
      "present": "{description} doit être vide",
      "singular": "{description} ne peut pas être une collection",
      "tooLong": "{description} est trop long (le maximum est {max} caracters)",
      "tooShort": "{description} est trop court (le minimum est {min} caracters)",
      "unsupported-file": "Le type de fichier n'est pas pris en charge pour le document à signer",
      "url": "{description} doit être une url",
      "wrongDateFormat": "{description} doit être au format suivant {format}",
      "wrongLength": "{description} n'a pas la bonne taille (doit être de {is} caracters)"
    },
    "interactions": {
      "conversation": {
        "action-bar": {
          "archive": "Archiver la conversation",
          "categorize": "Classer la conversation",
          "delete": "Supprimer la conversation",
          "download": "Exporter la conversation",
          "expiring-date": "Cette conversation expire le :",
          "mark-as-read": "Marquer la conversation comme lue",
          "mark-as-unread": "Marquer la conversation comme non lue",
          "open-project": "Ouvrir le projet",
          "print": "Imprimer la conversation",
          "removeFromTrash": "Supprimer définitivement la conversation",
          "restore": "Récupérer la conversation",
          "sort-asc": "Trier les messages par date du plus ancien au plus récent",
          "sort-desc": "Trier les messages par date du plus récent au plus ancien",
          "unarchive": "Récupérer la conversation"
        },
        "cc": {
          "current": "Cette conversation est rattachée au client : {name}"
        },
        "composer": {
          "attachments": {
            "buttons": {
              "delete": {
                "title": "Supprimer la pièce jointe"
              },
              "download": {
                "title": "Télécharger la pièce jointe"
              },
              "preview": {
                "title": "Visualiser la pièce jointe"
              }
            },
            "upload-in-progress": "(en cours)"
          },
          "buttons": {
            "add-attachment": {
              "title": "Ajouter une nouvelle pièce jointe"
            },
            "cancel": {
              "title": "Annuler les modifications",
              "value": "Annuler"
            },
            "save": {
              "title": "Sauvegarder le message en tant que brouillon",
              "value": "Sauvegarder le brouillon"
            },
            "send": {
              "title": "Envoyer le nouveau message",
              "value": "Envoyer"
            }
          },
          "notifications": {
            "attachment-uploaded": "La pièce jointe a été sauvegardée",
            "empty-file": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
            "file-name-already-used": "Une pièce jointe avec le même nom existe déjà.",
            "message-size-limit": "Les pièces jointes dépassent la taille limite.",
            "upload-in-progress": "Une pièce jointe est en cours d'envoi. Impossible d'envoyer le message"
          },
          "placeholder": "Rédiger un message"
        },
        "create": {
          "cc": "Cc",
          "confidentiality": {
            "HIGH": "Haute",
            "LOW": "Normale",
            "label": "Confidentialité :"
          },
          "flash-answer": {
            "answer": {
              "no": "Non",
              "yes": "Oui"
            },
            "indication": "Si vous répondez \"Oui\", votre message sera \"archivé\".",
            "question": "Est-ce que cette information répond à votre question?"
          },
          "message-info": {
            "error": {
              "invalid-form": "Le formulaire est invalide, veuillez vérifier la saisie.",
              "pop-up": {
                "leaving-page": "Vos modifications ne sont pas enregistrées, voulez-vous quitter la page ?"
              },
              "recipient": "Le destinataire de la conversation est obligatoire.",
              "save": {
                "conversation": "Une erreur est apparue, la conversation n'a pas été créée. Veuillez réessayer ultérieurement.",
                "conversation-message": "Une erreur est apparue, le message n'a pas été créé. Veuillez réessayer ultérieurement."
              },
              "subject": "L'objet de la conversation est obligatoire."
            },
            "info": {
              "save": "La conversation a été créée."
            }
          },
          "subject": "Objet :",
          "theme": "Thème :",
          "title": "Nouvelle conversation",
          "to": "Destinataire :"
        },
        "draft": {
          "button": {
            "delete": {
              "label": "Supprimer",
              "title": "Supprimer la conversation en brouillon."
            }
          },
          "message-info": {
            "error": {
              "delete": "Une erreur est apparue, le brouillon de conversation n'a pas été supprimé. Veuillez réessayer ultérieurement.",
              "invalid-form": "Le formulaire est invalide, veuillez vérifier la saisie.",
              "save": {
                "conversation": "Une erreur est apparue, le brouillon de conversation n'a pas été mis à jour. Veuillez réessayer ultérieurement.",
                "conversation-message": "Une erreur est apparue, le brouillon de message n'a pas été mis à jour. Veuillez réessayer ultérieurement."
              }
            },
            "info": {
              "delete": "Le brouillon a été supprimé.",
              "pop-up": {
                "delete": "Voulez-vous supprimer cette conversation ?"
              },
              "save": "Le brouillon a été sauvegardé."
            }
          },
          "title": "Editer un brouillon"
        },
        "errors": {
          "delete": "Impossible de supprimer le brouillon. Veuillez réessayer plus tard",
          "save": "Impossible de sauvegarder le message. Veuillez réessayer plus tard",
          "send": "Impossible d'envoyer le message. Veuillez réessayer plus tard"
        },
        "export": {
          "attachments": "Avec pièces jointes",
          "button": {
            "cancel": "Annuler",
            "download": "Télécharger"
          },
          "options": "Options d'exportation"
        },
        "header": {
          "has-attachments": "Cette conversation possède des pièces jointes",
          "is-confidential": "Cette conversation est confidentielle",
          "message-number": "{nb, plural, =0 {aucun message} =1 {1 message} other {# messages}} ",
          "open-project": "Cette conversation est liée à un projet personnel ",
          "status": {
            "CLOSED": "Archivée",
            "DELETED": "Supprimée",
            "INIT": "Brouillon",
            "IN_PROGRESS": "En cours",
            "TO_ARCHIVE": "Supprimée définitivement",
            "title": "Statut"
          },
          "unread": "Non lu"
        },
        "message-info": {
          "error": {
            "accept": "Une erreur est apparue pendant l'acception de la demande de confidentialité. Veuillez réessayer ultérieurement.",
            "archive": "Une erreur est apparue pendant l'archivage. Veuillez réessayer ultérieurement.",
            "categorize": "Une erreur est apparue pendant la classification. Veuillez réessayer ultérieurement.",
            "confidentiality-response": "Une erreur est apparue pendant la réponse de la demande de confidentialité. Veuillez réessayer ultérieurement.",
            "delete": "Une erreur est apparue pendant la suppression. Veuillez réessayer ultérieurement.",
            "download": "Une erreur est apparue pendant l'export. Veuillez réessayer ultérieurement.",
            "markAsRead": "Une erreur est apparue pendant le marquage comme lu. Veuillez réessayer ultérieurement.",
            "markAsUnread": "Une erreur est apparue pendant le marquage comme non lu. Veuillez réessayer ultérieurement.",
            "pop-up": {
              "archive": "Voulez-vous archiver cette conversation ?",
              "delete": "Voulez-vous vraiment supprimer cette conversation ? Cette action est irréversible.",
              "leaving-page": "Vos modifications ne sont pas enregistrées, voulez-vous quitter la page ?"
            },
            "print": "Une erreur est apparue pendant l'impression. Veuillez réessayer ultérieurement.",
            "refuse": "Une erreur est apparue pendant le refus de la demande de confidentialité. Veuillez réessayer ultérieurement.",
            "removeFromTrash": "Une erreur est apparue pendant la suppression définitive. Veuillez réessayer ultérieurement.",
            "restore": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement.",
            "unarchive": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement."
          },
          "info": {
            "accept": "La demande d'abaissement de confidentialité a été acceptée.",
            "archive": "La conversation a été archivée.",
            "categorize": "La conversation a été classée.",
            "delete": "La conversation a été supprimée.",
            "markAsRead": "La conversation a été marquée comme lue.",
            "markAsUnread": "La conversation a été marquée comme non lue.",
            "pop-up-removeFromTrash": "Êtes-vous sûr de vouloir supprimer définitivement cette conversation ?",
            "refuse": "La demande d'abaissement de confidentialité a été rejetée.",
            "removeFromTrash": "La conversation a été supprimée définitivement.",
            "restore": "La conversation a été récupérée.",
            "unarchive": "La conversation a été restaurée."
          }
        },
        "showMore": {
          "button": "Voir plus de messages.",
          "message-info": {
            "error": {
              "showMoreMessages": "Une erreur est apparue, les messages n'ont pas été téléchargés. Veuillez réessayer ultérieurement."
            },
            "info": {
              "showMoreMessages": "Des messages plus anciens ont été téléchargés."
            }
          }
        },
        "success": {
          "delete": "Le brouillon a été supprimé",
          "save": "Le brouillon a été sauvegardé",
          "send": "Le message a été envoyé"
        },
        "templates": {
          "confidentiality": {
            "accept": "<p>Bonjour,</p><p>Le client a accepté d'abaisser la confidentialité de la conversation.</p><p>Cordialement,</p>",
            "refuse": "<p>Bonjour,</p><p>Le client a refusé d'abaisser la confidentialité de la conversation.</p><p>Cordialement,</p>"
          }
        }
      },
      "conversation-action-bar-component": {
        "actions": {
          "archive": {
            "display": "Archiver",
            "title": "Archiver toutes les conversations sélectionnées"
          },
          "categorize": {
            "display": "Classer dans ...",
            "labelSelected": "Classer toutes les conversations sélectionnées dans {label}",
            "title": "Classer toutes les conversations sélectionnées dans ...",
            "toggle": "Ouvrir le menu de 'Classer dans ...'"
          },
          "checkAll": "Sélectionner toutes les conversations affichées",
          "delete": {
            "display": "Supprimer",
            "title": "Supprimer toutes les conversations sélectionnées"
          },
          "markAs": {
            "display": "Marquer comme ...",
            "markAsRead": {
              "display": "Marquer comme lu",
              "title": "Marquer comme lus toutes les conversations sélectionnées"
            },
            "markAsUnread": {
              "display": "Marquer comme non lu",
              "title": "Marquer comme non lus toutes les conversations sélectionnées"
            },
            "toggle": "Ouvrir le menu de 'Marquer comme ...'"
          },
          "removeFromTrash": {
            "display": "Supprimer de la corbeille",
            "title": "Supprimer de la corbeille toutes les conversations sélectionnées"
          },
          "restore": {
            "display": "Récupérer",
            "title": "Récupérer toutes les conversations sélectionnées"
          },
          "unarchive": {
            "display": "Restaurer",
            "title": "Restaurer toutes les conversations sélectionnées"
          },
          "uncheckAll": "Deselectionner toutes les conversations sélectionnées"
        },
        "search": {
          "button": "Filtrer les conversations avec le texte saisi",
          "input": "Saisir le texte à chercher"
        },
        "sort": {
          "sortBy": "Trier par :"
        }
      },
      "conversation-line-component": {
        "buttons": {
          "check": "Sélectionner la conversation.",
          "uncheck": "Désélectionner la conversation."
        },
        "confidential": "La conversation est confidentielle.",
        "conversationSubject": "Sujet de la conversation.",
        "hasAttachments": "La conversation a au moins un message avec une pièce jointe.",
        "isRead": {
          "read": "Le dernier message de la conversation est lu.",
          "unread": "Le dernier message de la conversation est non lu."
        },
        "lastMessageDisplayName": "Nom du conseiller rattaché au dernier message.",
        "lastMessageReceivedDate": "Date du dernier message reçu.",
        "lastMessageReceivedcontent": "Contenu du dernier message reçu.",
        "lastMessageStatus": {
          "DRAFT": "Le dernier message de la conversation est un brouillon.",
          "RECEIVED": "Le dernier message de la conversation provient d'un conseiller.",
          "SENT": "Le dernier message de la conversation vient de vous."
        }
      },
      "conversation-message": {
        "body": {
          "buttons": {
            "show-less": {
              "text": "--- voir le message partiel ---",
              "title": "Afficher le message partiel"
            },
            "show-more": {
              "text": "--- voir le message complet ---",
              "title": "Afficher le message complet"
            }
          }
        },
        "header": {
          "expand-button": {
            "label-expand": "Agrandir l'entête",
            "label-shrink": "Réduire l'entête"
          },
          "expanded": {
            "cc": "Cc :",
            "cc-type": {
              "actors": "(Conseiller)",
              "externals": "(Externe)",
              "interlocutors": "(Client)"
            },
            "channel": "Canal :",
            "channels": {
              "MAIL": "Mail"
            },
            "from": "De :",
            "receiveDate": "Reçu le :",
            "sendDate": "Envoyé à:",
            "subject": "Objet :",
            "tags": "Labels :",
            "to": "À :",
            "virus-scan": "Scan anti-virus :"
          },
          "shrunken": {
            "cc": "Cc :",
            "from": "De :",
            "to": "À  :"
          },
          "title": "Information sur le message"
        }
      },
      "conversation-message-attachment": {
        "attachment": "Pièces jointes",
        "download": "Télécharger le fichier",
        "dropdown-button-title": "Actions",
        "preview": "Prévisualiser le fichier"
      },
      "conversation-message-confidentiality": {
        "accept": "Vous avez accepté d'abaisser le niveau de confidentialité le {date}",
        "asking": "Abaisser le niveau de confidentialité de cette conversation :",
        "buttons": {
          "accept": "Accepter",
          "refuse": "Refuser"
        },
        "refuse": "Vous avez refusé d'abaisser le niveau de confidentialité le {date}"
      },
      "conversations": {
        "search": {
          "list": {
            "title": "Conversations"
          },
          "message-info": {
            "error": {
              "archive": "Une erreur est apparue pendant l'archivage. Veuillez réessayer ultérieurement.",
              "categorize": "Une erreur est apparue pendant la classification. Veuillez réessayer ultérieurement.",
              "delete": "Une erreur est apparue pendant la suppression. Veuillez réessayer ultérieurement.",
              "markAsRead": "Une erreur est apparue pendant le marquage comme lu. Veuillez réessayer ultérieurement.",
              "markAsUnread": "Une erreur est apparue pendant le marquage comme non lu. Veuillez réessayer ultérieurement.",
              "removeFromTrash": "Une erreur est apparue pendant la suppression définitive. Veuillez réessayer ultérieurement.",
              "restore": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement.",
              "unarchive": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement."
            },
            "info": {
              "archive": "L'archivage a été effectué.",
              "categorize": "La classification a été effectuée.",
              "delete": "La suppression a été effectuée.",
              "markAsRead": "Le marquage comme lu a été effectué.",
              "markAsUnread": "Le marquage comme non lu a été effectué.",
              "pop-up-removeFromTrash": "Êtes-vous sûr de vouloir effectuer la suppression ?",
              "removeFromTrash": "La suppression définitive a été effectuée.",
              "restore": "La restauration a été effectuée.",
              "unarchive": "La restauration a été effectuée."
            }
          }
        },
        "sort": {
          "lastMessageReceivedDate": {
            "asc": "Le plus ancien",
            "desc": "Le plus récent"
          }
        }
      },
      "forbidden-interlocutor": "L'accès à cet utilisateur n'est pas autorisé",
      "label": {
        "categorize-component": {
          "buttons": {
            "close": "Fermer",
            "manage": "Gérer mes dossiers personnels",
            "save": "Sauvegarder"
          },
          "header": {
            "text": "Mes dossiers personnels",
            "title": "Mes dossiers personnels"
          },
          "loading": "Chargement en cours...",
          "no-data": "Aucun dossier personnel à afficher"
        },
        "popup-edit-component": {
          "buttons": {
            "close": "Annuler",
            "save": "Sauvegarder"
          },
          "fields": {
            "color": {
              "label-color-1": "Couleur 1",
              "label-color-2": "Couleur 2",
              "label-color-3": "Couleur 3",
              "label-color-4": "Couleur 4",
              "label-color-5": "Couleur 5",
              "text": "Couleur :",
              "title": "Couleur"
            },
            "description": {
              "text": "Description :",
              "title": "Description"
            },
            "name": {
              "text": "Nom :",
              "title": "Nom"
            }
          },
          "header": {
            "edit": {
              "text": "Dossier personnel : [{name}]",
              "title": "Dossier personnel"
            },
            "new": {
              "text": "Nouveau dossier personnel",
              "title": "Nouveau dossier personnel"
            }
          },
          "message-info": {
            "error": {
              "duplication": "Ce nom est déja utilisé pour un autre dossier personnel.",
              "invalid-form": "Le formulaire est invalide, veuillez vérifier la saisie."
            }
          }
        },
        "settings": {
          "buttons": {
            "add": {
              "label": "Ajouter",
              "title": "Ajouter"
            },
            "delete": {
              "label": "Supprimer",
              "title": "Supprimer"
            },
            "edit": {
              "label": "Editer",
              "title": "Editer"
            }
          },
          "fields": {
            "defaultText": {
              "text": "Aa",
              "title": "Exemple de label"
            },
            "description": {
              "title": "Description"
            },
            "name": {
              "title": "Nom du label"
            }
          },
          "message-info": {
            "error": {
              "create": "Une erreur est apparue, le dossier personnel n'a pas été ajouté. Veuillez réessayer ultérieurement.",
              "delete": "Une erreur est apparue, le dossier personnel n'a pas été supprimé. Veuillez réessayer ultérieurement.",
              "edit": "Une erreur est apparue, le dossier personnel n'a pas été modifié. Veuillez réessayer ultérieurement.",
              "get-404": "Une erreur est apparue, le dossier personnel n'a pas été trouvé. Veuillez réessayer ultérieurement.",
              "unknow": "Une erreur est apparue au chargement de la page. Veuillez réessayer ultérieurement."
            },
            "info": {
              "create": "Le dossier personnel a été créé.",
              "delete": "Le dossier personnel a été supprimé.",
              "deleteConfirm": "Voulez-vous supprimer le dossier personnel [{name}] ?",
              "edit": "Le dossier personnel a été mis à jour."
            }
          },
          "search": {
            "results": {
              "title": "Dossiers personnels"
            }
          }
        }
      },
      "message-attachment": {
        "message-info": {
          "error": {
            "download": "Le téléchargement a échoué. Veuillez réessayer ultérieurement."
          }
        }
      },
      "message-composer-attachments": {
        "multiple-upload": {
          "error": "{nbUploadsFailed, plural, one {# pièce jointe n'a} other {# pièces jointes n'ont}} pas pu être {nbUploadsFailed, plural, one {téléchargée} other {téléchargées}}.",
          "success": "{nbUploadsPassed, plural, one {La pièce jointe a été téléchargée} other {# pièces jointes téléchargées}} avec succès."
        }
      },
      "preview-component": {
        "buttons": {
          "close": "Retour"
        },
        "error": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
        "loading": "Chargement en cours...",
        "title": {
          "uploadDate": "Déposé le :",
          "uploader": "Déposé par :"
        }
      },
      "side-menu": {
        "conversations": {
          "label": "Conversations",
          "lines": {
            "archived": "Archivées",
            "draft": "Brouillons",
            "mine": "Mes Conversations",
            "sent": "Envoyées",
            "trashed": "Corbeille"
          },
          "sr-only": "conversations"
        },
        "labels": {
          "configurationButton": "Configuration",
          "label": "Dossiers personnels"
        },
        "new-conversation": "Nouvelle Conversation"
      }
    },
    "interactions.conversation-action-bar-component.actions.archive.display": "Archiver",
    "interactions.conversation-action-bar-component.actions.archive.title": "Archiver toutes les conversations sélectionnées",
    "interactions.conversation-action-bar-component.actions.categorize.display": "Classer dans ...",
    "interactions.conversation-action-bar-component.actions.categorize.labelSelected": "Classer toutes les conversations sélectionnées dans {label}",
    "interactions.conversation-action-bar-component.actions.categorize.title": "Classer toutes les conversations sélectionnées dans ...",
    "interactions.conversation-action-bar-component.actions.categorize.toggle": "Ouvrir le menu de 'Classer dans ...'",
    "interactions.conversation-action-bar-component.actions.checkAll": "Sélectionner tout",
    "interactions.conversation-action-bar-component.actions.delete.display": "Supprimer",
    "interactions.conversation-action-bar-component.actions.delete.title": "Supprimer toutes les conversations sélectionnées",
    "interactions.conversation-action-bar-component.actions.markAs.display": "Marquer comme ...",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display": "Marquer comme lue",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title": "Marquer comme lues toutes les conversations sélectionnées",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display": "Marquer comme non lue",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title": "Marquer comme non lus toutes les conversations sélectionnées",
    "interactions.conversation-action-bar-component.actions.markAs.toggle": "Ouvrir le menu de 'Marquer comme ...'",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.display": "Supprimer de la corbeille",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.title": "Supprimer de la corbeille toutes les conversations sélectionnées",
    "interactions.conversation-action-bar-component.actions.restore.display": "Récupérer",
    "interactions.conversation-action-bar-component.actions.restore.title": "Récupérer toutes les conversations sélectionnées",
    "interactions.conversation-action-bar-component.actions.unarchive.display": "Restaurer",
    "interactions.conversation-action-bar-component.actions.unarchive.title": "Restaurer toutes les conversations sélectionnées",
    "interactions.conversation-action-bar-component.actions.uncheckAll": "Désélectionner tout",
    "interactions.conversation-action-bar-component.search.button": "Filtrer les conversations avec le texte saisi",
    "interactions.conversation-action-bar-component.search.input": "Saisir le texte à chercher",
    "interactions.conversation-action-bar-component.sort.sortBy": "Trier par :",
    "interactions.conversation-line-component.buttons.check": "Sélectionner la conversation.",
    "interactions.conversation-line-component.buttons.uncheck": "Désélectionner la conversation.",
    "interactions.conversation-line-component.conversationSubject": "Sujet de la conversation.",
    "interactions.conversation-line-component.hasAttachments": "La conversation a au moins un message avec une pièce jointe.",
    "interactions.conversation-line-component.isRead.read": "Le dernier message de la conversation est lu.",
    "interactions.conversation-line-component.isRead.unread": "Le dernier message de la conversation est non lu.",
    "interactions.conversation-line-component.lastMessageReceivedDate": "Date du dernier message reçu.",
    "interactions.conversation-line-component.lastMessageReceivedcontent": "Contenu du dernier message reçu.",
    "interactions.conversation-line-component.lastMessageStatus.DRAFT": "Le dernier message de la conversation est un brouillon.",
    "interactions.conversation-line-component.lastMessageStatus.RECEIVED": "Le dernier message de la conversation provient d'un conseiller.",
    "interactions.conversation-line-component.lastMessageStatus.SENT": "Le dernier message de la conversation vient de vous.",
    "interactions.conversation-message-attachment.attachment": "Pièces jointes",
    "interactions.conversation-message-attachment.download": "Télécharger",
    "interactions.conversation-message-attachment.dropdown-button-title": "Actions",
    "interactions.conversation-message-attachment.preview": "Prévisualiser",
    "interactions.conversation-message.body.buttons.show-less.text": "--- voir le message partiel ---",
    "interactions.conversation-message.body.buttons.show-less.title": "Afficher le message partiel",
    "interactions.conversation-message.body.buttons.show-more.text": "--- voir le message complet ---",
    "interactions.conversation-message.body.buttons.show-more.title": "Afficher le message complet",
    "interactions.conversation-message.header.expand-button.label-expand": "Agrandir l'entête",
    "interactions.conversation-message.header.expand-button.label-shrink": "Réduire l'entête",
    "interactions.conversation-message.header.expanded.channel": "Canal :",
    "interactions.conversation-message.header.expanded.channels.MAIL": "Mail",
    "interactions.conversation-message.header.expanded.from": "De :",
    "interactions.conversation-message.header.expanded.receiveDate": "Reçu le :",
    "interactions.conversation-message.header.expanded.sendDate": "Envoyé le :",
    "interactions.conversation-message.header.expanded.subject": "Objet :",
    "interactions.conversation-message.header.expanded.tags": "Labels :",
    "interactions.conversation-message.header.expanded.to": "À :",
    "interactions.conversation-message.header.expanded.virus-scan": "Scan anti-virus :",
    "interactions.conversation-message.header.shrunken.from": "De :",
    "interactions.conversation-message.header.shrunken.to": "À :",
    "interactions.conversation-message.header.title": "Information sur le message",
    "interactions.conversation.action-bar.archive": "Archiver la conversation",
    "interactions.conversation.action-bar.categorize": "Classer la conversation",
    "interactions.conversation.action-bar.delete": "Supprimer la conversation.",
    "interactions.conversation.action-bar.download": "Exporter la conversation",
    "interactions.conversation.action-bar.expiring-date": "Cette conversation expire le :",
    "interactions.conversation.action-bar.mark-as-read": "Marquer la conversation comme lue",
    "interactions.conversation.action-bar.mark-as-unread": "Marquer la conversation comme non lue",
    "interactions.conversation.action-bar.print": "Imprimer la conversation",
    "interactions.conversation.action-bar.removeFromTrash": "Supprimer définitivement la conversation",
    "interactions.conversation.action-bar.restore": "Récupérer la conversation",
    "interactions.conversation.action-bar.sort-asc": "Trier les messages par date du plus ancien au plus récent",
    "interactions.conversation.action-bar.sort-desc": "Trier les messages par date du plus récent au plus ancien",
    "interactions.conversation.action-bar.unarchive": "Récupérer la conversation",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Supprimer cette pièce jointe",
    "interactions.conversation.composer.attachments.buttons.download.title": "Télécharger cette pièce jointe",
    "interactions.conversation.composer.attachments.buttons.preview.title": "Visualiser la pièce jointe",
    "interactions.conversation.composer.attachments.upload-in-progress": "(en cours)",
    "interactions.conversation.composer.buttons.add-attachment.title": "Ajouter une pièce jointe",
    "interactions.conversation.composer.buttons.cancel.title": "Annuler les modifications",
    "interactions.conversation.composer.buttons.cancel.value": "Annuler",
    "interactions.conversation.composer.buttons.save.title": "Mise en brouillon",
    "interactions.conversation.composer.buttons.save.value": "Mise en brouillon",
    "interactions.conversation.composer.buttons.send.title": "Envoyer le nouveau message",
    "interactions.conversation.composer.buttons.send.value": "Envoyer",
    "interactions.conversation.composer.notifications.attachment-uploaded": "La pièce jointe a été sauvegardée",
    "interactions.conversation.composer.notifications.empty-file": "Vous ne pouvez pas télécharger de pièces jointes vides. Merci de réessayer avec un fichier valide.",
    "interactions.conversation.composer.notifications.file-name-already-used": "Une pièce jointe avec le même nom existe déjà.",
    "interactions.conversation.composer.notifications.message-size-limit": "Les pièces jointes dépassent la taille limite.",
    "interactions.conversation.composer.notifications.upload-in-progress": "Une pièce jointe est en cours de sauvegarde, merci d'attendre la fin du transfert",
    "interactions.conversation.create.message-info.error.invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer.",
    "interactions.conversation.create.message-info.error.pop-up.leaving-page": "Vos modifications ne sont pas enregistrées, voulez-vous quitter la page ?",
    "interactions.conversation.create.message-info.error.save.conversation": "Une erreur est apparue, la conversation n'a pas été mise à jour. Veuillez réessayer ultérieurement.",
    "interactions.conversation.create.message-info.error.save.conversation-message": "Une erreur est apparue, le message n'a pas été créé. Veuillez réessayer ultérieurement.",
    "interactions.conversation.create.message-info.error.subject": "Vous devez sélectionner un thème.",
    "interactions.conversation.create.message-info.info.save": "La conversation a été créée.",
    "interactions.conversation.create.subject": "Objet :",
    "interactions.conversation.create.title": "Nouvelle conversation",
    "interactions.conversation.draft.button.delete.label": "Supprimer",
    "interactions.conversation.draft.button.delete.title": "Supprimer la conversation en brouillon.",
    "interactions.conversation.draft.message-info.error.delete": "Une erreur est apparue, la conversation n'a pas été supprimée. Veuillez réessayer ultérieurement.",
    "interactions.conversation.draft.message-info.error.invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer.",
    "interactions.conversation.draft.message-info.error.save.conversation": "Une erreur est apparue, le brouillon n'a pas été sauvegardé. Veuillez réessayer ultérieurement.",
    "interactions.conversation.draft.message-info.error.save.conversation-message": "Une erreur est apparue, le brouillon n'a pas été sauvegardé. Veuillez réessayer ultérieurement.",
    "interactions.conversation.draft.message-info.info.delete": "La conversation a été supprimée.",
    "interactions.conversation.draft.message-info.info.pop-up.delete": "Voulez-vous vraiment supprimer ce brouillon ?",
    "interactions.conversation.draft.message-info.info.save": "Votre demande est sauvegardée en brouillon.",
    "interactions.conversation.draft.title": "Edition d'une conversation en brouillon",
    "interactions.conversation.errors.delete": "Impossible de supprimer le brouillon. Veuillez réessayer plus tard",
    "interactions.conversation.errors.save": "Une erreur est apparue, le message n'a pas été enregistré. Veuillez réessayer ultérieurement.",
    "interactions.conversation.errors.send": "Une erreur est apparue, le message n'a pas été envoyé. Veuillez réessayer ultérieurement.",
    "interactions.conversation.header.message-number": "{nb, plural, =0 {aucun message} =1 {1 message} other {# messages}}",
    "interactions.conversation.header.status.CLOSED": "Archivée",
    "interactions.conversation.header.status.DELETED": "Supprimée",
    "interactions.conversation.header.status.INIT": "Brouillon",
    "interactions.conversation.header.status.IN_PROGRESS": "En cours",
    "interactions.conversation.header.status.TO_ARCHIVE": "Supprimée définitivement",
    "interactions.conversation.header.status.title": "Statut",
    "interactions.conversation.header.unread": "Non lu",
    "interactions.conversation.message-info.confirm.pop-up.delete": "Voulez-vous vraiment placer cette conversation dans votre corbeille ?",
    "interactions.conversation.message-info.error.archive": "Une erreur est apparue pendant l'archivage. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.categorize": "Une erreur est apparue pendant la classification. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.delete": "Une erreur est apparue, la conversation n'a pas été supprimée. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.download": "Une erreur est apparue pendant l'export de la conversation, veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.markAsRead": "Une erreur est apparue pendant le marquage comme lu. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.markAsUnread": "Une erreur est apparue pendant le marquage comme non lu. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.pop-up.archive": "Voulez-vous archiver cette conversation ?",
    "interactions.conversation.message-info.error.pop-up.delete": "Voulez-vous vraiment supprimer cette conversation ? Cette action est irréversible.",
    "interactions.conversation.message-info.error.pop-up.leaving-page": "Vos modifications ne sont pas enregistrées, voulez-vous quitter la page ?",
    "interactions.conversation.message-info.error.print": "Une erreur est apparue pendant l'impression de la conversation, veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.removeFromTrash": "Une erreur est apparue pendant la suppression définitive. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.restore": "Une erreur est apparue pendant la récupération. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.error.unarchive": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement.",
    "interactions.conversation.message-info.info.archive": "La conversation a été archivée.",
    "interactions.conversation.message-info.info.categorize": "La conversation a été classée.",
    "interactions.conversation.message-info.info.delete": "La conversation a été supprimée.",
    "interactions.conversation.message-info.info.markAsRead": "La conversation a été marquée comme lue.",
    "interactions.conversation.message-info.info.markAsUnread": "La conversation a été marquée comme non lue.",
    "interactions.conversation.message-info.info.pop-up-removeFromTrash": "Êtes-vous sûr de vouloir supprimer définitivement cette conversation ?",
    "interactions.conversation.message-info.info.readOnly": "Ce message est en lecture seule.",
    "interactions.conversation.message-info.info.removeFromTrash": "La conversation a été supprimée définitivement.",
    "interactions.conversation.message-info.info.restore": "La conversation a été récupérée.",
    "interactions.conversation.message-info.info.unarchive": "La conversation a été restaurée.",
    "interactions.conversation.showMore.button": "Voir messages plus anciens",
    "interactions.conversation.showMore.message-info.error.showMoreMessages": "Une erreur est apparue, les messages n'ont pas été téléchargés. Veuillez réessayer ultérieurement.",
    "interactions.conversation.success.delete": "Le brouillon a été supprimé",
    "interactions.conversation.success.save": "Le brouillon a été sauvegardé",
    "interactions.conversation.success.send": "Le message a été envoyé.",
    "interactions.conversations.search.list.title": "Conversations",
    "interactions.conversations.search.message-info.error.archive": "Une erreur est apparue pendant l'archivage. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.error.categorize": "Une erreur est apparue pendant la classification. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.error.delete": "Une erreur est apparue, la conversation n'a pas été supprimée. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.error.markAsRead": "Une erreur est apparue pendant le marquage comme lu. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.error.markAsUnread": "Une erreur est apparue pendant le marquage comme non lu. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.error.removeFromTrash": "Une erreur est apparue, la conversation n'a pas été supprimée. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.error.restore": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.error.unarchive": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.info.archive": "L'archivage a été effectué.",
    "interactions.conversations.search.message-info.info.categorize": "La classification a été effectuée.",
    "interactions.conversations.search.message-info.info.delete": "La suppression a été effectuée.",
    "interactions.conversations.search.message-info.info.markAsRead": "Le marquage comme lu a été effectué.",
    "interactions.conversations.search.message-info.info.markAsUnread": "Le marquage comme non lu a été effectué.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "Êtes-vous sûr de vouloir effectuer la suppression ?",
    "interactions.conversations.search.message-info.info.removeFromTrash": "La suppression définitive a été effectuée.",
    "interactions.conversations.search.message-info.info.restore": "La restauration a été effectuée.",
    "interactions.conversations.search.message-info.info.unarchive": "La restauration a été effectuée.",
    "interactions.conversations.sort.lastMessageReceivedDate.asc": "Le plus ancien",
    "interactions.conversations.sort.lastMessageReceivedDate.desc": "Le plus récent",
    "interactions.forbidden-interlocutor": "L'accès à cet utilisateur n'est pas autorisé",
    "interactions.label.categorize-component.buttons.close": "Fermer",
    "interactions.label.categorize-component.buttons.manage": "Gérer mes dossiers personnels",
    "interactions.label.categorize-component.buttons.save": "Sauvegarder",
    "interactions.label.categorize-component.header.text": "Mes dossiers personnels",
    "interactions.label.categorize-component.header.title": "Mes dossiers personnels",
    "interactions.label.categorize-component.loading": "Chargement en cours",
    "interactions.label.categorize-component.no-data": "Aucun dossier personnel à afficher",
    "interactions.label.popup-edit-component.buttons.close": "Annuler",
    "interactions.label.popup-edit-component.buttons.save": "Sauvegarder",
    "interactions.label.popup-edit-component.fields.color.label-color-1": "Couleur 1",
    "interactions.label.popup-edit-component.fields.color.label-color-2": "Couleur 2",
    "interactions.label.popup-edit-component.fields.color.label-color-3": "Couleur 3",
    "interactions.label.popup-edit-component.fields.color.label-color-4": "Couleur 4",
    "interactions.label.popup-edit-component.fields.color.label-color-5": "Couleur 5",
    "interactions.label.popup-edit-component.fields.color.text": "Couleur :",
    "interactions.label.popup-edit-component.fields.color.title": "Couleur",
    "interactions.label.popup-edit-component.fields.description.text": "Description :",
    "interactions.label.popup-edit-component.fields.description.title": "Description",
    "interactions.label.popup-edit-component.fields.name.text": "Nom :",
    "interactions.label.popup-edit-component.fields.name.title": "Nom",
    "interactions.label.popup-edit-component.header.edit.text": "Dossier personnel : [{name}]",
    "interactions.label.popup-edit-component.header.edit.title": "Dossier personnel",
    "interactions.label.popup-edit-component.header.new.text": "Nouveau dossier personnel",
    "interactions.label.popup-edit-component.header.new.title": "Nouveau dossier personnel",
    "interactions.label.popup-edit-component.message-info.error.duplication": "Ce nom est déja utilisé pour un autre dossier personnel.",
    "interactions.label.popup-edit-component.message-info.error.invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer.",
    "interactions.label.settings.buttons.add.label": "Ajouter",
    "interactions.label.settings.buttons.add.title": "Ajouter",
    "interactions.label.settings.buttons.delete.label": "Supprimer",
    "interactions.label.settings.buttons.delete.title": "Supprimer",
    "interactions.label.settings.buttons.edit.label": "Editer",
    "interactions.label.settings.buttons.edit.title": "Editer",
    "interactions.label.settings.fields.defaultText.text": "Aa",
    "interactions.label.settings.fields.defaultText.title": "Exemple de label",
    "interactions.label.settings.fields.description.title": "Description",
    "interactions.label.settings.fields.name.title": "Nom du dossier personnel",
    "interactions.label.settings.message-info.error.create": "Une erreur est apparue, le dossier personnel n'a pas été ajouté. Veuillez réessayer ultérieurement.",
    "interactions.label.settings.message-info.error.delete": "Une erreur est apparue, le dossier personnel n'a pas été supprimé. Veuillez réessayer ultérieurement.",
    "interactions.label.settings.message-info.error.edit": "Une erreur est apparue, le dossier personnel n'a pas été modifié. Veuillez réessayer ultérieurement.",
    "interactions.label.settings.message-info.error.get-404": "Une erreur est apparue, le dossier personnel n'a pas été trouvé. Veuillez réessayer ultérieurement.",
    "interactions.label.settings.message-info.error.unknow": "Une erreur est apparue au chargement de la page. Veuillez vérifier et réessayer ultérieurement.",
    "interactions.label.settings.message-info.info.create": "Le dossier personnel a été créé.",
    "interactions.label.settings.message-info.info.delete": "Le dossier personnel a été supprimé.",
    "interactions.label.settings.message-info.info.deleteConfirm": "Voulez-vous supprimer le dossier personnel [{name}] ?",
    "interactions.label.settings.message-info.info.edit": "Le dossier personnel a été mis à jour.",
    "interactions.label.settings.search.results.title": "Dossiers personnels",
    "interactions.message-attachment.message-info.error.download": "Le téléchargement a échoué. Veuillez réessayer ultérieurement.",
    "interactions.message-composer-attachments.multiple-upload.error": "{nbUploadsFailed, plural, one {# pièce jointe n'a} other {# pièces jointes n'ont}} pas pu être {nbUploadsFailed, plural, one {téléchargée} other {téléchargées}}.",
    "interactions.message-composer-attachments.multiple-upload.success": "{nbUploadsPassed, plural, one {La pièce jointe a été téléchargée} other {# pièces jointes téléchargées}} avec succès.",
    "interactions.message-editor.message-info.info.pop-up.replace-subject": "Confirmez-vous la suppression du message en cours d'édition pour le remplacer par le nouveau thème sélectionné ?",
    "interactions.preview-component.buttons.close": "Retour",
    "interactions.preview-component.error": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
    "interactions.preview-component.loading": "Chargement en cours",
    "interactions.preview-component.title.uploadDate": "Déposé le",
    "interactions.preview-component.title.uploader": "Déposé par",
    "interactions.side-menu.conversations.label": "Conversations",
    "interactions.side-menu.conversations.lines.archived": "Archivées",
    "interactions.side-menu.conversations.lines.draft": "Brouillons",
    "interactions.side-menu.conversations.lines.mine": "Mes conversations",
    "interactions.side-menu.conversations.lines.sent": "Envoyées",
    "interactions.side-menu.conversations.lines.trashed": "Corbeille",
    "interactions.side-menu.conversations.sr-only": "conversations",
    "interactions.side-menu.labels.configurationButton": "Configuration",
    "interactions.side-menu.labels.label": "Dossiers personnels",
    "interactions.side-menu.new-conversation": "Nouvelle Conversation",
    "interlocutors": {
      "breadcrumb": {
        "title": "Interlocuteurs"
      },
      "interlocutor": {
        "projects": {
          "breadcrumb": {
            "title": "Projets"
          },
          "search": {
            "closed": "Projets clos",
            "open": "Projets en cours"
          }
        }
      }
    },
    "profile": {
      "coordinates": {
        "edit": {
          "cancel": "Annuler",
          "email": {
            "clear": "Effacer le champ email externe.",
            "label": "Email externe :",
            "title": "Email externe"
          },
          "mobile-phone": {
            "clear": "Effacer le champ téléphone mobile.",
            "invalid-message": "Le numéro de téléphone saisi est invalide.",
            "label": "Téléphone mobile :",
            "title": "Téléphone mobile"
          },
          "save": "Sauvegarder"
        },
        "header": "Coordonnées",
        "message-info": {
          "error": {
            "invalid-form": "Le formulaire est invalide, corrigez le puis réessayez.",
            "save": "Une erreur est apparue pendant la mise à jour de vos coordonnées. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "Vos coordonnées ont été mises à jour."
          }
        },
        "view": {
          "edit": "Modifier",
          "email": {
            "label": "Email externe :"
          },
          "mobile-phone": {
            "label": "Téléphone mobile :"
          }
        }
      },
      "infos": {
        "field": {
          "firstname": {
            "label": "Prénom :",
            "title": "Prénom"
          },
          "name": {
            "label": "Nom :",
            "title": "Nom"
          }
        },
        "header": "Informations personnelles"
      },
      "notifications": {
        "NEW_CAMPAIGN_MESSAGE_RECEIVED": "Nouveau message de campagne reçu",
        "NEW_DOCUMENT_UPLOADED_BY_ADVISER": "Nouveau document créé par un conseiller",
        "NEW_FILE_AVAILABLE_TO_SIGN": "Nouveau fichier à signer",
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nouveau message reçu",
        "PROJECT_PUBLICATION_STOPPED": "Partage de projet interrompu",
        "PROJECT_PUBLISHED_TO_INTERLOCUTOR": "Nouveau projet partagé avec moi",
        "SEND_DOCUMENT_REJECT_TO_CUSTOMER": "Document rejeté par un conseiller",
        "SEND_UPDATE_TO_CUSTOMER": "Mise à jour de projet"
      },
      "preferences": {
        "authorizeAudioMessage": {
          "label": "Messages audio autorisés :",
          "title": "Messages audio autorisés :"
        },
        "authorizeAudioMessages": {
          "false": "No",
          "true": "Yes"
        },
        "availableLangages": {
          "en-gb": "English",
          "fr-fr": "Français"
        },
        "closeErrorMessage": {
          "label": "Fermeture des messages d'erreur :",
          "title": "Fermeture des messages d'erreur :"
        },
        "closeErrorMessages": {
          "false": "Automatique",
          "true": "Manuel"
        },
        "edit": {
          "cancel": "Annuler",
          "save": "Sauvegarder"
        },
        "header": "Préférences",
        "language": {
          "label": "Langue de l'interface :",
          "title": "Langue"
        },
        "message-info": {
          "error": {
            "save": "Une erreur est survenue lors de la mise à jour des préférences. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "Les préférences ont été mises à jour."
          }
        },
        "spellchecker-language": {
          "label": "Langue de vérification par défaut :",
          "title": "Langue de vérification par défaut"
        },
        "view": {
          "edit": "Modifier"
        }
      },
      "side-bar": {
        "sign-out": "Déconnexion",
        "title": "Paramètres du compte"
      },
      "side-menu": {
        "personal-informations": "Informations personnelles",
        "preferences": "Préférences",
        "subscriptions": "Notifications"
      },
      "subscriptions": {
        "edit": {
          "cancel": "Annuler",
          "save": "Sauvegarder"
        },
        "header": "Mes abonnements",
        "message-info": {
          "error": {
            "loading": "Une erreur est apparue pendant le chargement des abonnements aux notifications. Veuillez réessayer ultérieurement.",
            "save": "Une erreur est apparue pendant la sauvegarde des abonnements aux notifications. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "Vos abonnements aux notifications ont été mis à jour."
          }
        },
        "view": {
          "edit": "Modifier",
          "state": {
            "disabled": "Inactive",
            "enabled": "Active"
          }
        }
      }
    },
    "profile.infos.header": "Préférences personnelles",
    "profile.infos.howto.messaging": "Pour revenir à votre messagerie sécurisée, cliquez dans le menu latéral  sur le menu déroulant intitulé 'Profil' puis sélectionnez 'Votre messagerie'",
    "profile.infos.howto.notifications": "Pour modifier la réception de notifications externes, cliquez dans le menu latéral sur Notifications.",
    "profile.infos.howto.warnings": "Pour modifier le fonctionnement des messages d'avertissement, cliquez dans le menu latéral sur Préférences.",
    "profile.infos.introduction": "Vous êtes dans le module de gestion de vos données personnelles.",
    "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nouveau message reçu",
    "profile.preferences.closeErrorMessage.label": "Fermeture des messages d'erreur :",
    "profile.preferences.closeErrorMessage.title": "Fermeture des messages d'erreur ",
    "profile.preferences.closeErrorMessages.false": "Automatique",
    "profile.preferences.closeErrorMessages.true": "Manuel",
    "profile.preferences.edit.cancel": "Annuler",
    "profile.preferences.edit.save": "Sauvegarder",
    "profile.preferences.header": "Préférences",
    "profile.preferences.message-info.info.save": "Les préférences ont été mises à jour.",
    "profile.preferences.view.edit": "Modifier",
    "profile.side-bar.sign-out": "Déconnexion",
    "profile.side-bar.title": "Paramètres du compte",
    "profile.side-menu.preferences": "Préférences",
    "profile.side-menu.subscriptions": "Notifications",
    "profile.subscriptions.edit.cancel": "Annuler",
    "profile.subscriptions.edit.save": "Sauvegarder",
    "profile.subscriptions.message-info.error.loading": "Une erreur est apparue pendant le chargement des abonnements aux notifications. Veuillez réessayer ultérieurement.",
    "profile.subscriptions.message-info.error.save": "Une erreur est apparue pendant la sauvegarde des abonnements aux notifications. Veuillez réessayer ultérieurement.",
    "profile.subscriptions.message-info.info.save": "Vos abonnements aux notifications ont été mis à jour.",
    "profile.subscriptions.view.edit": "Modifier",
    "profile.subscriptions.view.state.disabled": "Inactive",
    "profile.subscriptions.view.state.enabled": "Active",
    "projects": {
      "breadcrumb": {
        "choose-project": "Choix Projet",
        "create": {
          "free": {
            "title": "Projet Libre"
          },
          "title": "Nouveau Projet"
        },
        "edit": {
          "free": {
            "title": "Modification Projet"
          }
        },
        "project-details": "Détails",
        "projects-status": {
          "CLOSED": "Projets clos",
          "IN_PROGRESS": "Projets en cours"
        }
      },
      "create": {
        "project": {
          "buttons": {
            "cancel": "Annuler",
            "preview": "Pré visualiser",
            "save": "Sauvegarder"
          },
          "fields": {
            "assigned-to": "Assigné à",
            "date-format": "DD/MM/YYYY",
            "description": "Description",
            "enddate": "Date de fin prévue",
            "nature": "Nature",
            "title": "Nom"
          },
          "folder": {
            "no-data": "Le projet n'a aucun dossier.",
            "status": {
              "CLOSED": "Fermé",
              "INIT": "Initialisé",
              "OPENED": "Ouvert"
            },
            "title": "Dossiers"
          },
          "message-info": {
            "error": {
              "create": "Une erreur est apparue alors de la création du projet. Veuillez réessayer ultérieurement.",
              "duplicate-title": "Le nom existe déjà pour cet interlocuteur.",
              "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
            },
            "info": {
              "create": "Le projet a été créé."
            }
          },
          "pop-up": {
            "not-saved-transition": "Les modifications n'ont pas été enregistrées. Etes-vous sûr de vouloir quitter cette page ?"
          }
        }
      },
      "document": {
        "no-data": "Cette section n'a pas de document."
      },
      "edit": {
        "project": {
          "buttons": {
            "cancel": "Annuler",
            "preview": "Pré visualiser",
            "save": "Sauvegarder"
          },
          "fields": {
            "assigned-to": "Assigné à",
            "date-format": "DD/MM/YYYY",
            "description": "Description",
            "enddate": "Date de fin prévue",
            "nature": "Nature",
            "title": "Nom"
          },
          "folder": {
            "no-data": "Le projet n'a aucun dossier.",
            "status": {
              "CLOSED": "Fermé",
              "INIT": "Initialisé",
              "OPENED": "Ouvert"
            },
            "title": "Dossiers"
          },
          "message-info": {
            "error": {
              "duplicate-title": "Le nom existe déjà pour cet interlocuteur.",
              "edit": "Une erreur est apparue lors de la modification du projet. Veuillez réessayer ultérieurement.",
              "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
            },
            "info": {
              "edit": "Le projet a été modifié."
            }
          },
          "pop-up": {
            "not-saved-transition": "Les modifications n'ont pas été enregistrées. Etes-vous sûr de vouloir quitter cette page ?"
          }
        }
      },
      "list": {
        "message-info": {
          "error": {
            "get-404": "Une erreur est apparue, le projet n'a pas été trouvé. Veuillez réessayer ultérieurement.",
            "unknown": "Une erreur est apparue au chargement de la page. Veuillez réessayer ultérieurement."
          }
        },
        "title": "Projets"
      },
      "new-project": {
        "buttons": {
          "choose-btn": "Choisir"
        },
        "free": {
          "description": "Dans un projet libre, le client ou vous-même pouvez librement ajouter des documents.",
          "title": "Nouveau Projet Libre"
        }
      },
      "project": {
        "interlocutor-new": {
          "help": "Créer un nouveau projet pour {interlocutor}",
          "title": "Nouveau projet pour {interlocutor}"
        },
        "new": {
          "help": "Créer un nouveau projet",
          "title": "Nouveau projet"
        }
      },
      "project-group": {
        "no-data": "Ce groupe de projet est vide."
      },
      "project-group-title": {
        "projects": "Projets appartenant à {interlocutorFullName}",
        "projects-shared": "Projets partagés avec {interlocutorFullName}"
      },
      "project-part": {
        "no-data": "Ce projet n'a pas de section."
      },
      "search": {
        "filter": {
          "label": {
            "assignedTo": "Assignées à ::",
            "interlocutor": "Interlocuteur :"
          }
        },
        "legend": "Search",
        "message-info": {
          "error": {
            "project-load": "Impossible de charger la liste des projets"
          }
        }
      },
      "side-menu": {
        "closed-projects": "Close",
        "in-progress-projects": "En cours",
        "interlocutor-menu-label": "Projets de {name}",
        "my-projects": "Les miens",
        "projects": "Projets"
      },
      "signature": {
        "spinner": {
          "primary-error": "Une erreur est survenue",
          "primary-success": "Le document a été signé",
          "primary-wait": "Vérification de la signature du document...",
          "secondary-error": "Le document n'a pas été signé",
          "secondary-success": "Vous pouvez fermer cet onglet",
          "secondary-wait": "Veuillez patienter"
        }
      },
      "title": {
        "empty": "Projets",
        "mine": "Projets"
      }
    },
    "shared": {
      "components": {
        "a11y": {
          "modals": {
            "alert-modal": {
              "closeButtonText": "Fermer",
              "closeButtonTitle": "Fermer la fenêtre"
            }
          }
        },
        "absence-table": {
          "absence-backend-error": "Erreur lors de la récupération des absences d'un conseiller",
          "granularity-dateformat-day": "DD/MM/YYYY",
          "granularity-dateformat-month": "MMMM YYYY",
          "granularity-dateformat-week": "Semaine {week} - {year}",
          "granularity-day": "jour",
          "granularity-legend": "Affichage",
          "granularity-month": "mois",
          "granularity-week": "semaine",
          "grid-caption": "Liste des absences",
          "next-day-button-label": "Jour suivant",
          "next-month-button-label": "Mois suivant",
          "next-week-button-label": "Semaine suivante",
          "prev-day-button-label": "Jour précedent",
          "prev-month-button-label": "Mois précedent",
          "prev-week-button-label": "Semaine précedente",
          "range-label": "De {start} à {end}",
          "range-label-backup": "Remplacé par {backup}",
          "today-button-label": "Aujourd'hui"
        },
        "charts": {
          "adviser-dashboard-chart": {
            "ACKNOWLEDGE": "Conversations acquitées",
            "CLOSED": "Conversations traitées",
            "CLOSED-AFTER-CRITICAL-DUE-DATE": "Nombre de conversations closes après la date d'échéance critique",
            "CLOSED-BEFORE-CRITICAL-DUE-DATE": "Nombre de conversations closes avant la date d'échéance critique",
            "CLOSED-BEFORE-DUE-DATE": "Nombre de conversations closes avant la date d'échéance",
            "OPEN": "Conversations créées",
            "REOPEN": "Conversations réouvertes",
            "nb-conversations": "Nombre de conversations"
          }
        },
        "date": {
          "cancel": "Annuler",
          "dow": {
            "abbr": {
              "Fr": "Vendredi",
              "Mo": "Lundi",
              "Sa": "Samedi",
              "Su": "Dimanche",
              "Th": "Jeudi",
              "Tu": "Mardi",
              "We": "Mercredi"
            },
            "text": {
              "Fr": "Ve",
              "Mo": "Lu",
              "Sa": "Sa",
              "Su": "Di",
              "Th": "Je",
              "Tu": "Ma",
              "We": "Me"
            }
          },
          "format": "DD/MM/YYYY",
          "formatWithTime": "DD/MM/YYYY HH:mm",
          "month": {
            "april": "Avril",
            "august": "Août",
            "december": "Décembre",
            "february": "Février",
            "january": "Janvier",
            "july": "Juillet",
            "june": "Juin",
            "march": "Mars",
            "may": "Mai",
            "november": "Novembre",
            "october": "Octobre",
            "september": "Septembre"
          },
          "month-year": "Mois Année",
          "next-month": "Mois suivant",
          "next-year": "Année suivante",
          "ok": "OK",
          "picker-button-alt": "Choisissez une date",
          "placeholderFormat": "JJ/MM/AAAA",
          "placeholderFormatWithTime": "JJ/MM/AAAA hh:mm",
          "previous-month": "Mois précédent",
          "previous-year": "Année précédente"
        },
        "dropdown": {
          "default-button-title": "Actions"
        },
        "form-component": {
          "form-fields-required-label": "Les champs marqués d'une * sont obligatoires."
        },
        "form-group-component": {
          "missing-text": "Ce champ est requis."
        },
        "modals": {
          "audio-preview": {
            "loading": "Chargement ...",
            "modal-title": "Lecture audio"
          },
          "document-preview": {
            "error-message": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
            "modal-title": "Prévisualiser le fichier",
            "select-page": "Sélectionner une page",
            "upload-by": "Téléchargé par {actor} le {date}",
            "zoom-in": "Zoomer",
            "zoom-out": "Dézoomer"
          },
          "image-preview": {
            "error-message": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
            "loading-message": "Chargement en cours...",
            "modal-title": "Prévisualiser le fichier",
            "upload-by": "Déposé par",
            "upload-date": "Déposé le",
            "zoom-in": "Zoomer",
            "zoom-out": "Dézoomer"
          },
          "modal-component": {
            "modal-cancel": "Annuler",
            "modal-confirm": "Confirmer"
          },
          "title": {
            "closeButton": "Fermer ce message"
          }
        },
        "multi-selectors": {
          "cc-multi-selector": {
            "error": "Une erreur est apparue pendant la recherche.",
            "error-external-on-secured": "Impossible d'ajouter un courriel externe sur le canal de courriel sécurisé",
            "error-secured-on-external": "Impossible d'ajouter un courriel sécurisé sur le canal de courriel externe",
            "errors": {
              "no-account-found-external": "Aucun compte ayant le mail externe '{email}' n'a été trouvé",
              "no-account-found-secured": "Aucun compte ayant le mail securisé '{email}' n'a été trouvé"
            },
            "loading": "Chargement en cours...",
            "no-results": "Aucun résultat",
            "type": {
              "actor": "Conseiller : ",
              "entity": "Entité : ",
              "external": "Ext.",
              "interlocutor": "",
              "trustedInterlocutor": "Interlocuteur de confiance"
            }
          },
          "tags-multi-selector": {
            "error": "Une erreur est apparue pendant la recherche.",
            "loading": "Chargement en cours...",
            "no-results": "Aucun résultat"
          }
        },
        "panels": {
          "showLess": "Afficher moins",
          "showMore": "Afficher plus"
        },
        "presets-selector": {
          "title": "Prédéfinis"
        },
        "rating": {
          "message-info": {
            "error": {
              "rate": "Une erreur s'est produite lors de la sauvegarde de la note, veuillez réessayer plus tard."
            },
            "info": {
              "rate": "La note a été sauvegardée avec succès."
            }
          },
          "title": "Voulez-vous changer votre note ?",
          "title-new": "Êtes-vous satisfait par cet échange ?"
        },
        "result-list": {
          "results": {
            "error": "Une erreur est survenue pendant le chargement. Veuillez réessayer ultérieurement.",
            "loading": "Chargement ...",
            "none": "Aucun résultat disponible",
            "wait": "Veuillez patienter"
          }
        },
        "text-editor": {
          "actions-list": {
            "f-ai": {
              "choose-template": "Choisissez un modèle de réponse :",
              "ko-declaration": "Déclarer les modèles comme non satisfaisants :",
              "show-templates": "Afficher les modèles de réponses de l'analyse sémantique"
            },
            "f-aligncenter": "Centrer",
            "f-alignleft": "Aligner à gauche",
            "f-alignright": "Aligner à droite",
            "f-attachments": {
              "help": "Copier les pièces jointes"
            },
            "f-bold": "Gras",
            "f-color": {
              "help": "Couleur",
              "label": "Changer la couleur du texte"
            },
            "f-font": {
              "help": "Font",
              "label": "Choisissez la famille de fontes"
            },
            "f-gallery": "Ajouter une image",
            "f-italic": "Italique",
            "f-link": {
              "help": "Ajouter un lien",
              "ok": "OK",
              "url": "URL"
            },
            "f-list": "Passer de/à la liste",
            "f-signature": {
              "apply": "Appliquer la signature",
              "choose": "Choisissez une signature",
              "label": "Signature..."
            },
            "f-size": {
              "help": "Taille de la fonte",
              "label": "Choisissez la taille de la fonte"
            },
            "f-spellCheck": {
              "check-spelling": "Vérifier l'orthographe",
              "choose-language": "Choisissez une langue",
              "ignore": "Ignorer",
              "ignore-all": "Ignorer tout"
            },
            "f-underline": "Souligné",
            "f-upload": {
              "help": "Ajouter piéce jointe"
            }
          },
          "initial-attachments": {
            "copy-attachment": "Copier une pièce jointe",
            "message-info": {
              "error": {
                "copy-attachment": "Une erreur s'est produite lors de la copie de la pièce jointe. Merci de rééssayer ultérieurement."
              },
              "info": {
                "file-removed": "La pièce jointe a été supprimée.",
                "file-uploaded": "La pièce jointe a été copiée."
              }
            }
          },
          "inline-images": {
            "message-info": {
              "error": {
                "empty-file": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
                "preview": "Le chargement de l'image a échoué, veuillez réessayer ultérieurement."
              }
            }
          },
          "message-composer-attachments": {
            "delete": "Supprimer cette pièce jointe",
            "download": "Télécharger cette pièce jointe",
            "dropdown-button-title": "Actions",
            "message-info": {
              "error": {
                "bad-content-type": "Le type de la pièce jointe n'est pas autorisé.",
                "download": "Le téléchargement a échoué. Veuillez réessayer ultérieurement.",
                "empty-file": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
                "failed-to-upload": "Impossible d'envoyer la pièce jointe. Merci de rééssayer ultérieurement",
                "file-name-already-used": "Une pièce jointe avec le même nom existe déjà.",
                "maxAttachmentReached": "Vous avez atteint le nombre maximum de pièces jointes autorisé.",
                "message-size-limit": "Les pièces jointes dépassent la taille limite.",
                "preview": "Le chargement de l'image a échoué, veuillez réessayer ultérieurement."
              },
              "info": {
                "file-uploaded": "La pièce jointe a été sauvegardée",
                "file-uploading": "Sauvegarde en cours ..."
              }
            },
            "preview": "Visualiser cette pièce jointe"
          }
        }
      },
      "file-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
          "NOT_ALLOWED_CONTENT_TYPE": "Le type de la pièce jointe n'est pas autorisé.",
          "addFile": "Une erreur est apparue, la pièce jointe n'a pas été ajoutée. Veuillez réessayer ultérieurement.",
          "contentType": "Le type de la pièce jointe n'est pas autorisé.",
          "length": "La taille de la pièce jointe est supérieure à la taille maximale autorisée."
        }
      },
      "helpers": {
        "date-short-view": {
          "today": "Aujourd'hui, {hour}"
        }
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Conseiller } CLIENT {Interlocuteur } other {}}{gender, select, MR {Mr }  MME {Mme } other {}}{lastname} {firstname}",
        "unknown-person": "Personne inconnue"
      },
      "inline-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
          "length": "La taille de l'image en ligne est supérieure à la taille maximale autorisée."
        }
      },
      "numeric-unit": {
        "billion": "G",
        "million": "M",
        "thousand": "K"
      },
      "pagination": "pagination",
      "skip-link": "Navigation (Quitter le menu)",
      "spellchecker": {
        "languages": {
          "de": "Allemand",
          "en": "Anglais",
          "fr": "Français",
          "nl": "Néerlandais",
          "ru": "Russe"
        },
        "message-info": {
          "error": {
            "unknown": "Une erreur est apparue."
          }
        }
      },
      "theme": {
        "criticalDueDateDelay": {
          "error": {
            "message": "Ce champ doit être supérieur à En retard dans"
          }
        }
      },
      "treeview": {
        "button": {
          "list.label": "Afficher la liste des résultats",
          "search.label": "Rechercher",
          "select.default.label": "Select",
          "tree.label": "Afficher l'arborescence complète"
        },
        "message": {
          "noresult": "Aucun résultat de recherche"
        }
      }
    },
    "shared.components.a11y.modals.alert-modal.closeButtonText": "Fermer",
    "shared.components.a11y.modals.alert-modal.closeButtonTitle": "Fermer la fenêtre",
    "shared.components.dropdown.default-button-title": "Actions",
    "shared.components.form-component.form-fields-required-label": "Les champs marqués d'une * sont obligatoires.",
    "shared.components.form-group-component.missing-text": "Le champ est obligatoire.",
    "shared.components.modals.document-preview.error-message": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
    "shared.components.modals.document-preview.modal-title": "Prévisualiser le fichier",
    "shared.components.modals.document-preview.select-page": "Sélectionner une page",
    "shared.components.modals.document-preview.upload-by": "Téléchargé par {actor} le {date}",
    "shared.components.modals.document-preview.zoom-in": "Zoomer",
    "shared.components.modals.document-preview.zoom-out": "Dézoomer",
    "shared.components.modals.image-preview.error-message": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
    "shared.components.modals.image-preview.loading-message": "Chargement en cours",
    "shared.components.modals.image-preview.modal-title": "Prévisualiser",
    "shared.components.modals.image-preview.upload-by": "Déposé par",
    "shared.components.modals.image-preview.upload-date": "Déposé le",
    "shared.components.modals.image-preview.zoom-in": "Zoomer",
    "shared.components.modals.image-preview.zoom-out": "Dézoomer",
    "shared.components.modals.modal-component.modal-cancel": "Annuler",
    "shared.components.modals.modal-component.modal-confirm": "Confirmer",
    "shared.components.modals.title.closeButton": "Fermer ce message",
    "shared.components.result-list.results.error": "Une erreur est apparue au chargement de la page. Veuillez vérifier et réessayer ultérieurement.",
    "shared.components.result-list.results.loading": "Chargement en cours",
    "shared.components.result-list.results.none": "Aucun résultat",
    "shared.components.result-list.results.wait": "Veuillez patienter",
    "shared.components.text-editor.actions-list.f-aligncenter": "Centrer",
    "shared.components.text-editor.actions-list.f-alignleft": "Aligner à gauche",
    "shared.components.text-editor.actions-list.f-alignright": "Aligner à droite",
    "shared.components.text-editor.actions-list.f-bold": "Gras",
    "shared.components.text-editor.actions-list.f-color.help": "Couleur",
    "shared.components.text-editor.actions-list.f-color.label": "Changer la couleur du texte",
    "shared.components.text-editor.actions-list.f-font.help": "Font",
    "shared.components.text-editor.actions-list.f-font.label": "Choisissez la famille de fontes",
    "shared.components.text-editor.actions-list.f-gallery": "Ajouter une image",
    "shared.components.text-editor.actions-list.f-italic": "Italique",
    "shared.components.text-editor.actions-list.f-link.help": "Ajouter un lien",
    "shared.components.text-editor.actions-list.f-link.ok": "OK",
    "shared.components.text-editor.actions-list.f-link.url": "URL",
    "shared.components.text-editor.actions-list.f-list": "Passer de/à la liste",
    "shared.components.text-editor.actions-list.f-size.help": "Taille de la fonte",
    "shared.components.text-editor.actions-list.f-size.label": "Choisissez la taille de la fonte",
    "shared.components.text-editor.actions-list.f-spellCheck.check-spelling": "Vérifier l'orthographe",
    "shared.components.text-editor.actions-list.f-spellCheck.choose-language": "Choisissez une langue",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore": "Ignorer",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore-all": "Ignorer tout",
    "shared.components.text-editor.actions-list.f-underline": "Souligné",
    "shared.components.text-editor.actions-list.f-upload.help": "Ajouter pièce jointe",
    "shared.components.text-editor.message-composer-attachments.delete": "Supprimer cette pièce jointe",
    "shared.components.text-editor.message-composer-attachments.download": "Télécharger cette pièce jointe",
    "shared.components.text-editor.message-composer-attachments.dropdown-button-title": "Actions",
    "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type": "Le type de la pièce jointe n'est pas autorisé.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.download": "Problème de communication, téléchargement interrompu. Merci de réessayer ultérieurement.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file": "Problème de communication, téléchargement interrompu. Merci de réessayer ultérieurement.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload": "Problème de communication, téléchargement interrompu. Merci de réessayer ultérieurement.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used": "Vous ne pouvez pas télécharger de pièces jointes vides. Merci de réessayer avec un fichier valide.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached": "Vous avez atteint le nombre maximum de pièces jointes autorisé.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit": "Les pièces jointes dépassent la taille limite.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.preview": "Le chargement de l'image a échoué, veuillez réessayer ultérieurement.",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded": "La pièce jointe a été sauvegardée",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading": "Sauvegarde en cours ...",
    "shared.components.text-editor.message-composer-attachments.preview": "Visualiser la pièce jointe",
    "shared.file-uploader.error.EMPTY_ATTACHMENT": "Vous ne pouvez pas télécharger de pièces jointes vides. Merci de réessayer avec un fichier valide.",
    "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE": "Le type de la pièce jointe n'est pas autorisé.",
    "shared.file-uploader.error.addFile": "Une erreur est apparue, la pièce jointe n'a pas été ajoutée. Veuillez réessayer ultérieurement.",
    "shared.file-uploader.error.contentType": "Le type de la pièce jointe n'est pas autorisé.",
    "shared.file-uploader.error.length": "Le fichier que vous souhaitez télécharger est trop volumineux.",
    "shared.helpers.date-short-view.today": "Aujourd'hui, {hour}",
    "shared.numeric-unit.billion": "G",
    "shared.numeric-unit.million": "M",
    "shared.numeric-unit.thousand": "K",
    "shared.pagination": "pagination",
    "shared.skip-link": "Aller à la page principale (Quitter le menu)",
    "side-panel": {
      "accessibility": {
        "buttons": {
          "close": {
            "title": "Fermer"
          }
        },
        "contrast": {
          "enhanced": "Renforcés",
          "standard": "Standard"
        },
        "disparities": "Contrastes",
        "message-info": {
          "error": {
            "unknown": "Une erreur s'est produite lors de la modification du contraste d'accessibilité, veuillez réessayer plus tard."
          }
        },
        "title": "Accessibilité"
      },
      "burger": {
        "label": "Afficher / Masquer le menu"
      },
      "hide-menu": "Cacher le menu latéral",
      "logo": {
        "by": "by",
        "ti": "Trusted Interactions",
        "worldline": "Worldline"
      },
      "logo-alt": "WL Trusted Interactions",
      "module": {
        "title": "Module"
      },
      "settings": {
        "myprofile": "Paramètres",
        "name": "Préférences",
        "preferences": "Préférences"
      },
      "show-menu": "Afficher le menu latéral",
      "toggle-title": "Ouvre/Ferme le menu"
    },
    "side-panel.burger.label": "Afficher / Masquer le menu",
    "side-panel.hide-menu": "Cacher le menu latéral",
    "side-panel.show-menu": "Afficher le menu latéral",
    "side-panel.toggle-title": "Ouvre/Ferme le menu",
    "sso-error.text": "Une erreur est survenue lors de la connexion. Fermez cette fenêtre et essayer de nouveau depuis votre espace personnel.",
    "sso-error.title": "Erreur de connexion",
    "topbar": {
      "burger": {
        "label": "Afficher / Masquer le menu"
      },
      "interlocutors": {
        "myself": "Mon compte"
      },
      "message-info": {
        "log-out-confirmation": "Souhaitez-vous vous déconnecter? Vous perdrez les données non sauvegardées."
      },
      "module": {
        "administration": "Administration",
        "interactions": "Messages",
        "profile": "Profil",
        "projects": "Projets"
      },
      "nav": {
        "label": "Barre multi module"
      },
      "organisation": {
        "name": "equensWorldline"
      },
      "settings": {
        "profile": "Paramètres",
        "sign-out": "Déconnexion"
      }
    },
    "topbar.message-info.log-out-confirmation": "Êtes-vous sûr de vouloir vous déconnecter ?",
    "topbar.module.interactions": "Messages",
    "topbar.module.profile": "Profil",
    "topbar.nav.label": "Barre multi module",
    "topbar.organisation.name": "Messagerie sécurisée Assuré"
  };
  _exports.default = _default;
});