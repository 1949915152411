define("ticust-s2h-web-application/routes/application", ["exports", "ticust-web-common/routes/host-base-route", "ember-uuid", "moment"], function (_exports, _hostBaseRoute, _emberUuid, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _hostBaseRoute.default.extend({
    queue: Ember.inject.service(),
    sdhService: Ember.inject.service(),
    session: Ember.inject.service(),
    config: Ember.inject.service(),
    intl: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    identity: Ember.inject.service('identity-service'),
    apiSettings: Ember.inject.service('api-settings'),
    defaultLang: 'en-gb',
    ajax: Ember.inject.service(),
    timeoutService: Ember.inject.service('timeout-service'),
    activate: function activate() {
      // Create urlParams query string
      var urlParams = new URLSearchParams(window.location.search);
      var charte = urlParams.get('charte');
      if (charte) {
        charte = charte.toLowerCase();
        document.documentElement.setAttribute('data-charte', charte);
        document.cookie = "charte=" + charte + "; Path=/;";
      } else {
        // If no charte parameter was send, check the cookies.
        var match = document.cookie.match(new RegExp('(^| )' + "charte" + '=([^;]+)'));
        if (match) {
          document.documentElement.setAttribute('data-charte', match[2]);
        } else {
          // Set  "gray" charte as the default value
          document.documentElement.setAttribute('data-charte', 'gray');
          document.cookie = 'charte=gray; Path=/;';
        }
      }
      var lang = urlParams.get('lang');
      if (lang) {
        var langParam = this.setLanguage(lang);
        // only needed before connection (unknow settings)
        this.get('intl').setLocale(langParam);
      }
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;
      Ember.run.backburner.DEBUG = true;
      this.tiLogger.debug('App beforeModel', transition);
      var sdhService = this.sdhService;
      if (sdhService.status === 'init') {
        this.tiLogger.debug('Save attemptedTransition', transition);
        this.set('session.attemptedTransition', transition);
        this.timeoutService.load();
        return sdhService.load().then(function () {
          _this.tiLogger.debug('After S2H Service Init', transition);
          var attemptedTransition = _this.session.attemptedTransition;
          if (attemptedTransition) {
            attemptedTransition.retry();
            _this.set('session.attemptedTransition', null);
          }
        });
      }
      // Add sessionId before authentication
      if (!this.session.data || !this.session.data.sessionId) {
        var time = (0, _moment.default)(new Date().getTime()).format('HHmmss');
        this.set('session.data.sessionId', _emberUuid.default.v4() + '-' + time);
      }
    },
    setLanguage: function setLanguage(lang) {
      var languageMap = {
        en: "en-gb",
        fr: "fr-fr",
        it: "it-it",
        es: "es-es",
        de: "de-de",
        pt: "pt-pt",
        nl: "nl-nl"
      };
      var prefix = lang.substring(0, 2).toLowerCase();
      return languageMap[prefix] || "en-gb";
    },
    sessionInvalidated: function sessionInvalidated() {
      this.transitionTo('ticust-web-module-authentication.logout');
    },
    actions: {
      sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
        this.tiLogger.debug('TransitionTo previous route before Auth');
        this.transitionTo(this.controllerFor('application').get('previousRouteName'));
      }
    }
  });
  _exports.default = _default;
});