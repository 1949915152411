define("ticust-s2h-web-application/authenticators/sdh-token-auth", ["exports", "ember-simple-auth-token/authenticators/jwt", "lodash"], function (_exports, _jwt, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _jwt.default.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    config: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    makeRequest: function makeRequest(url, data, headers) {
      var _this = this;
      if (!data) {
        this.tiLogger.debug('Call S2H context');
        return this.ajax.raw('/api/customer/authentication/sso/context', {
          method: 'GET',
          headers: this.identity.ajaxHeaders
        }).then(function (response) {
          return {
            json: response.response
          };
        });
      }
      // Refresh token
      this.tiLogger.debug('Call refresh token');
      return this._super(url, data, headers).then(function () {
        var data = _lodash.default.clone(_this.session.data.authenticated);
        return {
          json: data
        };
      });
    },
    handleAuthResponse: function handleAuthResponse(response) {
      if (Ember.isEmpty(response.userId)) {
        throw new Error('Token is empty. Please check your backend response.');
      }

      /* fix expired token issue when device clock is out of sync with timezone
         by overriding the token's exp */
      var expiresAt = this.getCurrentTime() + response.timeToLive;
      response.expiresAt = expiresAt;
      this.scheduleAccessTokenRefresh(expiresAt, {});
      return response;
    },
    restore: function restore(data) {
      var expiresAt = data.expiresAt;
      var now = this.getCurrentTime();
      if (expiresAt > now) {
        var wait = (expiresAt - now - this.refreshLeeway) * 1000;
        if (wait > 0) {
          this.scheduleAccessTokenRefresh(expiresAt, {});
          return Ember.RSVP.Promise.resolve(data);
        }
      } else {
        return Ember.RSVP.Promise.resolve(this.refreshAccessToken({}));
      }
    }
  });
  _exports.default = _default;
});