define("ticust-s2h-web-application/services/topbar-service", ["exports", "ti-web-shared/services/topbar-service"], function (_exports, _topbarService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _topbarService.default;
    }
  });
});