define("ticust-s2h-web-application/components/ticust-web-module-interactions/conversation/composer/conversation-composer-create", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/composer/conversation-composer-create", "ticust-s2h-web-application/mixins/fileUploadMixin"], function (_exports, _conversationComposerCreate, _fileUploadMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _conversationComposerCreate.default.extend(_fileUploadMixin.default, {
    allowedTypes: undefined,
    // Allowed File Types
    hasClicked: false,
    // Internal state to avoid double action
    willDestroyElement: function willDestroyElement() {
      this.set('isReadOnlyForTheme', true);
    },
    actions: {
      triggerFilePicker: function triggerFilePicker(event) {
        this.set('hasClicked', true);
        document.getElementById('editor-file-uploader').click();
      }
    }
  });
  _exports.default = _default;
});