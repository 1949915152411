define("ticust-s2h-web-application/models/project", ["exports", "ti-web-shared/models/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _project.default;
    }
  });
});