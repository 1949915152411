define("ticust-s2h-web-application/adapters/project-nature", ["exports", "ticust-web-common/adapters/project-nature"], function (_exports, _projectNature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _projectNature.default;
    }
  });
});