define("ticust-s2h-web-application/initializers/controlers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/conversation", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/conversation"], function (_exports, _conversation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'controller/ticust-web-module-interactions/interlocutors/interlocutor/conversations/conversation',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversation.default.reopen({
    session: Ember.inject.service(),
    enableRating: false,
    draftSubject: Ember.computed('conversation.subject', function () {
      var _this2 = this;
      var tempDraftSubject = this.get('conversation.subject');
      var tempLastAdvisorMessageDate = this.get('conversation.creationDate');
      this.get('messages').forEach(function (message) {
        if (!_this2._isOwnedByCustomer(message.owners) && message.receiveDate.getTime() > tempLastAdvisorMessageDate.getTime()) {
          tempDraftSubject = message.messageContent.headers.Subject.get(0);
          tempLastAdvisorMessageDate = message.receiveDate;
        }
      });
      return tempDraftSubject;
    }),
    displayConversationComposer: Ember.computed('isConversationComposerEnabled', 'identity.hasRoleConversationCreateOrAnswer', 'conversationHasNoReadonlyMessage', 'displayReadOnlyLabel', function () {
      return this.get('isConversationComposerEnabled') && this.get('identity.hasRoleConversationCreateOrAnswer') && this.get('conversationHasNoReadonlyMessage') && !this.get('displayReadOnlyLabel');
    }),
    displayReadOnlyLabel: Ember.computed('displayableMessages.[]', function () {
      return this.get('displayableMessages').any(function (message) {
        return message.get('messageContent.headers.X-TI-READONLY.0') > 0;
      });
    }),
    actions: {
      delete: function _delete() {
        var _this3 = this;
        // S2H Override, change confirm pop-up wording
        if (confirm(this.get('intl').t('interactions.conversation.message-info.confirm.pop-up.delete'))) {
          this.get('conversation').set('status', 'DELETED');
          this.set('isDeleting', true);
          this._saveChange('delete').then(function () {
            var interlocutor = _this3.get('interlocutorController.model');
            _this3.transitionToRoute('interlocutors.interlocutor', interlocutor);
          });
        }
      },
      sendMessage: function sendMessage(messageContent) {
        var _this = this;

        // BEGIN -Add user information before sending
        var userInfo = this.get('session.data.authenticated.userInfo');
        var userInfoListHtml = "";
        Object.values(userInfo).forEach(function (value) {
          userInfoListHtml += Ember.String.htmlSafe(value.libelle) + " : " + Ember.String.htmlSafe(value.data) + "<br/>";
        });
        messageContent.bodyTextHtmlParts.firstObject.set("body", messageContent.bodyTextHtmlParts.firstObject.body + "<br/>" + userInfoListHtml);
        var userInfoListText = "";
        Object.values(userInfo).forEach(function (value) {
          userInfoListText += value.libelle + " : " + value.data + "\n\r";
        });
        messageContent.bodyTextPlainParts.firstObject.set("body", messageContent.bodyTextPlainParts.firstObject.body + "\n\r" + userInfoListText);
        // END - Add user information before sending

        // Create the record
        var record = this.createOrGetDraftRecord();
        record.set('status', 'SENT');
        record.set('receiveDate', new Date());
        record.set('messageContent', messageContent);
        record.set('participants', this.get('draftParticipants'));
        // BEGIN - Contact simplification FROM supp. of displayName
        var simplifiedParticipants = record.get("participants").map(function (p) {
          return p.type === 'FROM' ? {
            email: p.email,
            type: p.type
          } : p;
        });
        record.set('participants', simplifiedParticipants);
        var _from = record.get("participants").find(function (p) {
          return p.type === 'FROM';
        });
        record.set("messageContent.headers.From.0", "<" + _from.email + ">");
        // END - Contact simplification FROM supp. of displayName
        // Save
        this.set('isSendingMessage', true);
        this.set('sendingMessage', record);
        record.save().then(function () {
          _this.showSuccess('interactions.conversation.success.send');
          // Reset composer
          _this.set('sendingMessage', null);
        }).catch(function (error) {
          _this.showErrorAndLog('interactions.conversation.errors.send', 'Failed to send the message', error);
        }).finally(function () {
          _this.set('isSendingMessage', false);
          _this.send('refreshModel');
        });
      }
    }
  });
});