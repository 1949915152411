define("ticust-s2h-web-application/components/ticust-web-module-interactions/conversation/conversation-message-list", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-list"], function (_exports, _conversationMessageList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _conversationMessageList.default;
  _exports.default = _default;
});