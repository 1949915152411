define("ticust-s2h-web-application/initializers/controlers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/create", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/create", "ember-cp-validations"], function (_exports, _create, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'controller/ticust-web-module-interactions/interlocutors/interlocutor/conversations/create',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.recipient': {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    'model.conversation.subject': {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 256
      })]
    }
  });
  _create.default.reopen(Validations, {
    init: function init() {
      this._super.apply(this, arguments);
      // Inject manually service
      this.set('reference', Ember.getOwner(this).dependencies.services['reference']);
    },
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    isReadOnlyForTheme: true,
    _editorInstance: undefined,
    themeHasSelected: false,
    insertBody: function insertBody(body) {
      var editor = this.get('_editorInstance');
      editor.setEmptyEditor();
      editor.insertTemplateBody(editor.ttSelection, body);
    },
    getConfirmMessage: function getConfirmMessage() {
      return this.get('intl').t('interactions.message-editor.message-info.info.pop-up.replace-subject');
    },
    trimSubject: function trimSubject(subject) {
      return subject.length > 256 ? subject.substring(0, 256) : subject;
    },
    createConversationAndMessage: function createConversationAndMessage(message, conversationStatus) {
      // Validate the form
      if (!this.get('validations.isValid')) {
        this.set('showErrors.recipient', true);
        this.set('showErrors.subject', true);
        this.showError('interactions.conversation.create.message-info.error.invalid-form');
        return;
      }
      // BEGIN -Add user information before sending
      var userInfo = this.get('session.data.authenticated.userInfo');
      var userInfoListHtml = "";
      Object.values(userInfo).forEach(function (value) {
        userInfoListHtml += Ember.String.htmlSafe(value.libelle) + " : " + Ember.String.htmlSafe(value.data) + "<br/>";
      });
      message.bodyTextHtmlParts.firstObject.set("body", message.bodyTextHtmlParts.firstObject.body + "<br/>" + userInfoListHtml);
      var userInfoListText = "";
      Object.values(userInfo).forEach(function (value) {
        userInfoListText += value.libelle + " : " + value.data + "\n\r";
      });
      message.bodyTextPlainParts.firstObject.set("body", message.bodyTextPlainParts.firstObject.body + "\n\r" + userInfoListText);
      // BEGIN - Contact simplification FROM supp. of displayName
      var _participants = this.get('participants').map(function (p) {
        return p.type === 'FROM' ? {
          email: p.email,
          type: p.type
        } : p;
      });
      this.set('participants', _participants);
      var _from = this.get("participants").find(function (p) {
        return p.type === 'FROM';
      });
      message.set("headers.From.0", "<" + _from.email + ">");
      // END - Contact simplification FROM supp. of displayName
      this.send('createConversationInRoute', message, "SENT", conversationStatus);
      this.set('displayModal', false);
    },
    actions: {
      selection: function selection(theme) {
        if (theme.children.length === 0) {
          if (!(this.get('themeHasSelected') && !confirm(this.getConfirmMessage()))) {
            this.set('isReadOnlyForTheme', false);
            this.insertBody("<p>" + theme.precision + "</p>");
            this.set('themeHasSelected', true);
            this.set('currentSubject', this.trimSubject(theme.libelle_complet));
            this.set('recipient', Ember.Object.create({
              display: this.get('identity.userCompany'),
              internalEmailAddress: theme.destinataire_gestion
            }));
          }
        }
      },
      sendMessage: function sendMessage(message) {
        var _this = this;
        if (this.get('isReadOnlyForTheme')) {
          // The message is already sent
          return;
        }
        // The message is being sent
        this.set('isReadOnlyForTheme', true);
        this.get('reference').reference().then(function (reference) {
          if (message) {
            // Add a reference to the subject of the conversation and the first message
            _this.set("currentSubject", reference + " " + _this.get("currentSubject"));
            message.headers.Subject[0] = _this.get("currentSubject");
            if (_this.get('isSemanticAnalysisEnabled')) {
              _this.sendMessage(message);
              return;
            }
            _this.createConversationAndMessage(message);
          }
        });
      },
      cancelMessage: function cancelMessage() {
        this.send('cancelCreateInRoute');
        this.set('themeHasSelected', false);
      },
      onEditorLoaded: function onEditorLoaded(editor) {
        this.set('_editorInstance', editor);
        this.set('themeHasSelected', false);
      },
      // Save Draft
      saveDraftMessage: function saveDraftMessage(draftMessage) {
        // Validate the form
        if (!this.get('validations.isValid')) {
          this.set('showErrors.recipient', true);
          this.set('showErrors.subject', true);
          this.showError('interactions.conversation.create.message-info.error.invalid-form');
          return;
        }
        this.send('createConversationInRoute', draftMessage, "DRAFT", 'INIT');
        this.set('themeHasSelected', false);
      }
    }
  });
});