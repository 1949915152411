define("ticust-s2h-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/create", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/create',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _create.default.reopen({
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName != "ticust-web-module-authentication.logout" && this.get('controller.isDirty') && !confirm(this.get('intl').t('interactions.conversation.create.message-info.error.pop-up.leaving-page'))) {
          transition.abort();
          return;
        }
        this.get('controller.conversation').rollbackAttributes();
        if (this.get('controller.newMessage')) {
          this.get('controller.newMessage').rollbackAttributes();
        }
      }
    }
  });
});