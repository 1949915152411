define("ticust-s2h-web-application/components/ticust-web-module-interactions/interlocutors/interlocutor/conversations/themes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service(),
    init: function init() {
      this._super(arguments);
      this.set('themes', this.get('session.data.authenticated.themes'));
    }
  });
  _exports.default = _default;
});