define("ticust-s2h-web-application/services/reference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    identity: Ember.inject.service('identity-service'),
    ajax: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    reference: function reference() {
      return this.ajax.raw('/api/reference', {
        method: 'GET',
        headers: this.identity.ajaxHeaders
      }).then(function (ref) {
        return "[TI" + ref.response.reference + "]";
      });
    }
  });
  _exports.default = _default;
});