define("ticust-s2h-web-application/components/page-content-error", ["exports", "ticust-web-common/components/page-content-error"], function (_exports, _pageContentError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pageContentError.default;
    }
  });
});