define("ticust-s2h-web-application/models/prospect", ["exports", "ticust-web-common/models/prospect"], function (_exports, _prospect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _prospect.default;
    }
  });
});