define("ticust-s2h-web-application/routes/engine-base-route", ["exports", "ticust-web-common/routes/engine-base-route"], function (_exports, _engineBaseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _engineBaseRoute.default;
    }
  });
});