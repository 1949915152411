define("ticust-s2h-web-application/initializers/components/ticust-web-module-interactions/conversation/composer/conversation-composer-reply", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/composer/conversation-composer-reply", "ticust-s2h-web-application/mixins/fileUploadMixin"], function (_exports, _conversationComposerReply, _fileUploadMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/composer/conversation-composer-reply',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationComposerReply.default.reopen(_fileUploadMixin.default, {
    allowedTypes: undefined,
    // Allowed File Types
    hasClicked: false,
    // Internal state to avoid double action

    actions: {
      triggerFilePicker: function triggerFilePicker(event) {
        this.set('hasClicked', true);
        document.getElementById('editor-file-uploader').click();
      }
    }
  });
});