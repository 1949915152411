define("ticust-s2h-web-application/templates/ticust-web-module-authentication/sso-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jyZoBhBX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\"],[11,\"class\",\"login disconnection\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[10],[0,\"\\n  \"],[7,\"main\"],[9],[0,\"\\n    \"],[7,\"header\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[1,[27,\"t\",[\"sso-error.title\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"t\",[\"sso-error.text\"],null],false],[10],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ticust-s2h-web-application/templates/ticust-web-module-authentication/sso-error.hbs"
    }
  });
  _exports.default = _default;
});