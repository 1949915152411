define("ticust-s2h-web-application/mixins/fileUploadMixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    config: Ember.inject.service(),
    fileUtils: Ember.inject.service('file-utils'),
    identity: Ember.inject.service('identity-service'),
    ajax: Ember.inject.service(),
    _attachments: [],
    _messagesIntlPrefix: 'shared.components.text-editor.message-composer-attachments.',
    _isAttachmentLimitOK: Ember.computed('_attachments.[]', 'attachmentsMaxNumber', function () {
      return this.get('_attachments.length') < this.get('attachmentsMaxNumber');
    }),
    // Attachments
    _attachmentsUploadInProgress: Ember.computed('_attachments.[]', '_attachments.@each.isUploaded', function () {
      return this.get('_attachments').some(function (attachment) {
        return !attachment.get('isUploaded');
      });
    }),
    _attachmentParts: Ember.computed('_attachments.[]', function () {
      var _this = this;
      return this.get('_attachments').map(function (attachment) {
        var filename = attachment.filename;
        var headers = {};
        headers['Content-Disposition'] = _this.getAttachmentDispositionHeader(filename);
        headers['Content-Type'] = _this.getAttachmentContentTypeHeader(attachment.contentType, filename);
        return _this.get('store').createFragment('simple-part-s3', {
          headers: headers,
          s3Id: attachment.s3Id,
          size: attachment.size
        });
      });
    }),
    exceedsMaxSizeOfMessage: function exceedsMaxSizeOfMessage(file) {
      var sizeOfAttachments = this.get('_attachments.[]').reduce(function (accumulator, item) {
        return accumulator + item.size;
      }, 0);
      return sizeOfAttachments + file.size > this.get('maxSizeOfMessage');
    },
    exceedsMaxNbOfAttachments: function exceedsMaxNbOfAttachments() {
      var maxNb = this.get('maxNumOfAttachments');
      if (this.get('_attachments.[]')) {
        return maxNb !== 0 && this.get('_attachments.[]').length >= maxNb;
      } else return true;
    },
    attachmentNameAlreadyUsed: function attachmentNameAlreadyUsed(file) {
      var nameAlreadyUsed = false;
      this.get('_attachments.[]').forEach(function (item) {
        if (file.name.toLowerCase() === item.filename.toLowerCase()) {
          nameAlreadyUsed = true;
        }
      });
      return nameAlreadyUsed;
    },
    _error: function _error(key, error) {
      this.showErrorAndLog(this.get('_messagesIntlPrefix') + 'message-info.error.' + key, 'An error occurred while uploading the attachment.', error);
    },
    isValidFile: function isValidFile(file) {
      // prevent users from uploading empty files
      if (file.size <= 0) {
        this.showError(this.get('_messagesIntlPrefix') + 'message-info.error.empty-file');
        return false;
      }
      if (this.exceedsMaxNbOfAttachments()) {
        this.showError(this.get('_messagesIntlPrefix') + 'message-info.error.maxAttachmentReached');
        return false;
      }
      if (this.exceedsMaxSizeOfMessage(file)) {
        this.showError(this.get('_messagesIntlPrefix') + 'message-info.error.message-size-limit');
        return false;
      }
      if (!this.get('allowDuplicateFileName') && this.attachmentNameAlreadyUsed(file)) {
        this.showError(this.get('_messagesIntlPrefix') + 'message-info.error.file-name-already-used');
        return false;
      }
      return true;
    },
    initiateAttachmentUploading: function initiateAttachmentUploading(file) {
      var isVoiceMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!this.isValidFile(file)) {
        return null;
      }
      this.onComposerChanged();
      var newAttachment = Ember.Object.create({
        s3Id: null,
        isUploaded: false,
        filename: file.name,
        contentType: this.get('fileUtils').getFileTypeByExtension(file),
        size: file.size,
        file: file
      });
      if (isVoiceMessage) {
        newAttachment['isVoiceMessage'] = true;
      }
      this.get('_attachments').addObject(newAttachment);
      this.get('uploadAttachmentOnStartUpload')(file, newAttachment);
      return newAttachment;
    },
    executeOnDidUpload: function executeOnDidUpload(file, event) {
      var attachment = this.get('_attachments').find(function (attachment) {
        return attachment.get('file') === file;
      });
      if (attachment) {
        attachment.set('isUploaded', true);
        attachment.set('s3Id', event.contentRef);
        this.set('_lastAttachment', attachment);
        this.get('uploadAttachmentOnDidUpload')(file, event, attachment);
      }
    },
    executeOnDidError: function executeOnDidError(file, jqXHR, textStatus, errorThrown) {
      var attachment = this.get('_attachments').find(function (attachment) {
        return attachment.get('file') === file;
      });
      this.get('_attachments').removeObject(attachment);
      if (jqXHR.responseJSON && jqXHR.responseJSON.metadatas && jqXHR.responseJSON.metadatas.ATTACHMENT_STATUS === 'NOT_ALLOWED_CONTENT_TYPE') {
        this._error('bad-content-type', errorThrown);
      } else {
        this._error('failed-to-upload', errorThrown);
      }
      this.get('uploadAttachmentDidError')(file, attachment);
    },
    uploadAttachmentOnStartUpload: function uploadAttachmentOnStartUpload() {},
    // Start Upload Action
    uploadAttachmentOnDidUpload: function uploadAttachmentOnDidUpload() {},
    // Did Upload Action
    uploadAttachmentDidError: function uploadAttachmentDidError() {},
    // Did Error Action

    actions: {
      attachmentOnStartUpload: function attachmentOnStartUpload(file) {
        return !!this.initiateAttachmentUploading(file);
      },
      attachmentOnDidUpload: function attachmentOnDidUpload(file, event) {
        this.executeOnDidUpload(file, event);
      },
      attachmentDidError: function attachmentDidError(file, jqXHR, textStatus, errorThrown) {
        this.executeOnDidError(file, jqXHR, textStatus, errorThrown);
      },
      copyAttachment: function copyAttachment(attachment) {
        var _this2 = this;
        var ajaxUrl = '/conversation-message-attachments/' + attachment.s3Id + '?forward=true';
        this.get('ajax').raw(ajaxUrl, {
          method: 'POST',
          headers: this.get('identity.ajaxHeaders')
        }).then(function (result) {
          var _attachment = result.response;
          _attachment.isUploaded = true;
          _attachment.name = result.response.filename;
          _attachment.originalAttachmentId = attachment.s3Id;
          var res = _this2.addAttachment(_attachment);
          if (res != null) {
            Ember.set(attachment, 'isCopied', true);
            Ember.set(attachment, 'copyId', _attachment.contentRef);
            _this2.showSuccess('shared.components.text-editor.initial-attachments' + '.message-info.info.file-uploaded');
          }
        }).catch(function (error) {
          _this2.showErrorAndLog('shared.components.text-editor.initial-attachments' + '.message-info.error.copy-attachment', 'An error appear during the copying the attachment', error);
        });
      },
      deleteCopiedAttachment: function deleteCopiedAttachment(attachment) {
        var self = this;
        this.get('_attachments').forEach(function (item) {
          if (item.s3Id === attachment.copyId) {
            self.get('_attachments').removeObject(item);
            self.showSuccess('shared.components.text-editor.initial-attachments' + '.message-info.info.file-removed');
          }
        });
      }
    }
  });
  _exports.default = _default;
});