define("ticust-s2h-web-application/initializers/controlers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/drafts/draft/draft", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/drafts/draft", "ember-cp-validations"], function (_exports, _draft, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/drafts/draft',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.conversation.subject': {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 256
      })]
    }
  });
  _draft.default.reopen(Validations, {
    init: function init() {
      this._super.apply(this, arguments);
      // Inject manually service
      this.set('reference', Ember.getOwner(this).dependencies.services['reference']);
    },
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    _editorInstance: undefined,
    trimSubject: function trimSubject(subject) {
      return subject.length > 256 ? subject.substring(0, 256) : subject;
    },
    getConfirmMessage: function getConfirmMessage() {
      return this.get('intl').t('interactions.message-editor.message-info.info.pop-up.replace-subject');
    },
    insertBody: function insertBody(body) {
      var editor = this.get('_editorInstance');
      editor.setEmptyEditor();
      editor.insertTemplateBody(editor.ttSelection, body);
    },
    actions: {
      selection: function selection(theme) {
        // Adding the Recipient (not usingactors)
        if (!theme.destinataire_gestion) return;
        this.set('recipient', Ember.Object.create({
          display: this.get('identity.userCompany'),
          internalEmailAddress: theme.destinataire_gestion
        }));
        this.set('themeHasSelected', true);
        if (theme.children.length === 0) {
          if (!(this.get('themeHasSelected') && !confirm(this.getConfirmMessage()))) {
            this.set('isReadOnlyForTheme', false);
            this.insertBody("<p>" + theme.precision + "</p>");
            this.set('currentSubject', this.trimSubject(theme.libelle_complet));
            this.set('recipient', Ember.Object.create({
              display: "MSH",
              internalEmailAddress: theme.destinataire_gestion
            }));
          }
        }
      },
      sendMessage: function sendMessage(message) {
        var _this = this;
        // Validate the form
        if (!this.get('validations.isValid')) {
          this.set('showErrors.subject', true);
          this.showError('interactions.conversation.draft.message-info.error.invalid-form');
          return;
        }
        this.get('reference').reference().then(function (reference) {
          // Add a reference to the subject of the conversation and the first message.
          _this.set("currentSubject", reference + " " + _this.get("currentSubject"));
          message.headers.Subject[0] = _this.get("currentSubject");

          // BEGIN -Add user information before sending
          var userInfo = _this.get('session.data.authenticated.userInfo');
          var userInfoListHtml = "";
          Object.values(userInfo).forEach(function (value) {
            userInfoListHtml += Ember.String.htmlSafe(value.libelle) + " : " + Ember.String.htmlSafe(value.data) + "<br/>";
          });
          message.bodyTextHtmlParts.firstObject.set("body", message.bodyTextHtmlParts.firstObject.body + "<br/>" + userInfoListHtml);
          var userInfoListText = "";
          Object.values(userInfo).forEach(function (value) {
            userInfoListText += value.libelle + " : " + value.data + "\n\r";
          });
          message.bodyTextPlainParts.firstObject.set("body", message.bodyTextPlainParts.firstObject.body + "\n\r" + userInfoListText);
          // END - Add user information before sending
          _this.setParticipants();
          // BEGIN - Contact simplification FROM supp. of displayName
          var _participants = _this.get('conversation.messages.lastObject.participants').map(function (p) {
            return p.type === 'FROM' ? {
              email: p.email,
              type: p.type
            } : p;
          });
          _this.set('conversation.messages.lastObject.participants', _participants);
          var _from = _participants.find(function (p) {
            return p.type === 'FROM';
          });
          message.set("headers.From.0", "<" + _from.email + ">");
          // END - Contact simplification FROM supp. of displayName
          _this.send('updateConversationInRoute', message, 'SENT');
        });
      },
      // Save Draft
      saveDraftMessage: function saveDraftMessage(draftMessage) {
        // Validate the form
        if (!this.get('validations.isValid')) {
          this.set('showErrors.subject', true);
          this.showError('interactions.conversation.draft.message-info.error.invalid-form');
          return;
        }
        this.setParticipants();
        this.send('updateConversationInRoute', draftMessage, 'DRAFT');
        this.showSuccess('interactions.conversation.success.save');
      },
      onEditorLoaded: function onEditorLoaded(editor) {
        this.set('isReadOnlyForTheme', false);
        this.set('_editorInstance', editor);
      }
    }
  });
});