define("ticust-s2h-web-application/initializers/components/ti-web-shared/editor/text-editor-wrapper", ["exports", "ti-web-shared/components/ti-web-shared/editor/text-editor-wrapper"], function (_exports, _textEditorWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/editor/text-editor-wrapper',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _textEditorWrapper.default.reopen({
    executeOnDidError: function executeOnDidError(file, jqXHR, textStatus, errorThrown) {
      var attachment = this.get('_attachments').find(function (attachment) {
        return attachment.get('file') === file;
      });
      this.get('_attachments').removeObject(attachment);
      if (jqXHR.responseJSON && jqXHR.responseJSON.metadatas && jqXHR.responseJSON.metadatas.ATTACHMENT_STATUS === 'NOT_ALLOWED_CONTENT_TYPE') {
        this._error('bad-content-type', errorThrown);
      } else {
        this._error('failed-to-upload', errorThrown);
      }
      this.get('uploadAttachmentDidError')(file, attachment);
    }
  });
});