define("ticust-s2h-web-application/translations/nl-nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "common-projects.selectors.actor-selector.error": "Er is een fout opgetreden, de zoekopdracht is niet uitgevoerd. Probeer het later opnieuw.",
    "common.application.title": "Trusted Interactions",
    "common.file-upload.error.addFile": "Er is een fout opgetreden, de bijlage is niet bijgevoegd. Probeer het later opnieuw.",
    "common.file-upload.error.contentType": "Het type bijlage is niet toegestaan.",
    "common.file-upload.error.length": "Het bestand dat u wilt uploaden is te groot. ",
    "common.message-info.error.403": "U heeft geen de rechten op deze pagina.",
    "common.message-info.error.unknown": "Er is een fout opgetreden. Probeer het later opnieuw.",
    "common.page-error-message": "Er is een fout opgetreden. Probeer het later opnieuw.",
    "common.pagination-component.currentPage": "Huidige pagina",
    "common.pagination-component.firstPage": "Eerste pagina",
    "common.pagination-component.lastPage": "Laatste pagina",
    "common.pagination-component.nextPage": "Volgende pagina",
    "common.pagination-component.page": "Pagina",
    "common.pagination-component.previousPage": "Vorige pagina",
    "common.result-list.results.error": "Er is een fout opgetreden tijden het laden van de pagina. Controleer en probeer het later opnieuw.",
    "common.result-list.results.none": "Geen resultaten",
    "common.result-list.sort.by": "Sorteren op",
    "common.sidemenu.label": "Menu",
    "common.sidemenu.minimize": "Het menu samenvouwen",
    "common.sidemenu.pulldown": "Het menu uitvouwen",
    "common.sidemenu.search": "Zoeken",
    "common.spellchecker.languages.de": "Duits",
    "common.spellchecker.languages.en": "Engels",
    "common.spellchecker.languages.es": "Spaans",
    "common.spellchecker.languages.fr": "Frans",
    "common.spellchecker.languages.it": "Italiaans",
    "common.spellchecker.languages.nl": "Nederlands",
    "common.spellchecker.languages.pt": "Portugees",
    "common.spellchecker.message-info.error.unknown": "Er is een fout opgetreden. ",
    "common.variables.notificationCommon.EVENT_DATE": "EVENT_DATE",
    "common.variables.notificationCommon.RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
    "common.variables.notificationCommon.RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
    "common.variables.notificationCommon.RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME",
    "disconnection.text.explanation": "Uw beveiligde berichtensessie is nu afgelopen, u kunt deze pagina sluiten.",
    "disconnection.text.reminder": "Om opnieuw toegang te krijgen tot uw beveiligde berichten kunt u de link in uw beveiligde ruimte voor verzekerden of uw mobiele app voor verzekerden gebruiken. ",
    "disconnection.title": "De verbinding met uw beveiligde berichtensessie wordt verbroken",
    "interactions.conversation-action-bar-component.actions.archive.display": "Archiveren",
    "interactions.conversation-action-bar-component.actions.archive.title": "Alle geselecteerde gesprekken archiveren",
    "interactions.conversation-action-bar-component.actions.categorize.display": "Indelen onder ...",
    "interactions.conversation-action-bar-component.actions.categorize.labelSelected": "Alle geselecteerde gesprekken indelen onder {label}",
    "interactions.conversation-action-bar-component.actions.categorize.title": "Alle geselecteerde gesprekken indelen onder ...",
    "interactions.conversation-action-bar-component.actions.categorize.toggle": "Het menu van 'Indelen onder ...' openen",
    "interactions.conversation-action-bar-component.actions.checkAll": "Selecteren ongedaan maken",
    "interactions.conversation-action-bar-component.actions.delete.display": "Verwijderen",
    "interactions.conversation-action-bar-component.actions.delete.title": "Alle geselecteerde gesprekken verwijderen",
    "interactions.conversation-action-bar-component.actions.markAs.display": "Markeren als ...",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display": "Markeren als gelezen",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title": "Alle geselecteerde gesprekken markeren als gelezen",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display": "Markeren als niet gelezen",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title": "Alle geselecteerde gesprekken markeren als niet gelezen",
    "interactions.conversation-action-bar-component.actions.markAs.toggle": "Het menu van 'Markeren als ...' openen",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.display": "Uit de prullenbak verwijderen",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.title": "Alle geselecteerde gesprekken uit de prullenbak verwijderen",
    "interactions.conversation-action-bar-component.actions.restore.display": "Terughalen",
    "interactions.conversation-action-bar-component.actions.restore.title": "Alle geselecteerde gesprekken terughalen",
    "interactions.conversation-action-bar-component.actions.unarchive.display": "Terugzetten",
    "interactions.conversation-action-bar-component.actions.unarchive.title": "Alle geselecteerde gesprekken terugzetten",
    "interactions.conversation-action-bar-component.actions.uncheckAll": "Alles ongedaan maken",
    "interactions.conversation-action-bar-component.search.button": "De gesprekken filteren met de ingevoerde tekst",
    "interactions.conversation-action-bar-component.search.input": "De te zoeken tekst invoeren",
    "interactions.conversation-action-bar-component.sort.sortBy": "Sorteren op:",
    "interactions.conversation-line-component.buttons.check": "Het gesprek selecteren.",
    "interactions.conversation-line-component.buttons.uncheck": "Het gesprek deselecteren.",
    "interactions.conversation-line-component.conversationSubject": "Onderwerp van het gesprek.",
    "interactions.conversation-line-component.hasAttachments": "Het gesprek heeft minstens een bericht met een bijlage.",
    "interactions.conversation-line-component.isRead.read": "Het laatste bericht van het gesprek is gelezen.",
    "interactions.conversation-line-component.isRead.unread": "Het laatste bericht van het gesprek is ongelezen.",
    "interactions.conversation-line-component.lastMessageReceivedDate": "Datum van het laatst ontvangen bericht.",
    "interactions.conversation-line-component.lastMessageReceivedcontent": "Inhoud van het laatst ontvangen bericht.",
    "interactions.conversation-line-component.lastMessageStatus.DRAFT": "Het laatste bericht van het gesprek is een voorlopige versie.",
    "interactions.conversation-line-component.lastMessageStatus.RECEIVED": "Het laatste bericht van het gesprek is afkomstig van een adviseur.",
    "interactions.conversation-line-component.lastMessageStatus.SENT": "Het laatste bericht van het gesprek komt van u.",
    "interactions.conversation-message-attachment.attachment": "Bijlage",
    "interactions.conversation-message-attachment.download": "Downloaden",
    "interactions.conversation-message-attachment.dropdown-button-title": "Acties",
    "interactions.conversation-message-attachment.preview": "Preview",
    "interactions.conversation-message.body.buttons.show-less.text": "--- het gedeeltelijke bericht zien ---",
    "interactions.conversation-message.body.buttons.show-less.title": "Het gedeeltelijke bericht weergeven",
    "interactions.conversation-message.body.buttons.show-more.text": "--- het hele bericht zien ---",
    "interactions.conversation-message.body.buttons.show-more.title": "Het hele bericht weergeven",
    "interactions.conversation-message.header.expand-button.label-expand": "De header vergroten",
    "interactions.conversation-message.header.expand-button.label-shrink": "De header verkleinen",
    "interactions.conversation-message.header.expanded.channel": "Kanaal:",
    "interactions.conversation-message.header.expanded.channels.MAIL": "Mail",
    "interactions.conversation-message.header.expanded.from": "Van:",
    "interactions.conversation-message.header.expanded.receiveDate": "Ontvangen op:",
    "interactions.conversation-message.header.expanded.sendDate": "Verzonden op:",
    "interactions.conversation-message.header.expanded.subject": "Onderwerp:",
    "interactions.conversation-message.header.expanded.tags": "Labels:",
    "interactions.conversation-message.header.expanded.to": "Aan:",
    "interactions.conversation-message.header.expanded.virus-scan": "Antivirusscan:",
    "interactions.conversation-message.header.shrunken.from": "Van:",
    "interactions.conversation-message.header.shrunken.to": "Aan:",
    "interactions.conversation-message.header.title": "Informatie over het bericht",
    "interactions.conversation.action-bar.archive": "Het gesprek archiveren",
    "interactions.conversation.action-bar.categorize": "Het gesprek indelen",
    "interactions.conversation.action-bar.delete": "Het gesprek verwijderen.",
    "interactions.conversation.action-bar.download": "Het gesprek exporteren",
    "interactions.conversation.action-bar.expiring-date": "Dit gesprek verloopt op:",
    "interactions.conversation.action-bar.mark-as-read": "Het gesprek markeren als gelezen",
    "interactions.conversation.action-bar.mark-as-unread": "Het gesprek markeren als ongelezen",
    "interactions.conversation.action-bar.print": "Het gesprek afdrukken",
    "interactions.conversation.action-bar.removeFromTrash": "Het gesprek definitief verwijderen",
    "interactions.conversation.action-bar.restore": "Het gesprek terughalen",
    "interactions.conversation.action-bar.sort-asc": "De berichten sorteren op datum, van oudste naar meest recente ",
    "interactions.conversation.action-bar.sort-desc": "De berichten sorteren op datum, van meest recente naar oudste",
    "interactions.conversation.action-bar.unarchive": "Het gesprek terughalen",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Deze bijlage verwijderen",
    "interactions.conversation.composer.attachments.buttons.download.title": "Deze bijlage downloaden",
    "interactions.conversation.composer.attachments.buttons.preview.title": "De bijlage bekijken",
    "interactions.conversation.composer.attachments.upload-in-progress": "(in uitvoering)",
    "interactions.conversation.composer.buttons.add-attachment.title": "Een bijlage toevoegen",
    "interactions.conversation.composer.buttons.cancel.title": "De wijzigingen annuleren",
    "interactions.conversation.composer.buttons.cancel.value": "Annuleren",
    "interactions.conversation.composer.buttons.save.title": "Opslaan als voorlopige versie",
    "interactions.conversation.composer.buttons.save.value": "Opslaan als voorlopige versie",
    "interactions.conversation.composer.buttons.send.title": "Het nieuwe bericht versturen",
    "interactions.conversation.composer.buttons.send.value": "Verzenden",
    "interactions.conversation.composer.notifications.attachment-uploaded": "De bijlage is opgeslagen",
    "interactions.conversation.composer.notifications.empty-file": "U kunt geen lege bijlagen uploaden. Probeer het nogmaals met een geldig bestand.",
    "interactions.conversation.composer.notifications.file-name-already-used": "Er is al een bijlage met dezelfde naam.",
    "interactions.conversation.composer.notifications.message-size-limit": "De grootte van de bijlagen overschrijdt de limiet.",
    "interactions.conversation.composer.notifications.upload-in-progress": "Een bijlage wordt opgeslagen, wacht tot de overdracht voltooid is",
    "interactions.conversation.create.message-info.error.invalid-form": "Het formulier is ongeldig. Wijzig het en probeer opnieuw.",
    "interactions.conversation.create.message-info.error.pop-up.leaving-page": "Uw wijzigingen zijn niet geregistreerd. Wilt u de pagina verlaten?",
    "interactions.conversation.create.message-info.error.save.conversation": "Er is een fout opgetreden, het gesprek is niet bijgewerkt. Probeer het later opnieuw.",
    "interactions.conversation.create.message-info.error.save.conversation-message": "Er is een fout opgetreden, het bericht is niet gemaakt. Probeer het later opnieuw.",
    "interactions.conversation.create.message-info.error.subject": "U moet een thema kiezen.",
    "interactions.conversation.create.message-info.info.save": "Het gesprek is aangemaakt.",
    "interactions.conversation.create.subject": "Onderwerp:",
    "interactions.conversation.create.title": "Nieuw gesprek",
    "interactions.conversation.draft.button.delete.label": "Verwijderen",
    "interactions.conversation.draft.button.delete.title": "De voorlopige versie van het gesprek verwijderen.",
    "interactions.conversation.draft.message-info.error.delete": "Er is een fout opgetreden, het gesprek is niet verwijderd. Probeer het later opnieuw.",
    "interactions.conversation.draft.message-info.error.invalid-form": "Het formulier is ongeldig. Wijzig het en probeer opnieuw.",
    "interactions.conversation.draft.message-info.error.save.conversation": "Er is een fout opgetreden, de voorlopige versie is niet opgeslagen. Probeer het later opnieuw.",
    "interactions.conversation.draft.message-info.error.save.conversation-message": "Er is een fout opgetreden, de voorlopige versie is niet opgeslagen. Probeer het later opnieuw.",
    "interactions.conversation.draft.message-info.info.delete": "Het gesprek is verwijderd.",
    "interactions.conversation.draft.message-info.info.pop-up.delete": "Weet u zeker dat u deze voorlopige versie wilt verwijderen?",
    "interactions.conversation.draft.message-info.info.save": "Uw aanvraag is in voorlopige versie opgeslagen.",
    "interactions.conversation.draft.title": "Voorlopige versie van gesprek bewerken",
    "interactions.conversation.errors.delete": "De voorlopige versie kan niet worden verwijderd. Probeer het later nogmaals",
    "interactions.conversation.errors.save": "Er is een fout opgetreden, het bericht is niet geregistreerd. Probeer het later opnieuw.",
    "interactions.conversation.errors.send": "Er is een fout opgetreden, het bericht is niet verstuurd. Probeer het later opnieuw.",
    "interactions.conversation.header.message-number": "{nb, plural, =0 {geen bericht} =1 {1 bericht} other {# berichts}}",
    "interactions.conversation.header.status.CLOSED": "Gearchiveerd",
    "interactions.conversation.header.status.DELETED": "Verwijderd",
    "interactions.conversation.header.status.INIT": "Voorlopig",
    "interactions.conversation.header.status.IN_PROGRESS": "In uitvoering",
    "interactions.conversation.header.status.TO_ARCHIVE": "Definitief verwijderd",
    "interactions.conversation.header.status.title": "Status",
    "interactions.conversation.header.unread": "Niet gelezen",
    "interactions.conversation.message-info.confirm.pop-up.delete": "Wilt u deze conversatie echt naar uw prullenbak verplaatsen?",
    "interactions.conversation.message-info.error.archive": "Er is een fout opgetreden tijdens het archiveren. Probeer het later nogmaals.",
    "interactions.conversation.message-info.error.categorize": "Er is een fout opgetreden tijdens het indelen. Probeer het later nogmaals.",
    "interactions.conversation.message-info.error.delete": "Er is een fout opgetreden, het gesprek is niet verwijderd. Probeer het later opnieuw.",
    "interactions.conversation.message-info.error.download": "Er is een fout opgetreden tijdens het exporteren van het gesprek. Probeer het later opnieuw.",
    "interactions.conversation.message-info.error.markAsRead": "Er is een fout opgetreden tijdens het markeren als gelezen. Probeer het later nogmaals.",
    "interactions.conversation.message-info.error.markAsUnread": "Er is een fout opgetreden tijdens het markeren als ongelezen. Probeer het later nogmaals.",
    "interactions.conversation.message-info.error.pop-up.archive": "Wilt u dit gesprek archiveren?",
    "interactions.conversation.message-info.error.pop-up.delete": "Weet u zeker dat u dit gesprek wilt verwijderen? Deze actie is onomkeerbaar.",
    "interactions.conversation.message-info.error.pop-up.leaving-page": "Uw wijzigingen zijn niet geregistreerd. Wilt u de pagina verlaten?",
    "interactions.conversation.message-info.error.print": "Er is een fout opgetreden tijdens het afdrukken van het gesprek. Probeer het later opnieuw.",
    "interactions.conversation.message-info.error.removeFromTrash": "Er is een fout opgetreden tijdens de definitieve verwijdering. Probeer het later nogmaals.",
    "interactions.conversation.message-info.error.restore": "Er is een fout opgetreden tijdens het terughalen. Probeer het later nogmaals.",
    "interactions.conversation.message-info.error.unarchive": "Er is een fout opgetreden tijdens het terugzetten. Probeer het later nogmaals.",
    "interactions.conversation.message-info.info.archive": "Het gesprek is gearchiveerd.",
    "interactions.conversation.message-info.info.categorize": "Het gesprek is ingedeeld.",
    "interactions.conversation.message-info.info.delete": "Het gesprek is verwijderd.",
    "interactions.conversation.message-info.info.markAsRead": "Het gesprek is gemarkeerd als gelezen.",
    "interactions.conversation.message-info.info.markAsUnread": "Het gesprek is gemarkeerd als ongelezen.",
    "interactions.conversation.message-info.info.pop-up-removeFromTrash": "Weet u zeker dat u dit gesprek definitief wilt verwijderen?",
    "interactions.conversation.message-info.info.readOnly": "Dit bericht is alleen-lezen.",
    "interactions.conversation.message-info.info.removeFromTrash": "Het gesprek is definitief verwijderd.",
    "interactions.conversation.message-info.info.restore": "Het gesprek is teruggehaald.",
    "interactions.conversation.message-info.info.unarchive": "Het gesprek is teruggezet.",
    "interactions.conversation.showMore.button": "Oudere berichten zien",
    "interactions.conversation.showMore.message-info.error.showMoreMessages": "Er is een fout opgetreden, de berichten zijn niet gedownload. Probeer het later nogmaals.",
    "interactions.conversation.success.delete": "De voorlopige versie is verwijderd",
    "interactions.conversation.success.save": "De voorlopige versie is opgeslagen",
    "interactions.conversation.success.send": "Het bericht is verstuurd.",
    "interactions.conversations.search.list.title": "Gesprekken",
    "interactions.conversations.search.message-info.error.archive": "Er is een fout opgetreden tijdens het archiveren. Probeer het later nogmaals.",
    "interactions.conversations.search.message-info.error.categorize": "Er is een fout opgetreden tijdens het indelen. Probeer het later nogmaals.",
    "interactions.conversations.search.message-info.error.delete": "Er is een fout opgetreden, het gesprek is niet verwijderd. Probeer het later opnieuw.",
    "interactions.conversations.search.message-info.error.markAsRead": "Er is een fout opgetreden tijdens het markeren als gelezen. Probeer het later nogmaals.",
    "interactions.conversations.search.message-info.error.markAsUnread": "Er is een fout opgetreden tijdens het markeren als ongelezen. Probeer het later nogmaals.",
    "interactions.conversations.search.message-info.error.removeFromTrash": "Er is een fout opgetreden, het gesprek is niet verwijderd. Probeer het later opnieuw.",
    "interactions.conversations.search.message-info.error.restore": "Er is een fout opgetreden tijdens het terugzetten. Probeer het later nogmaals.",
    "interactions.conversations.search.message-info.error.unarchive": "Er is een fout opgetreden tijdens het terugzetten. Probeer het later nogmaals.",
    "interactions.conversations.search.message-info.info.archive": "Het is gearchiveerd.",
    "interactions.conversations.search.message-info.info.categorize": "Het is ingedeeld.",
    "interactions.conversations.search.message-info.info.delete": "Het is verwijderd.",
    "interactions.conversations.search.message-info.info.markAsRead": "Het is gemarkeerd als gelezen.",
    "interactions.conversations.search.message-info.info.markAsUnread": "Het is gemarkeerd als ongelezen.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "Weet u zeker dat u het wilt verwijderen?",
    "interactions.conversations.search.message-info.info.removeFromTrash": "Het is definitief verwijderd.",
    "interactions.conversations.search.message-info.info.restore": "Het is teruggezet.",
    "interactions.conversations.search.message-info.info.unarchive": "Het is teruggezet.",
    "interactions.conversations.sort.lastMessageReceivedDate.asc": "Het oudste",
    "interactions.conversations.sort.lastMessageReceivedDate.desc": "Het meest recente",
    "interactions.forbidden-interlocutor": "Deze gebruiker heeft geen toegang",
    "interactions.label.categorize-component.buttons.close": "Sluiten",
    "interactions.label.categorize-component.buttons.manage": "Mijn persoonlijke dossiers beheren",
    "interactions.label.categorize-component.buttons.save": "Opslaan",
    "interactions.label.categorize-component.header.text": "Mijn persoonlijke dossiers",
    "interactions.label.categorize-component.header.title": "Mijn persoonlijke dossiers",
    "interactions.label.categorize-component.loading": "Bezig met laden",
    "interactions.label.categorize-component.no-data": "Er zijn geen persoonlijke dossiers om weer te geven",
    "interactions.label.popup-edit-component.buttons.close": "Annuleren",
    "interactions.label.popup-edit-component.buttons.save": "Opslaan",
    "interactions.label.popup-edit-component.fields.color.label-color-1": "Kleur 1",
    "interactions.label.popup-edit-component.fields.color.label-color-2": "Kleur 2",
    "interactions.label.popup-edit-component.fields.color.label-color-3": "Kleur 3",
    "interactions.label.popup-edit-component.fields.color.label-color-4": "Kleur 4 ",
    "interactions.label.popup-edit-component.fields.color.label-color-5": "Kleur 5",
    "interactions.label.popup-edit-component.fields.color.text": "Kleur:",
    "interactions.label.popup-edit-component.fields.color.title": "Kleur",
    "interactions.label.popup-edit-component.fields.description.text": "Beschrijving:",
    "interactions.label.popup-edit-component.fields.description.title": "Beschrijving",
    "interactions.label.popup-edit-component.fields.name.text": "Naam:",
    "interactions.label.popup-edit-component.fields.name.title": "Naam",
    "interactions.label.popup-edit-component.header.edit.text": "Persoonlijk dossier: [{name}]",
    "interactions.label.popup-edit-component.header.edit.title": "Persoonlijk dossier",
    "interactions.label.popup-edit-component.header.new.text": "Nieuw persoonlijk dossier",
    "interactions.label.popup-edit-component.header.new.title": "Nieuw persoonlijk dossier",
    "interactions.label.popup-edit-component.message-info.error.duplication": "Deze naam wordt al gebruikt voor een ander persoonlijk dossier.",
    "interactions.label.popup-edit-component.message-info.error.invalid-form": "Het formulier is ongeldig. Wijzig het en probeer opnieuw.",
    "interactions.label.settings.buttons.add.label": "Een",
    "interactions.label.settings.buttons.add.title": "Een",
    "interactions.label.settings.buttons.delete.label": "Verwijderen",
    "interactions.label.settings.buttons.delete.title": "Verwijderen",
    "interactions.label.settings.buttons.edit.label": "Bewerken",
    "interactions.label.settings.buttons.edit.title": "Bewerken",
    "interactions.label.settings.fields.defaultText.text": "Aa",
    "interactions.label.settings.fields.defaultText.title": "Voorbeeld van label",
    "interactions.label.settings.fields.description.title": "Beschrijving",
    "interactions.label.settings.fields.name.title": "Naam van het persoonlijk dossier",
    "interactions.label.settings.message-info.error.create": "Er is een fout opgetreden, het persoonlijk dossier is niet toegevoegd. Probeer het later nogmaals.",
    "interactions.label.settings.message-info.error.delete": "Er is een fout opgetreden, het persoonlijk dossier is niet verwijderd. Probeer het later nogmaals.",
    "interactions.label.settings.message-info.error.edit": "Er is een fout opgetreden, het persoonlijk dossier is niet gewijzigd. Probeer het later nogmaals.",
    "interactions.label.settings.message-info.error.get-404": "Er is een fout opgetreden, het persoonlijk dossier is niet gevonden. Probeer het later nogmaals.",
    "interactions.label.settings.message-info.error.unknow": "Er is een fout opgetreden tijden het laden van de pagina. Controleer en probeer het later opnieuw.",
    "interactions.label.settings.message-info.info.create": "Het persoonlijk dossier is aangemaakt.",
    "interactions.label.settings.message-info.info.delete": "Het persoonlijk dossier is verwijderd.",
    "interactions.label.settings.message-info.info.deleteConfirm": "Wilt u het persoonlijk dossier [{name}] verwijderen?",
    "interactions.label.settings.message-info.info.edit": "Het persoonlijk dossier is bijgewerkt.",
    "interactions.label.settings.search.results.title": "Persoonlijke dossiers.",
    "interactions.message-attachment.message-info.error.download": "Het downloaden is mislukt. Probeer het later nogmaals.",
    "interactions.message-composer-attachments.multiple-upload.error": "{nbUploadsFailed, plural, one {# bijlage is niet} other {# bijlagen zijn niet}}  {nbUploadsFailed, plural, one {geupload} other {geupload}}.",
    "interactions.message-composer-attachments.multiple-upload.success": "{nbUploadsPassed, plural, one {De bijlage is geupload} other {# bijlagen zijn geupload}}.",
    "interactions.message-editor.message-info.info.pop-up.replace-subject": "Bevestigt u dat u het bericht dat momenteel wordt bewerkt wilt verwijderen en vervangen door het nieuwe geselecteerde thema?",
    "interactions.preview-component.buttons.close": "Terug",
    "interactions.preview-component.error": "Er is een fout opgetreden tijdens het laden van de afbeelding. Probeer het later opnieuw.",
    "interactions.preview-component.loading": "Bezig met laden",
    "interactions.preview-component.title.uploadDate": "Ingediend op",
    "interactions.preview-component.title.uploader": "Ingediend door",
    "interactions.side-menu.conversations.label": "Gesprekken",
    "interactions.side-menu.conversations.lines.archived": "Gearchiveerd",
    "interactions.side-menu.conversations.lines.draft": "Voorlopig",
    "interactions.side-menu.conversations.lines.mine": "Mijn gesprekken",
    "interactions.side-menu.conversations.lines.sent": "Verstuurd",
    "interactions.side-menu.conversations.lines.trashed": "Prullenbak",
    "interactions.side-menu.conversations.sr-only": "gesprekken",
    "interactions.side-menu.labels.configurationButton": "Configuratie",
    "interactions.side-menu.labels.label": "Persoonlijke dossiers",
    "interactions.side-menu.new-conversation": "Nieuw gesprek",
    "profile.infos.header": "Persoonlijke voorkeuren",
    "profile.infos.howto.messaging": "Om terug te keren naar uw beveiligde mailbox: klik in het zijmenu op het vervolgkeuzemenu 'Profiel' en selecteer 'Mailbox'",
    "profile.infos.howto.notifications": "Om de ontvangst van externe meldingen te wijzigen: klik in het zijmenu op Meldingen.",
    "profile.infos.howto.warnings": "Om de werking van waarschuwingsmeldingen te wijzigen: klik in het zijmenu op Voorkeuren.",
    "profile.infos.introduction": "U bevindt zich in de module voor het beheer van uw persoonsgegevens.",
    "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nieuw bericht ontvangen",
    "profile.preferences.closeErrorMessage.label": "Sluiten van foutberichten:",
    "profile.preferences.closeErrorMessage.title": "Sluiten van foutberichten ",
    "profile.preferences.closeErrorMessages.false": "Automatisch",
    "profile.preferences.closeErrorMessages.true": "Handmatig",
    "profile.preferences.edit.cancel": "Annuleren",
    "profile.preferences.edit.save": "Opslaan",
    "profile.preferences.header": "Voorkeuren",
    "profile.preferences.message-info.info.save": "De voorkeuren zijn bijgewerkt.",
    "profile.preferences.view.edit": "Wijzigen",
    "profile.side-bar.sign-out": "Uitloggen",
    "profile.side-bar.title": "Accountinstellingen",
    "profile.side-menu.preferences": "Voorkeuren",
    "profile.side-menu.subscriptions": "Meldingen",
    "profile.subscriptions.edit.cancel": "Annuleren",
    "profile.subscriptions.edit.save": "Opslaan",
    "profile.subscriptions.message-info.error.loading": "Er is een fout opgetreden tijdens het laden van de abonnementen op meldingen. Probeer het later nogmaals.",
    "profile.subscriptions.message-info.error.save": "Er is een fout opgetreden tijdens het opslaan van de abonnementen op meldingen. Probeer het later nogmaals.",
    "profile.subscriptions.message-info.info.save": "Uw abonnementen op meldingen zijn bijgewerkt.",
    "profile.subscriptions.view.edit": "Wijzigen",
    "profile.subscriptions.view.state.disabled": "Inactief",
    "profile.subscriptions.view.state.enabled": "Actief",
    "shared.components.a11y.modals.alert-modal.closeButtonText": "Sluiten",
    "shared.components.a11y.modals.alert-modal.closeButtonTitle": "Het venster sluiten",
    "shared.components.dropdown.default-button-title": "Acties",
    "shared.components.form-component.form-fields-required-label": "Velden met een * zijn verplicht.",
    "shared.components.form-group-component.missing-text": "Dit veld is verplicht.",
    "shared.components.modals.document-preview.error-message": "Er is een fout opgetreden tijdens het laden van de afbeelding. Probeer het later nogmaals.",
    "shared.components.modals.document-preview.modal-title": "Preview bekijken van het bestand",
    "shared.components.modals.document-preview.select-page": "Een pagina selecteren",
    "shared.components.modals.document-preview.upload-by": "Gedownload door {actor} op {date}",
    "shared.components.modals.document-preview.zoom-in": "Inzoomen",
    "shared.components.modals.document-preview.zoom-out": "Uitzoomen",
    "shared.components.modals.image-preview.error-message": "Er is een fout opgetreden tijdens het laden van de afbeelding. Probeer het later nogmaals.",
    "shared.components.modals.image-preview.loading-message": "Bezig met laden",
    "shared.components.modals.image-preview.modal-title": "Preview",
    "shared.components.modals.image-preview.upload-by": "Ingediend door",
    "shared.components.modals.image-preview.upload-date": "Ingediend op",
    "shared.components.modals.image-preview.zoom-in": "Inzoomen",
    "shared.components.modals.image-preview.zoom-out": "Uitzoomen",
    "shared.components.modals.modal-component.modal-cancel": "Annuleren",
    "shared.components.modals.modal-component.modal-confirm": "Bevestigen",
    "shared.components.modals.title.closeButton": "Dit bericht sluiten",
    "shared.components.result-list.results.error": "Er is een fout opgetreden tijden het laden van de pagina. Controleer en probeer het later opnieuw.",
    "shared.components.result-list.results.loading": "Bezig met laden",
    "shared.components.result-list.results.none": "Geen resultaten",
    "shared.components.result-list.results.wait": "Even geduld",
    "shared.components.text-editor.actions-list.f-aligncenter": "Centreren",
    "shared.components.text-editor.actions-list.f-alignleft": "Links uitlijnen",
    "shared.components.text-editor.actions-list.f-alignright": "Rechts uitlijnen",
    "shared.components.text-editor.actions-list.f-bold": "Vet",
    "shared.components.text-editor.actions-list.f-color.help": "Kleur",
    "shared.components.text-editor.actions-list.f-color.label": "De kleur van de tekst veranderen",
    "shared.components.text-editor.actions-list.f-font.help": "Lettertype",
    "shared.components.text-editor.actions-list.f-font.label": "Kies de lettertypefamilie",
    "shared.components.text-editor.actions-list.f-gallery": "Een afbeelding toevoegen",
    "shared.components.text-editor.actions-list.f-italic": "Cursief",
    "shared.components.text-editor.actions-list.f-link.help": "Een link toevoegen",
    "shared.components.text-editor.actions-list.f-link.ok": "OK",
    "shared.components.text-editor.actions-list.f-link.url": "URL",
    "shared.components.text-editor.actions-list.f-list": "Van/naar-lijst",
    "shared.components.text-editor.actions-list.f-size.help": "Grootte van het lettertype",
    "shared.components.text-editor.actions-list.f-size.label": "Kies de grootte van het lettertype",
    "shared.components.text-editor.actions-list.f-spellCheck.check-spelling": "De spelling controleren",
    "shared.components.text-editor.actions-list.f-spellCheck.choose-language": "Kies een taal",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore": "Negeren",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore-all": "Alles negeren",
    "shared.components.text-editor.actions-list.f-underline": "Onderstreept",
    "shared.components.text-editor.actions-list.f-upload.help": "Bijlage toevoegen",
    "shared.components.text-editor.message-composer-attachments.delete": "Deze bijlage verwijderen",
    "shared.components.text-editor.message-composer-attachments.download": "Downloaden bijlage verwijderen",
    "shared.components.text-editor.message-composer-attachments.dropdown-button-title": "Acties",
    "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type": "Het type bijlage is niet toegestaan.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.download": "Verbindingsprobleem, upload onderbroken. Probeer het later opnieuw.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file": "Verbindingsprobleem, upload onderbroken. Probeer het later opnieuw.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload": "Verbindingsprobleem, upload onderbroken.Probeer het later opnieuw.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used": "U kunt geen lege bijlagen uploaden. Probeer het nogmaals met een geldig bestand.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached": "U heeft het maximaal aantal bijlagen bereikt.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit": "De grootte van de bijlagen overschrijdt de limiet.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.preview": "Het laden van de afbeelding is mislukt. Probeer het later opnieuw.",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded": "De bijlage is opgeslagen",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading": "Bezig met opslaan...",
    "shared.components.text-editor.message-composer-attachments.preview": "De bijlage bekijken",
    "shared.file-uploader.error.EMPTY_ATTACHMENT": "U kunt geen lege bijlagen uploaden. Probeer het nogmaals met een geldig bestand.",
    "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE": "Het type bijlage is niet toegestaan.",
    "shared.file-uploader.error.addFile": "Er is een fout opgetreden, de bijlage is niet bijgevoegd. Probeer het later opnieuw.",
    "shared.file-uploader.error.contentType": "Het type bijlage is niet toegestaan.",
    "shared.file-uploader.error.length": "Het bestand dat u wilt uploaden is te groot. ",
    "shared.helpers.date-short-view.today": "Vandaag, {hour}",
    "shared.numeric-unit.billion": "G",
    "shared.numeric-unit.million": "M",
    "shared.numeric-unit.thousand": "K",
    "shared.pagination": "paginering",
    "shared.skip-link": "Naar de hoofdpagina gaan (het menu verlaten)",
    "side-panel.burger.label": "Het menu weergeven / verbergen",
    "side-panel.hide-menu": "Het zijmenu verbergen",
    "side-panel.show-menu": "Het zijmenu weergeven",
    "side-panel.toggle-title": "Open/sluit het menu ",
    "sso-error.text": "Er is een fout opgetreden tijdens de verbinding. Sluit dit venster en probeer het opnieuw vanuit uw persoonlijke ruimte.",
    "sso-error.title": "Fout bij verbinding",
    "topbar.message-info.log-out-confirmation": "Weet u zeker dat u wilt uitloggen?",
    "topbar.module.interactions": "Berichten",
    "topbar.module.profile": "Profiel",
    "topbar.nav.label": "Balk met meerdere modules",
    "topbar.organisation.name": "Beveiligde mailbox Verzekerde"
  };
  _exports.default = _default;
});