define("ticust-s2h-web-application/translations/key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "common-projects.selectors.actor-selector.error": "common-projects.selectors.actor-selector.error",
    "common.application.title": "common.application.title",
    "common.file-upload.error.addFile": "common.file-upload.error.addFile",
    "common.file-upload.error.contentType": "common.file-upload.error.contentType",
    "common.file-upload.error.length": "common.file-upload.error.length",
    "common.message-info.error.403": "common.message-info.error.403",
    "common.message-info.error.unknown": "common.message-info.error.unknown",
    "common.page-error-message": "common.page-error-message",
    "common.pagination-component.currentPage": "common.pagination-component.currentPage",
    "common.pagination-component.firstPage": "common.pagination-component.firstPage",
    "common.pagination-component.lastPage": "common.pagination-component.lastPage",
    "common.pagination-component.nextPage": "common.pagination-component.nextPage",
    "common.pagination-component.page": "common.pagination-component.page",
    "common.pagination-component.previousPage": "common.pagination-component.previousPage",
    "common.result-list.results.error": "common.result-list.results.error",
    "common.result-list.results.none": "common.result-list.results.none",
    "common.result-list.sort.by": "common.result-list.sort.by",
    "common.sidemenu.label": "common.sidemenu.label",
    "common.sidemenu.minimize": "common.sidemenu.minimize",
    "common.sidemenu.pulldown": "common.sidemenu.pulldown",
    "common.sidemenu.search": "common.sidemenu.search",
    "common.spellchecker.languages.de": "common.spellchecker.languages.de",
    "common.spellchecker.languages.en": "common.spellchecker.languages.en",
    "common.spellchecker.languages.es": "common.spellchecker.languages.es",
    "common.spellchecker.languages.fr": "common.spellchecker.languages.fr",
    "common.spellchecker.languages.it": "common.spellchecker.languages.it",
    "common.spellchecker.languages.nl": "common.spellchecker.languages.nl",
    "common.spellchecker.languages.pt": "common.spellchecker.languages.pt",
    "common.spellchecker.message-info.error.unknown": "common.spellchecker.message-info.error.unknown",
    "common.variables.notificationCommon.EVENT_DATE": "common.variables.notificationCommon.EVENT_DATE",
    "common.variables.notificationCommon.RECIPIENT_CIVILITY": "common.variables.notificationCommon.RECIPIENT_CIVILITY",
    "common.variables.notificationCommon.RECIPIENT_FIRSTNAME": "common.variables.notificationCommon.RECIPIENT_FIRSTNAME",
    "common.variables.notificationCommon.RECIPIENT_LASTNAME": "common.variables.notificationCommon.RECIPIENT_LASTNAME",
    "disconnection.text.explanation": "disconnection.text.explanation",
    "disconnection.text.reminder": "disconnection.text.reminder",
    "disconnection.title": "disconnection.title",
    "interactions.conversation-action-bar-component.actions.archive.display": "interactions.conversation-action-bar-component.actions.archive.display",
    "interactions.conversation-action-bar-component.actions.archive.title": "interactions.conversation-action-bar-component.actions.archive.title",
    "interactions.conversation-action-bar-component.actions.categorize.display": "interactions.conversation-action-bar-component.actions.categorize.display",
    "interactions.conversation-action-bar-component.actions.categorize.labelSelected": "interactions.conversation-action-bar-component.actions.categorize.labelSelected",
    "interactions.conversation-action-bar-component.actions.categorize.title": "interactions.conversation-action-bar-component.actions.categorize.title",
    "interactions.conversation-action-bar-component.actions.categorize.toggle": "interactions.conversation-action-bar-component.actions.categorize.toggle",
    "interactions.conversation-action-bar-component.actions.checkAll": "interactions.conversation-action-bar-component.actions.checkAll",
    "interactions.conversation-action-bar-component.actions.delete.display": "interactions.conversation-action-bar-component.actions.delete.display",
    "interactions.conversation-action-bar-component.actions.delete.title": "interactions.conversation-action-bar-component.actions.delete.title",
    "interactions.conversation-action-bar-component.actions.markAs.display": "interactions.conversation-action-bar-component.actions.markAs.display",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display": "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title": "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display": "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title": "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title",
    "interactions.conversation-action-bar-component.actions.markAs.toggle": "interactions.conversation-action-bar-component.actions.markAs.toggle",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.display": "interactions.conversation-action-bar-component.actions.removeFromTrash.display",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.title": "interactions.conversation-action-bar-component.actions.removeFromTrash.title",
    "interactions.conversation-action-bar-component.actions.restore.display": "interactions.conversation-action-bar-component.actions.restore.display",
    "interactions.conversation-action-bar-component.actions.restore.title": "interactions.conversation-action-bar-component.actions.restore.title",
    "interactions.conversation-action-bar-component.actions.unarchive.display": "interactions.conversation-action-bar-component.actions.unarchive.display",
    "interactions.conversation-action-bar-component.actions.unarchive.title": "interactions.conversation-action-bar-component.actions.unarchive.title",
    "interactions.conversation-action-bar-component.actions.uncheckAll": "interactions.conversation-action-bar-component.actions.uncheckAll",
    "interactions.conversation-action-bar-component.search.button": "interactions.conversation-action-bar-component.search.button",
    "interactions.conversation-action-bar-component.search.input": "interactions.conversation-action-bar-component.search.input",
    "interactions.conversation-action-bar-component.sort.sortBy": "interactions.conversation-action-bar-component.sort.sortBy",
    "interactions.conversation-line-component.buttons.check": "interactions.conversation-line-component.buttons.check",
    "interactions.conversation-line-component.buttons.uncheck": "interactions.conversation-line-component.buttons.uncheck",
    "interactions.conversation-line-component.conversationSubject": "interactions.conversation-line-component.conversationSubject",
    "interactions.conversation-line-component.hasAttachments": "interactions.conversation-line-component.hasAttachments",
    "interactions.conversation-line-component.isRead.read": "interactions.conversation-line-component.isRead.read",
    "interactions.conversation-line-component.isRead.unread": "interactions.conversation-line-component.isRead.unread",
    "interactions.conversation-line-component.lastMessageReceivedDate": "interactions.conversation-line-component.lastMessageReceivedDate",
    "interactions.conversation-line-component.lastMessageReceivedcontent": "interactions.conversation-line-component.lastMessageReceivedcontent",
    "interactions.conversation-line-component.lastMessageStatus.DRAFT": "interactions.conversation-line-component.lastMessageStatus.DRAFT",
    "interactions.conversation-line-component.lastMessageStatus.RECEIVED": "interactions.conversation-line-component.lastMessageStatus.RECEIVED",
    "interactions.conversation-line-component.lastMessageStatus.SENT": "interactions.conversation-line-component.lastMessageStatus.SENT",
    "interactions.conversation-message-attachment.attachment": "interactions.conversation-message-attachment.attachment",
    "interactions.conversation-message-attachment.download": "interactions.conversation-message-attachment.download",
    "interactions.conversation-message-attachment.dropdown-button-title": "interactions.conversation-message-attachment.dropdown-button-title",
    "interactions.conversation-message-attachment.preview": "interactions.conversation-message-attachment.preview",
    "interactions.conversation-message.body.buttons.show-less.text": "interactions.conversation-message.body.buttons.show-less.text",
    "interactions.conversation-message.body.buttons.show-less.title": "interactions.conversation-message.body.buttons.show-less.title",
    "interactions.conversation-message.body.buttons.show-more.text": "interactions.conversation-message.body.buttons.show-more.text",
    "interactions.conversation-message.body.buttons.show-more.title": "interactions.conversation-message.body.buttons.show-more.title",
    "interactions.conversation-message.header.expand-button.label-expand": "interactions.conversation-message.header.expand-button.label-expand",
    "interactions.conversation-message.header.expand-button.label-shrink": "interactions.conversation-message.header.expand-button.label-shrink",
    "interactions.conversation-message.header.expanded.channel": "interactions.conversation-message.header.expanded.channel",
    "interactions.conversation-message.header.expanded.channels.MAIL": "interactions.conversation-message.header.expanded.channels.MAIL",
    "interactions.conversation-message.header.expanded.from": "interactions.conversation-message.header.expanded.from",
    "interactions.conversation-message.header.expanded.receiveDate": "interactions.conversation-message.header.expanded.receiveDate",
    "interactions.conversation-message.header.expanded.sendDate": "interactions.conversation-message.header.expanded.sendDate",
    "interactions.conversation-message.header.expanded.subject": "interactions.conversation-message.header.expanded.subject",
    "interactions.conversation-message.header.expanded.tags": "interactions.conversation-message.header.expanded.tags",
    "interactions.conversation-message.header.expanded.to": "interactions.conversation-message.header.expanded.to",
    "interactions.conversation-message.header.expanded.virus-scan": "interactions.conversation-message.header.expanded.virus-scan",
    "interactions.conversation-message.header.shrunken.from": "interactions.conversation-message.header.shrunken.from",
    "interactions.conversation-message.header.shrunken.to": "interactions.conversation-message.header.shrunken.to",
    "interactions.conversation-message.header.title": "interactions.conversation-message.header.title",
    "interactions.conversation.action-bar.archive": "interactions.conversation.action-bar.archive",
    "interactions.conversation.action-bar.categorize": "interactions.conversation.action-bar.categorize",
    "interactions.conversation.action-bar.delete": "interactions.conversation.action-bar.delete",
    "interactions.conversation.action-bar.download": "interactions.conversation.action-bar.download",
    "interactions.conversation.action-bar.expiring-date": "interactions.conversation.action-bar.expiring-date",
    "interactions.conversation.action-bar.mark-as-read": "interactions.conversation.action-bar.mark-as-read",
    "interactions.conversation.action-bar.mark-as-unread": "interactions.conversation.action-bar.mark-as-unread",
    "interactions.conversation.action-bar.print": "interactions.conversation.action-bar.print",
    "interactions.conversation.action-bar.removeFromTrash": "interactions.conversation.action-bar.removeFromTrash",
    "interactions.conversation.action-bar.restore": "interactions.conversation.action-bar.restore",
    "interactions.conversation.action-bar.sort-asc": "interactions.conversation.action-bar.sort-asc",
    "interactions.conversation.action-bar.sort-desc": "interactions.conversation.action-bar.sort-desc",
    "interactions.conversation.action-bar.unarchive": "interactions.conversation.action-bar.unarchive",
    "interactions.conversation.composer.attachments.buttons.delete.title": "interactions.conversation.composer.attachments.buttons.delete.title",
    "interactions.conversation.composer.attachments.buttons.download.title": "interactions.conversation.composer.attachments.buttons.download.title",
    "interactions.conversation.composer.attachments.buttons.preview.title": "interactions.conversation.composer.attachments.buttons.preview.title",
    "interactions.conversation.composer.attachments.upload-in-progress": "interactions.conversation.composer.attachments.upload-in-progress",
    "interactions.conversation.composer.buttons.add-attachment.title": "interactions.conversation.composer.buttons.add-attachment.title",
    "interactions.conversation.composer.buttons.cancel.title": "interactions.conversation.composer.buttons.cancel.title",
    "interactions.conversation.composer.buttons.cancel.value": "interactions.conversation.composer.buttons.cancel.value",
    "interactions.conversation.composer.buttons.save.title": "interactions.conversation.composer.buttons.save.title",
    "interactions.conversation.composer.buttons.save.value": "interactions.conversation.composer.buttons.save.value",
    "interactions.conversation.composer.buttons.send.title": "interactions.conversation.composer.buttons.send.title",
    "interactions.conversation.composer.buttons.send.value": "interactions.conversation.composer.buttons.send.value",
    "interactions.conversation.composer.notifications.attachment-uploaded": "interactions.conversation.composer.notifications.attachment-uploaded",
    "interactions.conversation.composer.notifications.empty-file": "interactions.conversation.composer.notifications.empty-file",
    "interactions.conversation.composer.notifications.file-name-already-used": "interactions.conversation.composer.notifications.file-name-already-used",
    "interactions.conversation.composer.notifications.message-size-limit": "interactions.conversation.composer.notifications.message-size-limit",
    "interactions.conversation.composer.notifications.upload-in-progress": "interactions.conversation.composer.notifications.upload-in-progress",
    "interactions.conversation.create.message-info.error.invalid-form": "interactions.conversation.create.message-info.error.invalid-form",
    "interactions.conversation.create.message-info.error.pop-up.leaving-page": "interactions.conversation.create.message-info.error.pop-up.leaving-page",
    "interactions.conversation.create.message-info.error.save.conversation": "interactions.conversation.create.message-info.error.save.conversation",
    "interactions.conversation.create.message-info.error.save.conversation-message": "interactions.conversation.create.message-info.error.save.conversation-message",
    "interactions.conversation.create.message-info.error.subject": "interactions.conversation.create.message-info.error.subject",
    "interactions.conversation.create.message-info.info.save": "interactions.conversation.create.message-info.info.save",
    "interactions.conversation.create.subject": "interactions.conversation.create.subject",
    "interactions.conversation.create.title": "interactions.conversation.create.title",
    "interactions.conversation.draft.button.delete.label": "interactions.conversation.draft.button.delete.label",
    "interactions.conversation.draft.button.delete.title": "interactions.conversation.draft.button.delete.title",
    "interactions.conversation.draft.message-info.error.delete": "interactions.conversation.draft.message-info.error.delete",
    "interactions.conversation.draft.message-info.error.invalid-form": "interactions.conversation.draft.message-info.error.invalid-form",
    "interactions.conversation.draft.message-info.error.save.conversation": "interactions.conversation.draft.message-info.error.save.conversation",
    "interactions.conversation.draft.message-info.error.save.conversation-message": "interactions.conversation.draft.message-info.error.save.conversation-message",
    "interactions.conversation.draft.message-info.info.delete": "interactions.conversation.draft.message-info.info.delete",
    "interactions.conversation.draft.message-info.info.pop-up.delete": "interactions.conversation.draft.message-info.info.pop-up.delete",
    "interactions.conversation.draft.message-info.info.save": "interactions.conversation.draft.message-info.info.save",
    "interactions.conversation.draft.title": "interactions.conversation.draft.title",
    "interactions.conversation.errors.delete": "interactions.conversation.errors.delete",
    "interactions.conversation.errors.save": "interactions.conversation.errors.save",
    "interactions.conversation.errors.send": "interactions.conversation.errors.send",
    "interactions.conversation.header.message-number": "interactions.conversation.header.message-number",
    "interactions.conversation.header.status.CLOSED": "interactions.conversation.header.status.CLOSED",
    "interactions.conversation.header.status.DELETED": "interactions.conversation.header.status.DELETED",
    "interactions.conversation.header.status.INIT": "interactions.conversation.header.status.INIT",
    "interactions.conversation.header.status.IN_PROGRESS": "interactions.conversation.header.status.IN_PROGRESS",
    "interactions.conversation.header.status.TO_ARCHIVE": "interactions.conversation.header.status.TO_ARCHIVE",
    "interactions.conversation.header.status.title": "interactions.conversation.header.status.title",
    "interactions.conversation.header.unread": "interactions.conversation.header.unread",
    "interactions.conversation.message-info.confirm.pop-up.delete": "interactions.conversation.message-info.confirm.pop-up.delete",
    "interactions.conversation.message-info.error.archive": "interactions.conversation.message-info.error.archive",
    "interactions.conversation.message-info.error.categorize": "interactions.conversation.message-info.error.categorize",
    "interactions.conversation.message-info.error.delete": "interactions.conversation.message-info.error.delete",
    "interactions.conversation.message-info.error.download": "interactions.conversation.message-info.error.download",
    "interactions.conversation.message-info.error.markAsRead": "interactions.conversation.message-info.error.markAsRead",
    "interactions.conversation.message-info.error.markAsUnread": "interactions.conversation.message-info.error.markAsUnread",
    "interactions.conversation.message-info.error.pop-up.archive": "interactions.conversation.message-info.error.pop-up.archive",
    "interactions.conversation.message-info.error.pop-up.delete": "interactions.conversation.message-info.error.pop-up.delete",
    "interactions.conversation.message-info.error.pop-up.leaving-page": "interactions.conversation.message-info.error.pop-up.leaving-page",
    "interactions.conversation.message-info.error.print": "interactions.conversation.message-info.error.print",
    "interactions.conversation.message-info.error.removeFromTrash": "interactions.conversation.message-info.error.removeFromTrash",
    "interactions.conversation.message-info.error.restore": "interactions.conversation.message-info.error.restore",
    "interactions.conversation.message-info.error.unarchive": "interactions.conversation.message-info.error.unarchive",
    "interactions.conversation.message-info.info.archive": "interactions.conversation.message-info.info.archive",
    "interactions.conversation.message-info.info.categorize": "interactions.conversation.message-info.info.categorize",
    "interactions.conversation.message-info.info.delete": "interactions.conversation.message-info.info.delete",
    "interactions.conversation.message-info.info.markAsRead": "interactions.conversation.message-info.info.markAsRead",
    "interactions.conversation.message-info.info.markAsUnread": "interactions.conversation.message-info.info.markAsUnread",
    "interactions.conversation.message-info.info.pop-up-removeFromTrash": "interactions.conversation.message-info.info.pop-up-removeFromTrash",
    "interactions.conversation.message-info.info.readOnly": "interactions.conversation.message-info.info.readOnly",
    "interactions.conversation.message-info.info.removeFromTrash": "interactions.conversation.message-info.info.removeFromTrash",
    "interactions.conversation.message-info.info.restore": "interactions.conversation.message-info.info.restore",
    "interactions.conversation.message-info.info.unarchive": "interactions.conversation.message-info.info.unarchive",
    "interactions.conversation.showMore.button": "interactions.conversation.showMore.button",
    "interactions.conversation.showMore.message-info.error.showMoreMessages": "interactions.conversation.showMore.message-info.error.showMoreMessages",
    "interactions.conversation.success.delete": "interactions.conversation.success.delete",
    "interactions.conversation.success.save": "interactions.conversation.success.save",
    "interactions.conversation.success.send": "interactions.conversation.success.send",
    "interactions.conversations.search.list.title": "interactions.conversations.search.list.title",
    "interactions.conversations.search.message-info.error.archive": "interactions.conversations.search.message-info.error.archive",
    "interactions.conversations.search.message-info.error.categorize": "interactions.conversations.search.message-info.error.categorize",
    "interactions.conversations.search.message-info.error.delete": "interactions.conversations.search.message-info.error.delete",
    "interactions.conversations.search.message-info.error.markAsRead": "interactions.conversations.search.message-info.error.markAsRead",
    "interactions.conversations.search.message-info.error.markAsUnread": "interactions.conversations.search.message-info.error.markAsUnread",
    "interactions.conversations.search.message-info.error.removeFromTrash": "interactions.conversations.search.message-info.error.removeFromTrash",
    "interactions.conversations.search.message-info.error.restore": "interactions.conversations.search.message-info.error.restore",
    "interactions.conversations.search.message-info.error.unarchive": "interactions.conversations.search.message-info.error.unarchive",
    "interactions.conversations.search.message-info.info.archive": "interactions.conversations.search.message-info.info.archive",
    "interactions.conversations.search.message-info.info.categorize": "interactions.conversations.search.message-info.info.categorize",
    "interactions.conversations.search.message-info.info.delete": "interactions.conversations.search.message-info.info.delete",
    "interactions.conversations.search.message-info.info.markAsRead": "interactions.conversations.search.message-info.info.markAsRead",
    "interactions.conversations.search.message-info.info.markAsUnread": "interactions.conversations.search.message-info.info.markAsUnread",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "interactions.conversations.search.message-info.info.pop-up-removeFromTrash",
    "interactions.conversations.search.message-info.info.removeFromTrash": "interactions.conversations.search.message-info.info.removeFromTrash",
    "interactions.conversations.search.message-info.info.restore": "interactions.conversations.search.message-info.info.restore",
    "interactions.conversations.search.message-info.info.unarchive": "interactions.conversations.search.message-info.info.unarchive",
    "interactions.conversations.sort.lastMessageReceivedDate.asc": "interactions.conversations.sort.lastMessageReceivedDate.asc",
    "interactions.conversations.sort.lastMessageReceivedDate.desc": "interactions.conversations.sort.lastMessageReceivedDate.desc",
    "interactions.forbidden-interlocutor": "interactions.forbidden-interlocutor",
    "interactions.label.categorize-component.buttons.close": "interactions.label.categorize-component.buttons.close",
    "interactions.label.categorize-component.buttons.manage": "interactions.label.categorize-component.buttons.manage",
    "interactions.label.categorize-component.buttons.save": "interactions.label.categorize-component.buttons.save",
    "interactions.label.categorize-component.header.text": "interactions.label.categorize-component.header.text",
    "interactions.label.categorize-component.header.title": "interactions.label.categorize-component.header.title",
    "interactions.label.categorize-component.loading": "interactions.label.categorize-component.loading",
    "interactions.label.categorize-component.no-data": "interactions.label.categorize-component.no-data",
    "interactions.label.popup-edit-component.buttons.close": "interactions.label.popup-edit-component.buttons.close",
    "interactions.label.popup-edit-component.buttons.save": "interactions.label.popup-edit-component.buttons.save",
    "interactions.label.popup-edit-component.fields.color.label-color-1": "interactions.label.popup-edit-component.fields.color.label-color-1",
    "interactions.label.popup-edit-component.fields.color.label-color-2": "interactions.label.popup-edit-component.fields.color.label-color-2",
    "interactions.label.popup-edit-component.fields.color.label-color-3": "interactions.label.popup-edit-component.fields.color.label-color-3",
    "interactions.label.popup-edit-component.fields.color.label-color-4": "interactions.label.popup-edit-component.fields.color.label-color-4",
    "interactions.label.popup-edit-component.fields.color.label-color-5": "interactions.label.popup-edit-component.fields.color.label-color-5",
    "interactions.label.popup-edit-component.fields.color.text": "interactions.label.popup-edit-component.fields.color.text",
    "interactions.label.popup-edit-component.fields.color.title": "interactions.label.popup-edit-component.fields.color.title",
    "interactions.label.popup-edit-component.fields.description.text": "interactions.label.popup-edit-component.fields.description.text",
    "interactions.label.popup-edit-component.fields.description.title": "interactions.label.popup-edit-component.fields.description.title",
    "interactions.label.popup-edit-component.fields.name.text": "interactions.label.popup-edit-component.fields.name.text",
    "interactions.label.popup-edit-component.fields.name.title": "interactions.label.popup-edit-component.fields.name.title",
    "interactions.label.popup-edit-component.header.edit.text": "interactions.label.popup-edit-component.header.edit.text",
    "interactions.label.popup-edit-component.header.edit.title": "interactions.label.popup-edit-component.header.edit.title",
    "interactions.label.popup-edit-component.header.new.text": "interactions.label.popup-edit-component.header.new.text",
    "interactions.label.popup-edit-component.header.new.title": "interactions.label.popup-edit-component.header.new.title",
    "interactions.label.popup-edit-component.message-info.error.duplication": "interactions.label.popup-edit-component.message-info.error.duplication",
    "interactions.label.popup-edit-component.message-info.error.invalid-form": "interactions.label.popup-edit-component.message-info.error.invalid-form",
    "interactions.label.settings.buttons.add.label": "interactions.label.settings.buttons.add.label",
    "interactions.label.settings.buttons.add.title": "interactions.label.settings.buttons.add.title",
    "interactions.label.settings.buttons.delete.label": "interactions.label.settings.buttons.delete.label",
    "interactions.label.settings.buttons.delete.title": "interactions.label.settings.buttons.delete.title",
    "interactions.label.settings.buttons.edit.label": "interactions.label.settings.buttons.edit.label",
    "interactions.label.settings.buttons.edit.title": "interactions.label.settings.buttons.edit.title",
    "interactions.label.settings.fields.defaultText.text": "interactions.label.settings.fields.defaultText.text",
    "interactions.label.settings.fields.defaultText.title": "interactions.label.settings.fields.defaultText.title",
    "interactions.label.settings.fields.description.title": "interactions.label.settings.fields.description.title",
    "interactions.label.settings.fields.name.title": "interactions.label.settings.fields.name.title",
    "interactions.label.settings.message-info.error.create": "interactions.label.settings.message-info.error.create",
    "interactions.label.settings.message-info.error.delete": "interactions.label.settings.message-info.error.delete",
    "interactions.label.settings.message-info.error.edit": "interactions.label.settings.message-info.error.edit",
    "interactions.label.settings.message-info.error.get-404": "interactions.label.settings.message-info.error.get-404",
    "interactions.label.settings.message-info.error.unknow": "interactions.label.settings.message-info.error.unknow",
    "interactions.label.settings.message-info.info.create": "interactions.label.settings.message-info.info.create",
    "interactions.label.settings.message-info.info.delete": "interactions.label.settings.message-info.info.delete",
    "interactions.label.settings.message-info.info.deleteConfirm": "interactions.label.settings.message-info.info.deleteConfirm",
    "interactions.label.settings.message-info.info.edit": "interactions.label.settings.message-info.info.edit",
    "interactions.label.settings.search.results.title": "interactions.label.settings.search.results.title",
    "interactions.message-attachment.message-info.error.download": "interactions.message-attachment.message-info.error.download",
    "interactions.message-composer-attachments.multiple-upload.error": "interactions.message-composer-attachments.multiple-upload.error",
    "interactions.message-composer-attachments.multiple-upload.success": "interactions.message-composer-attachments.multiple-upload.success",
    "interactions.message-editor.message-info.info.pop-up.replace-subject": "interactions.message-editor.message-info.info.pop-up.replace-subject",
    "interactions.preview-component.buttons.close": "interactions.preview-component.buttons.close",
    "interactions.preview-component.error": "interactions.preview-component.error",
    "interactions.preview-component.loading": "interactions.preview-component.loading",
    "interactions.preview-component.title.uploadDate": "interactions.preview-component.title.uploadDate",
    "interactions.preview-component.title.uploader": "interactions.preview-component.title.uploader",
    "interactions.side-menu.conversations.label": "interactions.side-menu.conversations.label",
    "interactions.side-menu.conversations.lines.archived": "interactions.side-menu.conversations.lines.archived",
    "interactions.side-menu.conversations.lines.draft": "interactions.side-menu.conversations.lines.draft",
    "interactions.side-menu.conversations.lines.mine": "interactions.side-menu.conversations.lines.mine",
    "interactions.side-menu.conversations.lines.sent": "interactions.side-menu.conversations.lines.sent",
    "interactions.side-menu.conversations.lines.trashed": "interactions.side-menu.conversations.lines.trashed",
    "interactions.side-menu.conversations.sr-only": "interactions.side-menu.conversations.sr-only",
    "interactions.side-menu.labels.configurationButton": "interactions.side-menu.labels.configurationButton",
    "interactions.side-menu.labels.label": "interactions.side-menu.labels.label",
    "interactions.side-menu.new-conversation": "interactions.side-menu.new-conversation",
    "profile.infos.header": "profile.infos.header",
    "profile.infos.howto.messaging": "profile.infos.howto.messaging",
    "profile.infos.howto.notifications": "profile.infos.howto.notifications",
    "profile.infos.howto.warnings": "profile.infos.howto.warnings",
    "profile.infos.introduction": "profile.infos.introduction",
    "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED",
    "profile.preferences.closeErrorMessage.label": "profile.preferences.closeErrorMessage.label",
    "profile.preferences.closeErrorMessage.title": "profile.preferences.closeErrorMessage.title",
    "profile.preferences.closeErrorMessages.false": "profile.preferences.closeErrorMessages.false",
    "profile.preferences.closeErrorMessages.true": "profile.preferences.closeErrorMessages.true",
    "profile.preferences.edit.cancel": "profile.preferences.edit.cancel",
    "profile.preferences.edit.save": "profile.preferences.edit.save",
    "profile.preferences.header": "profile.preferences.header",
    "profile.preferences.message-info.info.save": "profile.preferences.message-info.info.save",
    "profile.preferences.view.edit": "profile.preferences.view.edit",
    "profile.side-bar.sign-out": "profile.side-bar.sign-out",
    "profile.side-bar.title": "profile.side-bar.title",
    "profile.side-menu.preferences": "profile.side-menu.preferences",
    "profile.side-menu.subscriptions": "profile.side-menu.subscriptions",
    "profile.subscriptions.edit.cancel": "profile.subscriptions.edit.cancel",
    "profile.subscriptions.edit.save": "profile.subscriptions.edit.save",
    "profile.subscriptions.message-info.error.loading": "profile.subscriptions.message-info.error.loading",
    "profile.subscriptions.message-info.error.save": "profile.subscriptions.message-info.error.save",
    "profile.subscriptions.message-info.info.save": "profile.subscriptions.message-info.info.save",
    "profile.subscriptions.view.edit": "profile.subscriptions.view.edit",
    "profile.subscriptions.view.state.disabled": "profile.subscriptions.view.state.disabled",
    "profile.subscriptions.view.state.enabled": "profile.subscriptions.view.state.enabled",
    "shared.components.a11y.modals.alert-modal.closeButtonText": "shared.components.a11y.modals.alert-modal.closeButtonText",
    "shared.components.a11y.modals.alert-modal.closeButtonTitle": "shared.components.a11y.modals.alert-modal.closeButtonTitle",
    "shared.components.dropdown.default-button-title": "shared.components.dropdown.default-button-title",
    "shared.components.form-component.form-fields-required-label": "shared.components.form-component.form-fields-required-label",
    "shared.components.form-group-component.missing-text": "shared.components.form-group-component.missing-text",
    "shared.components.modals.document-preview.error-message": "shared.components.modals.document-preview.error-message",
    "shared.components.modals.document-preview.modal-title": "shared.components.modals.document-preview.modal-title",
    "shared.components.modals.document-preview.select-page": "shared.components.modals.document-preview.select-page",
    "shared.components.modals.document-preview.upload-by": "shared.components.modals.document-preview.upload-by",
    "shared.components.modals.document-preview.zoom-in": "shared.components.modals.document-preview.zoom-in",
    "shared.components.modals.document-preview.zoom-out": "shared.components.modals.document-preview.zoom-out",
    "shared.components.modals.image-preview.error-message": "shared.components.modals.image-preview.error-message",
    "shared.components.modals.image-preview.loading-message": "shared.components.modals.image-preview.loading-message",
    "shared.components.modals.image-preview.modal-title": "shared.components.modals.image-preview.modal-title",
    "shared.components.modals.image-preview.upload-by": "shared.components.modals.image-preview.upload-by",
    "shared.components.modals.image-preview.upload-date": "shared.components.modals.image-preview.upload-date",
    "shared.components.modals.image-preview.zoom-in": "shared.components.modals.image-preview.zoom-in",
    "shared.components.modals.image-preview.zoom-out": "shared.components.modals.image-preview.zoom-out",
    "shared.components.modals.modal-component.modal-cancel": "shared.components.modals.modal-component.modal-cancel",
    "shared.components.modals.modal-component.modal-confirm": "shared.components.modals.modal-component.modal-confirm",
    "shared.components.modals.title.closeButton": "shared.components.modals.title.closeButton",
    "shared.components.result-list.results.error": "shared.components.result-list.results.error",
    "shared.components.result-list.results.loading": "shared.components.result-list.results.loading",
    "shared.components.result-list.results.none": "shared.components.result-list.results.none",
    "shared.components.result-list.results.wait": "shared.components.result-list.results.wait",
    "shared.components.text-editor.actions-list.f-aligncenter": "shared.components.text-editor.actions-list.f-aligncenter",
    "shared.components.text-editor.actions-list.f-alignleft": "shared.components.text-editor.actions-list.f-alignleft",
    "shared.components.text-editor.actions-list.f-alignright": "shared.components.text-editor.actions-list.f-alignright",
    "shared.components.text-editor.actions-list.f-bold": "shared.components.text-editor.actions-list.f-bold",
    "shared.components.text-editor.actions-list.f-color.help": "shared.components.text-editor.actions-list.f-color.help",
    "shared.components.text-editor.actions-list.f-color.label": "shared.components.text-editor.actions-list.f-color.label",
    "shared.components.text-editor.actions-list.f-font.help": "shared.components.text-editor.actions-list.f-font.help",
    "shared.components.text-editor.actions-list.f-font.label": "shared.components.text-editor.actions-list.f-font.label",
    "shared.components.text-editor.actions-list.f-gallery": "shared.components.text-editor.actions-list.f-gallery",
    "shared.components.text-editor.actions-list.f-italic": "shared.components.text-editor.actions-list.f-italic",
    "shared.components.text-editor.actions-list.f-link.help": "shared.components.text-editor.actions-list.f-link.help",
    "shared.components.text-editor.actions-list.f-link.ok": "shared.components.text-editor.actions-list.f-link.ok",
    "shared.components.text-editor.actions-list.f-link.url": "shared.components.text-editor.actions-list.f-link.url",
    "shared.components.text-editor.actions-list.f-list": "shared.components.text-editor.actions-list.f-list",
    "shared.components.text-editor.actions-list.f-size.help": "shared.components.text-editor.actions-list.f-size.help",
    "shared.components.text-editor.actions-list.f-size.label": "shared.components.text-editor.actions-list.f-size.label",
    "shared.components.text-editor.actions-list.f-spellCheck.check-spelling": "shared.components.text-editor.actions-list.f-spellCheck.check-spelling",
    "shared.components.text-editor.actions-list.f-spellCheck.choose-language": "shared.components.text-editor.actions-list.f-spellCheck.choose-language",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore": "shared.components.text-editor.actions-list.f-spellCheck.ignore",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore-all": "shared.components.text-editor.actions-list.f-spellCheck.ignore-all",
    "shared.components.text-editor.actions-list.f-underline": "shared.components.text-editor.actions-list.f-underline",
    "shared.components.text-editor.actions-list.f-upload.help": "shared.components.text-editor.actions-list.f-upload.help",
    "shared.components.text-editor.message-composer-attachments.delete": "shared.components.text-editor.message-composer-attachments.delete",
    "shared.components.text-editor.message-composer-attachments.download": "shared.components.text-editor.message-composer-attachments.download",
    "shared.components.text-editor.message-composer-attachments.dropdown-button-title": "shared.components.text-editor.message-composer-attachments.dropdown-button-title",
    "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type": "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type",
    "shared.components.text-editor.message-composer-attachments.message-info.error.download": "shared.components.text-editor.message-composer-attachments.message-info.error.download",
    "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file": "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file",
    "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload": "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload",
    "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used": "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used",
    "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached": "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached",
    "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit": "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit",
    "shared.components.text-editor.message-composer-attachments.message-info.error.preview": "shared.components.text-editor.message-composer-attachments.message-info.error.preview",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded": "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading": "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading",
    "shared.components.text-editor.message-composer-attachments.preview": "shared.components.text-editor.message-composer-attachments.preview",
    "shared.file-uploader.error.EMPTY_ATTACHMENT": "shared.file-uploader.error.EMPTY_ATTACHMENT",
    "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE": "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE",
    "shared.file-uploader.error.addFile": "shared.file-uploader.error.addFile",
    "shared.file-uploader.error.contentType": "shared.file-uploader.error.contentType",
    "shared.file-uploader.error.length": "shared.file-uploader.error.length",
    "shared.helpers.date-short-view.today": "shared.helpers.date-short-view.today",
    "shared.numeric-unit.billion": "shared.numeric-unit.billion",
    "shared.numeric-unit.million": "shared.numeric-unit.million",
    "shared.numeric-unit.thousand": "shared.numeric-unit.thousand",
    "shared.pagination": "shared.pagination",
    "shared.skip-link": "shared.skip-link",
    "side-panel.burger.label": "side-panel.burger.label",
    "side-panel.hide-menu": "side-panel.hide-menu",
    "side-panel.show-menu": "side-panel.show-menu",
    "side-panel.toggle-title": "side-panel.toggle-title",
    "sso-error.text": "sso-error.text",
    "sso-error.title": "sso-error.title",
    "topbar.message-info.log-out-confirmation": "topbar.message-info.log-out-confirmation",
    "topbar.module.interactions": "topbar.module.interactions",
    "topbar.module.profile": "topbar.module.profile",
    "topbar.nav.label": "topbar.nav.label",
    "topbar.organisation.name": "topbar.organisation.name"
  };
  _exports.default = _default;
});