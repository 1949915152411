define("ticust-s2h-web-application/translations/en-gb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "authentication": {
      "error": {
        "AUTHENTICATION_FAILED": "The authentication failed",
        "BAD_CREDENTIALS": "Error: username or password invalid",
        "UNKNOWN": "An unknown error occurred. Please try again later"
      },
      "login": {
        "company": "Company",
        "forgot": "Lost password",
        "message-info": {
          "error": {
            "invalid-form": "The form is invalid, at least one field is missing.",
            "invalid-input": {
              "company": "The company is required.",
              "password": "The password is required.",
              "username": "The username is required."
            }
          }
        },
        "password": "Password",
        "title": "Login",
        "username": "Login",
        "valid": "Sign in"
      },
      "prospect": {
        "create": {
          "birthDate": "Birth date",
          "company": "Company",
          "email": "E-mail address",
          "firstName": "First name",
          "lastName": "Last name",
          "link": "Join now",
          "user": {
            "password": "Password",
            "title": "Create Password"
          }
        },
        "message": {
          "save": {
            "error": "An error occurred while saving the prospect. Please try again later.",
            "has-no-company": "This organisation doesn't exist",
            "info": "The prospect has been saved.",
            "user": "The user has been created.",
            "wrong-email": "This email is already in use. Please use the another one."
          },
          "validation": {
            "invalid-form": "The form is invalid, correct it and try again."
          }
        },
        "new": {
          "buttons": {
            "cancel": "Cancel",
            "confirm": "Confirm",
            "create": "Submit"
          }
        },
        "title": "New user"
      },
      "pwdreinit": {
        "cancel": "Cancel",
        "email": "Email",
        "header": "Please fill your email to receive an activation url to renew your password",
        "message-info": {
          "error": {
            "invalid-form": "The form is incorrect.",
            "invalid-input": {
              "email": "The email is incorrect."
            }
          },
          "info": {
            "success": "If an account exists for {email}, you will receive an email with a renewal link inside"
          }
        },
        "title": "Reseting password",
        "validate": "Submit"
      },
      "pwdrenew": {
        "email": "Email",
        "firstnewpassword": "Please type here your new password",
        "message-info": {
          "error": {
            "echec": "Your password has not been changed.",
            "invalid-form": "The form is incorrect, please check the fields.",
            "invalid-input": {
              "firstPassword": "The first password is missing.",
              "secondPassword": "The second password is missing."
            }
          },
          "info": {
            "success": "Your password has been changed."
          }
        },
        "password": "Password",
        "secondnewpassword": "Please confirm your new password",
        "title": "Change password",
        "validate": "Submit"
      }
    },
    "common": {
      "application": {
        "title": "Trusted Interactions"
      },
      "file-upload": {
        "error": {
          "addFile": "The file has not been uploaded. Please try again later.",
          "contentType": "The type of attachment is not allowed.",
          "length": "The size of the attachment is greater than the maximum size allowed."
        }
      },
      "filter": {
        "datePrefix": "Date",
        "dateTimeLocale": "en",
        "hide": "Hide",
        "lessCriteria": "Show less criteria",
        "message-info": {
          "error": {
            "invalid-form": "The form is invalid, correct it and try again."
          }
        },
        "moreCriteria": "Show more criteria",
        "preset": "Preset:",
        "reset": "Reset criteria",
        "search": "Search",
        "show": "Show"
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Adviser }  CLIENT {Interlocutor } other {}}{gender, select, MR {Mr }  MME {Ms } other {}}{lastname} {firstname}",
        "unknown-person": "Unknown person"
      },
      "message-info": {
        "error": {
          "403": "You are not allowed to see this page.",
          "unknown": "An error occurred while loading the page. Please try again later."
        }
      },
      "notification": {
        "CUST_RESET_PASSWORD": {
          "label": "Reset password",
          "variables": {
            "URL_LINK": "URL_LINK"
          }
        },
        "LOWER_CONFIDENTIALITY_ACCEPTED": {
          "label": "Accept to lower confidentiality",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "INTERLOCUTOR_CIVILITY",
            "INTERLOCUTOR_FIRSTNAME": "INTERLOCUTOR_FIRSTNAME",
            "INTERLOCUTOR_LASTNAME": "INTERLOCUTOR_LASTNAME",
            "INTERLOCUTOR_NATURE": "INTERLOCUTOR_NATURE"
          }
        },
        "LOWER_CONFIDENTIALITY_REFUSED": {
          "label": "Refuse to lower confidentiality",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "INTERLOCUTOR_CIVILITY",
            "INTERLOCUTOR_FIRSTNAME": "INTERLOCUTOR_FIRSTNAME",
            "INTERLOCUTOR_LASTNAME": "INTERLOCUTOR_LASTNAME",
            "INTERLOCUTOR_NATURE": "INTERLOCUTOR_NATURE"
          }
        },
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": {
          "label": "New interlocutor message received",
          "variables": {
            "CONVERSATION_SUBJECT": "CONVERSATION_SUBJECT",
            "MESSAGE_EXTRACT": "MESSAGE_EXTRACT",
            "URL_LINK": "URL_LINK"
          }
        }
      },
      "page-error-message": "An unknown error occured. Please try again later or contact your service support",
      "pagination-component": {
        "currentPage": "Current page",
        "firstPage": "First page",
        "lastPage": "Last page",
        "nextPage": "Next page",
        "page": "Page",
        "previousPage": "Previous page"
      },
      "panel-collapse": {
        "close": "Close",
        "open": "Open",
        "title-default": "Error, title of panel is missing"
      },
      "result-list": {
        "action": {
          "show-grid": "Show in grid",
          "show-list": "Show in lines"
        },
        "results": {
          "error": "An error occurred while loading, please try again later.",
          "none": "No result available"
        },
        "sort": {
          "by": "Sort by"
        }
      },
      "selectors": {
        "actor-selector": {
          "error": "An error occurred during the search.",
          "loading": "Loading result(s)...",
          "no-results": "No recipient is matching this term",
          "required": "A recipient must be selected"
        },
        "theme-selector": {
          "error": "An error occurred during the search.",
          "loading": "Loading result(s)...",
          "no-results": "No theme is matching this term",
          "required": "A theme must be selected"
        }
      },
      "sidemenu": {
        "label": "Menu",
        "minimize": "Pull menu up",
        "pulldown": "Pull menu down",
        "search": "Search"
      },
      "spellchecker": {
        "languages": {
          "en": "English",
          "fr": "French"
        },
        "message-info": {
          "error": {
            "unknown": "An error occurred."
          }
        }
      },
      "variables": {
        "notificationCommon": {
          "EVENT_DATE": "EVENT_DATE",
          "RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
          "RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
          "RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME"
        }
      }
    },
    "common-projects": {
      "closed": "Closed Project",
      "components": {
        "annex-area-button": {
          "openConversation": {
            "buttonLabel": "Go to conversation",
            "title": "Conversation"
          }
        },
        "audit-component": {
          "results": {
            "error": "An error has occurred while loading audit lines",
            "loading": "Loading, please wait..."
          },
          "table": {
            "no-result": "No results to show"
          }
        },
        "buttons": {
          "close": "Close"
        },
        "file-uploader-label": "Upload",
        "file-uploading": "Upload in progress ...",
        "header": "History",
        "project-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Loading result(s)...",
          "no-results": "No entity is matching this term",
          "required": "An entity must be selected"
        },
        "table": {
          "action": "Action",
          "date": "Date",
          "newValue": "New value",
          "no-result": "No result",
          "oldValue": "Old value",
          "user": "User"
        }
      },
      "document": {
        "audit": {
          "action": {
            "fileInvalid": "Marked as invalid",
            "fileValid": "Marked as valid",
            "init": "Initialization",
            "status": "Status changed",
            "uploaded": "Deposit"
          },
          "button": {
            "close": "Close"
          },
          "column": {
            "action": "Action",
            "actor": "Actor",
            "date": "Date",
            "detail": "Detail",
            "type": "Type"
          },
          "userType": {
            "ADVISER": "Adviser",
            "CLIENT": "Client"
          }
        },
        "button": {
          "add": "Add a file",
          "audit": "See history about this document",
          "cancel": "Cancel",
          "copy": "Copy-paste a file from an existing project",
          "create": {
            "copy": "Copy a file",
            "new": "Blank document",
            "upload": "Upload a file"
          },
          "download": "Download all files of the document",
          "edit": {
            "show": "Edit"
          },
          "hideButtons": "Hide action buttons for the document and its files",
          "info": {
            "hide": "Hide complementary information about this document",
            "show": "See more information about this document"
          },
          "markAsNotNeeded": "Already present",
          "markAsNotValid": "Mark the document as Invalid",
          "mergeFiles": "Merge files",
          "save": "Validate",
          "showButtons": "Show action buttons for the document and its files",
          "undoAsNotNeeded": "Undo already present",
          "upload": "Upload a new file from device"
        },
        "create": {
          "new": {
            "title": "New Document"
          },
          "title": "Add a new document"
        },
        "fields": {
          "description": "Description",
          "nature": "Nature",
          "providerType": "Provider",
          "signatureRequired": "Signature required",
          "title": "Name"
        },
        "message-info": {
          "error": {
            "duplicate-title": "The document title already exists.",
            "invalid-form": "The form is invalid, correct it and try again.",
            "markAsNotNeeded": "An error occurred while updating the document as Already Present, please try again later.",
            "markAsNotValid": "An error occurred while updating the file as Invalid, please try again later.",
            "markAsValid": "An error occurred while updating the file as Valid, please try again later.",
            "save": "An error occurred while saving the document, please try again later.",
            "undoNotNeeded": "An error occurred while reverting Already Present status, please try again later."
          },
          "info": {
            "markAsNotNeeded": "The document has been marked as Already Present.",
            "markAsNotValid": "The document has been marked as Invalid.",
            "markAsValid": "The document has been marked as Valid.",
            "onlyAdviserAllowed": "* Only the document with Adviser provider can be set as signature required",
            "save": "The document has been saved successfully.",
            "undoNotNeeded": "The document Already Present status reverted."
          }
        },
        "nature": {
          "ADVERTISEMENT": "Advertisement",
          "BANK_STATEMENT": "Bank statement",
          "BIRTH_CERTIFICATE": "Birth certificate",
          "COMPROMISE_SALE": "Compromise sale",
          "IBAN": "IBAN",
          "IDENTIFICATION": "Proof of identity",
          "INSURANCE": "Insurance",
          "LOAN_OFFER": "Loan offer",
          "PAYSLIP": "Payslip",
          "POLICE_RECORD": "Police record",
          "TAX_NOTICE": "Tax notice",
          "UNKNOWN": "Unknown",
          "label": "Nature:"
        },
        "providerType": {
          "ACTOR": "Adviser",
          "INTERLOCUTOR": "Customer",
          "UNKNOWN": "Unknown",
          "label": "Provider:"
        },
        "refusedReason": {
          "BLURRY": "The document is blurry.",
          "ERROR": "The document is off topic.",
          "ILLEGIBLE": "The document is illegible.",
          "label": "Reason to reject:"
        },
        "status": {
          "KO": "Invalid",
          "NOT_NEEDED": "Already present",
          "OK": "Valid",
          "TO_ACKNOWLEDGE": "To be validated",
          "TO_UPLOAD": "New",
          "UPLOADED": "Uploaded"
        },
        "statusMessage": {
          "KO": "This document has been flagged as Invalid by advisor. Please upload a new file.",
          "NOT_NEEDED": "This document is already present.",
          "TO_ACKNOWLEDGE": "This document is awaiting validation by an advisor.",
          "TO_UPLOAD": "This document has been created. Please upload the corresponding file."
        },
        "type": {
          "FREE": "Free",
          "STRUCTURED": "Structured",
          "UNKNOWN": "Unknown",
          "label": "Type of document:"
        }
      },
      "file": {
        "button": {
          "delete": "Delete this file",
          "download": "Download this file",
          "markAsNotValid": "Mark the file as Invalid",
          "markAsValid": "Mark the file as Valid",
          "preview": "Preview this file",
          "sign": "Sign this file"
        },
        "help": {
          "signature-required": "The file needs to be signed",
          "signed": "The file is already signed"
        },
        "message-info": {
          "error": {
            "delete": "An error occurred while deleting the file, please try again later.",
            "get-e-signiture-url": "An error occurred while getting the URL to sign the file, please try again later.",
            "mark-as-not-valid": "An error occurred while updating the file as Invalid, please try again later.",
            "mark-as-valid": "An error occurred while updating the file as Valid, please try again later."
          },
          "info": {
            "delete": "The file has been deleted.",
            "mark-as-not-valid": "The file has been marked as Invalid.",
            "mark-as-valid": "The file has been marked as Valid.",
            "pop-up": {
              "delete": "Do you want to delete this file?"
            }
          }
        },
        "status": {
          "KO": "Invalid",
          "OK": "Valid",
          "TO_SIGN": "To sign",
          "UPLOADED": "Uploaded"
        }
      },
      "file-upload": {
        "error": {
          "contentType": "This file type is not allowed."
        },
        "info": {
          "uploadSucess": "Your file has been uploaded."
        }
      },
      "folder": "Folder",
      "list": {
        "tabs": {
          "personal": "Personal",
          "shared": "Shared"
        },
        "title": "{interlocutorName}'s projects"
      },
      "modal-copy": {
        "buttons": {
          "cancel": "Cancel",
          "copy": "Copy"
        },
        "document": "Choose the document to copy to",
        "file": "Choose the file to copy",
        "label": {
          "search": "Search"
        },
        "message-info": {
          "error": {
            "post-400": "An error occurred while copying the file, please check the document status or that you don't have file with such name.",
            "unknown": "An error occurred while copying the file, please try again later."
          },
          "info": {
            "save": "{nb, plural, =1 {Copied file has been saved.} other {Copied files have been saved.}}",
            "saveAttachment": "Copied attachment has been saved.",
            "uploading": "File is uploading..."
          }
        }
      },
      "modal-merge": {
        "buttons": {
          "cancel": "Cancel",
          "confirm": "Confirm",
          "move-down": "Move Down",
          "move-up": "Move Up",
          "preview": "Preview"
        },
        "filename": "Name of target file: ",
        "header": "Merge files",
        "list": "List of files to merge: ",
        "message-info": {
          "error": {
            "generic": "An error occurred while merging the file, please try again later."
          },
          "info": {
            "info": "Some files might not be merged.",
            "loading": "File is being merged, please wait...",
            "saved": "File has been merged and saved."
          },
          "invalid-input": {
            "filename-duplicate": "Filename already exists in the document.",
            "filename-empty": "Filename can't be empty.",
            "filename-long": "Filename is too long.",
            "list-empty": "At least two files must be selected.",
            "list-too-large": "The weight of all the files is too large."
          }
        }
      },
      "modal-share": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "message-info": {
          "error": {
            "loading": "An error occurred while loading the trusted interlocutors, please try again later.",
            "save": "An error occurred while sharing the project, please try again later."
          },
          "info": {
            "empty-result": "You don't have any trusted interlocutors.",
            "save": "Sharing information has been saved."
          }
        },
        "placeholder": "Rights",
        "title": "Project rights"
      },
      "modal-upload": {
        "message-info": {
          "error": {
            "failed-due-bad-request": "An error occurred while uploading the file. please check the size or type of the file.",
            "failed-to-upload": "An error occurred while uploading the file. please try again later.",
            "post-400": "An error occurred while uploading the file, please check the document status or that you don't have file with such name.",
            "unknown": "An error occurred while uploading the file, please try again later.",
            "wrong-format": "The format of a file for which a signature is required can only be a PDF"
          },
          "info": {
            "file-uploading": "Upload in progress ...",
            "save": "Your file has been uploaded."
          }
        }
      },
      "page-error-message": "An unknown error occurred. Please try again later or contact your service support",
      "preview-component": {
        "buttons": {
          "close": "Close"
        },
        "error": "An error occurred while downloading the picture, please try again later.",
        "loading": "Loading; please wait...",
        "title": {
          "filedBy": "Filed by:",
          "filedOn": "Filed on:"
        }
      },
      "project": {
        "assignation": "This project is handled by: ",
        "audit": {
          "action": {
            "CREATE": "Project creation",
            "SHARED_INTERLOCUTOR_ADD": "Add interlocutor {interlocutorName} as {newProfileName}",
            "SHARED_INTERLOCUTOR_REMOVE": "Remove interlocutor {interlocutorName}",
            "SHARED_INTERLOCUTOR_UPDATE": "Update interlocutor {interlocutorName} from {previousProfileName} to {newProfileName}",
            "STATUS_CLOSED": "Project closed",
            "STATUS_REOPENED": "Project reopened",
            "UPDATE": "Project updated",
            "auditToAdviser": "Notification send to adviser {receiverName}",
            "auditToCustomer": "Notification send to customer {receiverName}"
          },
          "button": {
            "close": "Close"
          },
          "column": {
            "action": "Action",
            "date": "Date",
            "detail": "Detail",
            "sender": "Author"
          }
        },
        "button": {
          "audit": "See history about this project",
          "back": "Return to the list of projects",
          "close": "Close the project",
          "download": "Download all files of the project",
          "edit": "Edit the project",
          "hideInformations": "Hide informations",
          "notify-to-adviser": "Notify my Adviser that my project has been updated",
          "notify-to-customer": "Notify my Customer that his project has been updated",
          "open": "Open the project",
          "openConversation": "Open the linked conversation",
          "reopen": "Reopen the project",
          "share": "Manage project publication",
          "showInformations": "Show more informations"
        },
        "closedDate": "Closed on",
        "createdBy": "By",
        "creationDate": "Created on",
        "finishDate": "Expected completion on",
        "lastUpdateDate": "Modified on {date} by {name}",
        "message-error": {
          "download": "An error occurred while downloading the project, please try again later."
        },
        "message-info": {
          "error": {
            "LIMITED_CLICK_FOR_ADVISER": "You have already notified your customer less than {limit, plural, =1 {# hour} other {# hours}} ago.",
            "LIMITED_CLICK_FOR_CUSTOMER": "You have already notified your adviser less than {limit, plural, =1 {# hour} other {# hours}} ago.",
            "close": "An error occurred while updating the project as closed, please try again later.",
            "reopen": "An error occurred while updating the project as in-progress, please try again later.",
            "unknown": "An error occurred while sending notification, please try again later."
          },
          "info": {
            "close": "The project has been closed.",
            "notify-update": "The notification has been sent.",
            "pop-up": {
              "close": "Do you want to close this project?",
              "reopen": "Do you want to reopen this project?"
            },
            "reopen": "The project has been reopened."
          }
        },
        "new": "New",
        "reopenDate": "Reopened on",
        "shared": {
          "byMe": "This project is currently published to {to} with the profile: ",
          "not": "This project is currently not published to other clients.",
          "right": {
            "FULL_ACCESS": "Full access",
            "NO_ACCESS": "No access",
            "READ_ONLY": "Read only"
          },
          "toMe": "This project is currently published by {from} with the profile: "
        },
        "statistics": {
          "messages": {
            "KO": "{nb, plural, =1 {# document} other {# documents}} rejected",
            "OK": "{nb, plural, =1 {# document} other {# documents}} approved",
            "TO_ACKNOWLEDGE": "{nb, plural, =1 {# document} other {# documents}} to acknowledge",
            "UPLOADED": "{nb, plural, =1 {# document} other {# documents}} uploaded"
          }
        },
        "type": {
          "FREE": "Free",
          "STRUCTURED": "Structured"
        }
      },
      "project-card": {
        "invalid-documents": "{numDocs, plural, =1 {# document was} other {# documents were}} flagged as invalid.",
        "project-closed-alt": "Project closed",
        "project-shared-alt": "Project shared"
      },
      "project-element-list": {
        "sort": {
          "creationDate": {
            "asc": "The oldest",
            "desc": "The most recent"
          },
          "lastUpdateDate": {
            "asc": "The oldest update",
            "desc": "The most recent update"
          },
          "sortBy": "Sort by:"
        },
        "status-filters": {
          "all": "All",
          "all-help": "All projects",
          "closed": "Closed",
          "closed-help": "Closed projects",
          "opened": "Open",
          "opened-help": "Open projects"
        }
      },
      "project-group-title": {
        "button": {
          "close": "Close",
          "open": "Open"
        }
      },
      "project-part": {
        "close": "Close this part",
        "open": "Open this part"
      },
      "project-part-validator": {
        "error": {
          "duplicate-title": "The template folder name already exists for this project."
        }
      },
      "project-template-duplicate-validator": {
        "error": {
          "duplicate-title": "The project template label already exists."
        }
      },
      "project-template-part-document-validator": {
        "error": {
          "duplicate-title": "The document template name already exists for this folder."
        }
      },
      "selectors": {
        "document-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-part-document-file-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-part-document-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        }
      },
      "side-menu": {
        "new-project": "New Project",
        "projects": "Projects"
      },
      "title": "Project"
    },
    "common-projects.selectors.actor-selector.error": "An error occurred while searching conversations. Please try again later.",
    "common.application.title": "Trusted Interactions",
    "common.file-upload.error.addFile": "The file has not been uploaded. Please try again later.",
    "common.file-upload.error.contentType": "The type of attachment is not allowed.",
    "common.file-upload.error.length": "File exceeds size limit. ",
    "common.message-info.error.403": "You are not allowed to see this page.",
    "common.message-info.error.unknown": "An error occurred. Please try again later.",
    "common.page-error-message": "An error occurred. Please try again later.",
    "common.pagination-component.currentPage": "Current page",
    "common.pagination-component.firstPage": "First page",
    "common.pagination-component.lastPage": "Last page",
    "common.pagination-component.nextPage": "Next page",
    "common.pagination-component.page": "Page",
    "common.pagination-component.previousPage": "Previous page",
    "common.result-list.results.error": "An error occurred when loading the page. Please check and try again later.",
    "common.result-list.results.none": "No result",
    "common.result-list.sort.by": "Sort by",
    "common.sidemenu.label": "Menu",
    "common.sidemenu.minimize": "Pull menu up",
    "common.sidemenu.pulldown": "Pull menu down",
    "common.sidemenu.search": "Search",
    "common.spellchecker.languages.de": "German",
    "common.spellchecker.languages.en": "English",
    "common.spellchecker.languages.es": "Spanish",
    "common.spellchecker.languages.fr": "French",
    "common.spellchecker.languages.it": "Italian",
    "common.spellchecker.languages.nl": "Dutch",
    "common.spellchecker.languages.pt": "Portuguese",
    "common.spellchecker.message-info.error.unknown": "An error occurred. ",
    "common.variables.notificationCommon.EVENT_DATE": "EVENT_DATE",
    "common.variables.notificationCommon.RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
    "common.variables.notificationCommon.RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
    "common.variables.notificationCommon.RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME",
    "disconnection.text.explanation": "Your secure messaging session is now closed. You may close this page.",
    "disconnection.text.reminder": "If you wish to access your secure messaging again, please use the access link from your members' area or your mobile application.",
    "disconnection.title": "Logging out of your secure messaging session",
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can't be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "file-empty": "File is not selected",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "unsupported-file": "File type is not supported for signature required document",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "interactions": {
      "conversation": {
        "action-bar": {
          "archive": "Archive the conversation",
          "categorize": "Classify the conversation",
          "delete": "Delete the conversation",
          "download": "Export the conversation",
          "expiring-date": "This conversation expires on:",
          "mark-as-read": "Mark the conversation as read",
          "mark-as-unread": "Mark the conversation as unread",
          "open-project": "Open project",
          "print": "Print the conversation",
          "removeFromTrash": "Permanently delete the conversation",
          "restore": "Restore the conversation",
          "sort-asc": "Sort the messages by date from oldest to newest",
          "sort-desc": "Sort the messages by date from newest to oldest",
          "unarchive": "Restore the conversation"
        },
        "cc": {
          "current": "This conversations is related to customer: {name}"
        },
        "composer": {
          "attachments": {
            "buttons": {
              "delete": {
                "title": "Delete the attachment"
              },
              "download": {
                "title": "Download the attachment"
              },
              "preview": {
                "title": "Preview the attachment"
              }
            },
            "upload-in-progress": "(in progress)"
          },
          "buttons": {
            "add-attachment": {
              "title": "Add a new attachment"
            },
            "cancel": {
              "title": "Cancel this edition",
              "value": "Cancel"
            },
            "save": {
              "title": "Save the message as draft",
              "value": "Save the draft"
            },
            "send": {
              "title": "Send the message",
              "value": "Send"
            }
          },
          "notifications": {
            "attachment-uploaded": "The attachment has been uploaded",
            "empty-file": "You can't upload empty files. Please try again with a valid file.",
            "file-name-already-used": "An attachment with the same name already exists.",
            "message-size-limit": "You have exceeded the max size of message attachments.",
            "upload-in-progress": "An attachment is being uploaded. Can not send the message"
          },
          "placeholder": "Write a message"
        },
        "create": {
          "cc": "Cc",
          "confidentiality": {
            "HIGH": "High",
            "LOW": "Regular",
            "label": "Confidentiality:"
          },
          "flash-answer": {
            "answer": {
              "no": "No",
              "yes": "Yes"
            },
            "indication": "If you answer \"yes\", your message will be \"archived\".",
            "question": "Does this information answer your question?"
          },
          "message-info": {
            "error": {
              "invalid-form": "The form is invalid, please check your input.",
              "pop-up": {
                "leaving-page": "Your changes may not be saved, do you want to leave this page?"
              },
              "recipient": "The recipient is required.",
              "save": {
                "conversation": "An error occurred while creating conversation. Please try again later.",
                "conversation-message": "An error occurred while creating message. Please try again later."
              },
              "subject": "The subject is required."
            },
            "info": {
              "save": "The conversation has been created."
            }
          },
          "subject": "Subject:",
          "theme": "Theme:",
          "title": "New conversation",
          "to": "To:"
        },
        "draft": {
          "button": {
            "delete": {
              "label": "Delete",
              "title": "Delete the draft conversation."
            }
          },
          "message-info": {
            "error": {
              "delete": "An error occurred while deleting the draft conversation. Please try again later.",
              "invalid-form": "The form is invalid, please check your input.",
              "save": {
                "conversation": "An error occurred while updating draft conversation. Please try again later.",
                "conversation-message": "An error occurred while updating draft message. Please try again later."
              }
            },
            "info": {
              "delete": "The conversation has been deleted.",
              "pop-up": {
                "delete": "Do you want to delete this conversation ?"
              },
              "save": "The draft has been updated."
            }
          },
          "title": "Edit a draft"
        },
        "errors": {
          "delete": "Failed to delete the draft. Please try again later",
          "save": "Failed to save the message. Please try again later",
          "send": "Failed to send the message. Please try again later"
        },
        "export": {
          "attachments": "With attachments",
          "button": {
            "cancel": "Cancel",
            "download": "Download"
          },
          "options": "Export options"
        },
        "header": {
          "has-attachments": "This conversation has attachments",
          "is-confidential": "This conversation is confidential",
          "message-number": "{nb, plural, =0 {no message} =1 {1 message} other {# messages}} ",
          "open-project": "This conversation is related to a personal project ",
          "status": {
            "CLOSED": "Archived",
            "DELETED": "Deleted",
            "INIT": "Draft",
            "IN_PROGRESS": "In progress",
            "TO_ARCHIVE": "Deleted definitively",
            "title": "Status"
          },
          "unread": "Unread"
        },
        "message-info": {
          "error": {
            "accept": "An error occurred while accepting the confidentiality downgrade. Please try again later.",
            "archive": "An error occurred while archiving the conversation. Please try again later.",
            "categorize": "An error occurred while categorizing the conversation. Please try again later.",
            "confidentiality-response": "An error occurred while answering the confidentiality asking. Please try again later.",
            "delete": "An error occurred while deleting the conversation. Please try again later.",
            "download": "An error occurred while downloading the conversation. Please try again later.",
            "markAsRead": "An error occurred while marking the conversation as read. Please try again later.",
            "markAsUnread": "An error occurred while marking the conversation as unread. Please try again later.",
            "pop-up": {
              "archive": "Do you want to archive this conversation ?",
              "delete": "Are you sure you want to delete this conversation ?",
              "leaving-page": "Your changes may not be saved, do you want to leave this page?"
            },
            "print": "An error occurred while printing the conversation. Please try again later.",
            "refuse": "An error occurred while refusing the confidentiality downgrade. Please try again later.",
            "removeFromTrash": "An error occurred while deleting definitively the conversation. Please try again later.",
            "restore": "An error occurred while restoring the conversation. Please try again later.",
            "unarchive": "An error occurred while restoring the conversation. Please try again later."
          },
          "info": {
            "accept": "The confidentiality downgrade has been accepted.",
            "archive": "The conversation has been archived.",
            "categorize": "The conversation has been categorized.",
            "delete": "The conversation has been deleted.",
            "markAsRead": "The conversation has been marked as read.",
            "markAsUnread": "The conversation has been marked as unread.",
            "pop-up-removeFromTrash": "Do you want to remove definitively this conversation?",
            "refuse": "The confidentiality downgrade has been rejected.",
            "removeFromTrash": "The conversation has been deleted definitively.",
            "restore": "The conversation has been restored.",
            "unarchive": "The conversation has been restored."
          }
        },
        "showMore": {
          "button": "Show more messages",
          "message-info": {
            "error": {
              "showMoreMessages": "An error occurred while loading the messages. Please try again later."
            },
            "info": {
              "showMoreMessages": "Older messages have been downloaded."
            }
          }
        },
        "success": {
          "delete": "The draft has been deleted",
          "save": "The draft has been saved",
          "send": "The message has been sent"
        },
        "templates": {
          "confidentiality": {
            "accept": "<p>Hello,</p><p>The customer has accepted to downgrade the confidentiality level of the conversation.</p><p>Best regards,</p>",
            "refuse": "<p>Hello,</p><p>The customer has refused to downgrade the confidentiality level of the conversation.</p><p>Best regards,</p>"
          }
        }
      },
      "conversation-action-bar-component": {
        "actions": {
          "archive": {
            "display": "Archive",
            "title": "Archive all checked conversations"
          },
          "categorize": {
            "display": "Classify in ...",
            "labelSelected": "Classify all checked conversations in {label}",
            "title": "Classify all checked conversations in ...",
            "toggle": "Open the menu 'Classify in ...'"
          },
          "checkAll": "Select all checked conversations",
          "delete": {
            "display": "Delete",
            "title": "Delete all checked conversations"
          },
          "markAs": {
            "display": "Mark as ...",
            "markAsRead": {
              "display": "Mark as read",
              "title": "Mark all checked conversations as read"
            },
            "markAsUnread": {
              "display": "Mark as unread",
              "title": "Mark all checked conversations as unread"
            },
            "toggle": "Open the menu 'Mark as ...'"
          },
          "removeFromTrash": {
            "display": "Remove from trash",
            "title": "Remove all checked conversations from trash"
          },
          "restore": {
            "display": "Restore",
            "title": "Restore all checked conversations"
          },
          "unarchive": {
            "display": "Restore",
            "title": "Restore all checked conversations"
          },
          "uncheckAll": "Unselect all checked conversations"
        },
        "search": {
          "button": "Filter conversation with the text",
          "input": "Text to search"
        },
        "sort": {
          "sortBy": "Sort by:"
        }
      },
      "conversation-line-component": {
        "buttons": {
          "check": "Check the conversation.",
          "uncheck": "Uncheck the conversation."
        },
        "confidential": "The conversation is confidential.",
        "conversationSubject": "Conversation subject.",
        "hasAttachments": "The conversation has at least one attachment",
        "isRead": {
          "read": "The last conversation message is read.",
          "unread": "The last conversation message is not read."
        },
        "lastMessageDisplayName": "Adviser name attached to the last message.",
        "lastMessageReceivedDate": "Last message date.",
        "lastMessageReceivedcontent": "Last message content.",
        "lastMessageStatus": {
          "DRAFT": "The last conversation message is a draft.",
          "RECEIVED": "The last conversation message comes from your adviser.",
          "SENT": "The last conversation message comes from you."
        }
      },
      "conversation-message": {
        "body": {
          "buttons": {
            "show-less": {
              "text": "--- display the partial message ---",
              "title": "Display the partial message"
            },
            "show-more": {
              "text": "--- display the full message ---",
              "title": "Display the full message"
            }
          }
        },
        "header": {
          "expand-button": {
            "label-expand": "Expand header",
            "label-shrink": "Shrink header"
          },
          "expanded": {
            "cc": "Cc:",
            "cc-type": {
              "actors": "(Adviser)",
              "externals": "(External)",
              "interlocutors": "(Customer)"
            },
            "channel": "Channel:",
            "channels": {
              "MAIL": "Mail"
            },
            "from": "From:",
            "receiveDate": "Received on:",
            "sendDate": "Sent at:",
            "subject": "Subject:",
            "tags": "Tags:",
            "to": "To:",
            "virus-scan": "Virus scan:"
          },
          "shrunken": {
            "cc": "Cc:",
            "from": "From:",
            "to": "To:"
          },
          "title": "Message Information"
        }
      },
      "conversation-message-attachment": {
        "attachment": "Attachments",
        "download": "Download the file",
        "dropdown-button-title": "Actions",
        "preview": "Preview the file"
      },
      "conversation-message-confidentiality": {
        "accept": "You accepted to downgrade the confidentiality level at {date}",
        "asking": "Downgrade the confidentiality level of this conversation:",
        "buttons": {
          "accept": "Accept",
          "refuse": "Refuse"
        },
        "refuse": "You refused to downgrade the confidentiality level at {date}"
      },
      "conversations": {
        "search": {
          "list": {
            "title": "Conversations"
          },
          "message-info": {
            "error": {
              "archive": "An error occurred while archiving. Please try again later.",
              "categorize": "An error occurred while categorizing. Please try again later.",
              "delete": "An error occurred while deleting. Please try again later.",
              "markAsRead": "An error occurred while marking as read. Please try again later.",
              "markAsUnread": "An error occurred while marking as unread. Please try again later.",
              "removeFromTrash": "An error occurred while deleting definitively. Please try again later.",
              "restore": "An error occurred while restoring. Please try again later.",
              "unarchive": "An error occurred while restoring. Please try again later."
            },
            "info": {
              "archive": "Archiving has been done.",
              "categorize": "Categorizing has been done.",
              "delete": "Removal has been done.",
              "markAsRead": "Marking as read has been done.",
              "markAsUnread": "Marking as unread has been done.",
              "pop-up-removeFromTrash": "Do you want to remove definitively?",
              "removeFromTrash": "Removal definitively has been done.",
              "restore": "Restoration has been done.",
              "unarchive": "Restoration has been done."
            }
          }
        },
        "sort": {
          "lastMessageReceivedDate": {
            "asc": "The oldest",
            "desc": "The most recent"
          }
        }
      },
      "forbidden-interlocutor": "The access to this user is not allowed",
      "label": {
        "categorize-component": {
          "buttons": {
            "close": "Close",
            "manage": "Manage my personal folders",
            "save": "Save"
          },
          "header": {
            "text": "My personal folders",
            "title": "My personal folders"
          },
          "loading": "Loading, please wait...",
          "no-data": "No personal folder to display"
        },
        "popup-edit-component": {
          "buttons": {
            "close": "Cancel",
            "save": "Save"
          },
          "fields": {
            "color": {
              "label-color-1": "Color 1",
              "label-color-2": "Color 2",
              "label-color-3": "Color 3",
              "label-color-4": "Color 4",
              "label-color-5": "Color 5",
              "text": "Color:",
              "title": "Color"
            },
            "description": {
              "text": "Description:",
              "title": "Description"
            },
            "name": {
              "text": "Name:",
              "title": "Name"
            }
          },
          "header": {
            "edit": {
              "text": "Personal folder: [{name}]",
              "title": "Personal folder"
            },
            "new": {
              "text": "New personal folder",
              "title": "New personal folder"
            }
          },
          "message-info": {
            "error": {
              "duplication": "The name is already used for another personal folder.",
              "invalid-form": "The form is invalid, please check your input."
            }
          }
        },
        "settings": {
          "buttons": {
            "add": {
              "label": "Add",
              "title": "Add"
            },
            "delete": {
              "label": "Delete",
              "title": "Delete"
            },
            "edit": {
              "label": "Edit",
              "title": "Edit"
            }
          },
          "fields": {
            "defaultText": {
              "text": "Aa",
              "title": "Label example"
            },
            "description": {
              "title": "Description"
            },
            "name": {
              "title": "Name"
            }
          },
          "message-info": {
            "error": {
              "create": "An error occurred, the personal folder hasn't been created. Please retry later.",
              "delete": "An error occurred, the personal folder hasn't been removed. Please retry later.",
              "edit": "An error occurred, the personal folder hasn't been updated. Please retry later.",
              "get-404": "An error occurred, the label was not found. Please retry later.",
              "unknow": "An error occurred on the page. Please retry later."
            },
            "info": {
              "create": "The personal folder has been created.",
              "delete": "The personal folder has been deleted.",
              "deleteConfirm": "Do you want to delete the personal folder [{name}]?",
              "edit": "The personal folder has been updated."
            }
          },
          "search": {
            "results": {
              "title": "Personal folders"
            }
          }
        }
      },
      "message-attachment": {
        "message-info": {
          "error": {
            "download": "An error occurred while downloading the attachment, please try again later."
          }
        }
      },
      "message-composer-attachments": {
        "multiple-upload": {
          "error": "{nbUploadsFailed, plural, one {# attachment} other {# attachments}} could not be uploaded.",
          "success": "{nbUploadsPassed, plural, one {The attachment has} other {# attachments have}} been uploaded."
        }
      },
      "preview-component": {
        "buttons": {
          "close": "Back"
        },
        "error": "An error occurred while downloading the picture, please try again later.",
        "loading": "Loading, please wait...",
        "title": {
          "uploadDate": "Uploaded on:",
          "uploader": "Uploaded by:"
        }
      },
      "side-menu": {
        "conversations": {
          "label": "Conversations",
          "lines": {
            "archived": "Archived",
            "draft": "Drafts",
            "mine": "My Conversations",
            "sent": "Sent",
            "trashed": "Trash"
          },
          "sr-only": "conversations"
        },
        "labels": {
          "configurationButton": "Configuration",
          "label": "Personal folders"
        },
        "new-conversation": "New Conversation"
      }
    },
    "interactions.conversation-action-bar-component.actions.archive.display": "Archive",
    "interactions.conversation-action-bar-component.actions.archive.title": "Archive all conversations selected",
    "interactions.conversation-action-bar-component.actions.categorize.display": "Classify in ...",
    "interactions.conversation-action-bar-component.actions.categorize.labelSelected": "Classify all conversations selected in {label}",
    "interactions.conversation-action-bar-component.actions.categorize.title": "Classify all conversations selected in ...",
    "interactions.conversation-action-bar-component.actions.categorize.toggle": "Open the 'Classify in…' menu",
    "interactions.conversation-action-bar-component.actions.checkAll": "Select all",
    "interactions.conversation-action-bar-component.actions.delete.display": "Delete",
    "interactions.conversation-action-bar-component.actions.delete.title": "Delete all conversations selected",
    "interactions.conversation-action-bar-component.actions.markAs.display": "Mark as ...",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display": "Mark as read",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title": "Mark all conversations selected as read",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display": "Mark as unread",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title": "Mark all conversations selected as unread",
    "interactions.conversation-action-bar-component.actions.markAs.toggle": "Open the 'Mark as…' menu",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.display": "Delete from trash",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.title": "Delete all conversations selected from trash",
    "interactions.conversation-action-bar-component.actions.restore.display": "Retrieve",
    "interactions.conversation-action-bar-component.actions.restore.title": "Retrieve all conversations selected",
    "interactions.conversation-action-bar-component.actions.unarchive.display": "Restore",
    "interactions.conversation-action-bar-component.actions.unarchive.title": "Restore all conversations selected",
    "interactions.conversation-action-bar-component.actions.uncheckAll": "Deselect all",
    "interactions.conversation-action-bar-component.search.button": "Filter conversations with the text entered",
    "interactions.conversation-action-bar-component.search.input": "Enter text to search for",
    "interactions.conversation-action-bar-component.sort.sortBy": "Sort by:",
    "interactions.conversation-line-component.buttons.check": "Select conversation.",
    "interactions.conversation-line-component.buttons.uncheck": "Deselect conversation.",
    "interactions.conversation-line-component.conversationSubject": "Conversation subject.",
    "interactions.conversation-line-component.hasAttachments": "The conversation has at least one attachment",
    "interactions.conversation-line-component.isRead.read": "The last conversation message has been read.",
    "interactions.conversation-line-component.isRead.unread": "The last conversation message has not been read.",
    "interactions.conversation-line-component.lastMessageReceivedDate": "Last message date.",
    "interactions.conversation-line-component.lastMessageReceivedcontent": "Last message content.",
    "interactions.conversation-line-component.lastMessageStatus.DRAFT": "The last conversation message is a draft.",
    "interactions.conversation-line-component.lastMessageStatus.RECEIVED": "The last conversation message comes from your advisor.",
    "interactions.conversation-line-component.lastMessageStatus.SENT": "The last conversation message comes from you.",
    "interactions.conversation-message-attachment.attachment": "Attachments",
    "interactions.conversation-message-attachment.download": "Download",
    "interactions.conversation-message-attachment.dropdown-button-title": "Actions",
    "interactions.conversation-message-attachment.preview": "Preview",
    "interactions.conversation-message.body.buttons.show-less.text": " --- display partial message ---",
    "interactions.conversation-message.body.buttons.show-less.title": "Display partial message",
    "interactions.conversation-message.body.buttons.show-more.text": " --- display full message ---",
    "interactions.conversation-message.body.buttons.show-more.title": "Display full message",
    "interactions.conversation-message.header.expand-button.label-expand": "Expand header",
    "interactions.conversation-message.header.expand-button.label-shrink": "Shrink header",
    "interactions.conversation-message.header.expanded.channel": "Channel:",
    "interactions.conversation-message.header.expanded.channels.MAIL": "E-mail",
    "interactions.conversation-message.header.expanded.from": "From:",
    "interactions.conversation-message.header.expanded.receiveDate": "Received on:",
    "interactions.conversation-message.header.expanded.sendDate": "Sent on:",
    "interactions.conversation-message.header.expanded.subject": "Subject:",
    "interactions.conversation-message.header.expanded.tags": "Tags:",
    "interactions.conversation-message.header.expanded.to": "To:",
    "interactions.conversation-message.header.expanded.virus-scan": "Virus scan:",
    "interactions.conversation-message.header.shrunken.from": "From:",
    "interactions.conversation-message.header.shrunken.to": "To:",
    "interactions.conversation-message.header.title": "Message Information",
    "interactions.conversation.action-bar.archive": "Archive conversation",
    "interactions.conversation.action-bar.categorize": "Classify conversation",
    "interactions.conversation.action-bar.delete": "Delete conversation.",
    "interactions.conversation.action-bar.download": "Export conversation",
    "interactions.conversation.action-bar.expiring-date": "This conversation expires on:",
    "interactions.conversation.action-bar.mark-as-read": "Mark conversation as read",
    "interactions.conversation.action-bar.mark-as-unread": "Mark conversation as unread",
    "interactions.conversation.action-bar.print": "Print conversation",
    "interactions.conversation.action-bar.removeFromTrash": "Permanently delete conversation",
    "interactions.conversation.action-bar.restore": "Retrieve conversation",
    "interactions.conversation.action-bar.sort-asc": "Sort messages by date from oldest to newest",
    "interactions.conversation.action-bar.sort-desc": "Sort messages by date from newest to oldest",
    "interactions.conversation.action-bar.unarchive": "Retrieve conversation",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Delete this attachment",
    "interactions.conversation.composer.attachments.buttons.download.title": "Download this attachment",
    "interactions.conversation.composer.attachments.buttons.preview.title": "View attachment",
    "interactions.conversation.composer.attachments.upload-in-progress": "(in process)",
    "interactions.conversation.composer.buttons.add-attachment.title": "Add an attachment",
    "interactions.conversation.composer.buttons.cancel.title": "Cancel changes",
    "interactions.conversation.composer.buttons.cancel.value": "Cancel",
    "interactions.conversation.composer.buttons.save.title": "Draft",
    "interactions.conversation.composer.buttons.save.value": "Draft",
    "interactions.conversation.composer.buttons.send.title": "Send the new message",
    "interactions.conversation.composer.buttons.send.value": "Send",
    "interactions.conversation.composer.notifications.attachment-uploaded": "The attachment has been saved",
    "interactions.conversation.composer.notifications.empty-file": "You can't upload empty files. Please try again with a valid file.",
    "interactions.conversation.composer.notifications.file-name-already-used": "An attachment with the same name already exists.",
    "interactions.conversation.composer.notifications.message-size-limit": "Attachments exceed size limit.",
    "interactions.conversation.composer.notifications.upload-in-progress": "An attachment is being uploaded, please wait until the end of the transfer",
    "interactions.conversation.create.message-info.error.invalid-form": "The form is invalid, please correct it and try again.",
    "interactions.conversation.create.message-info.error.pop-up.leaving-page": "Your changes have not been saved, do you want to leave this page?",
    "interactions.conversation.create.message-info.error.save.conversation": "An error occurred while updating the conversation. Please try again later.",
    "interactions.conversation.create.message-info.error.save.conversation-message": "An error occurred while creating a message. Please try again later.",
    "interactions.conversation.create.message-info.error.subject": "You must select a theme.",
    "interactions.conversation.create.message-info.info.save": "The conversation has been created.",
    "interactions.conversation.create.subject": "Subject:",
    "interactions.conversation.create.title": "New conversation",
    "interactions.conversation.draft.button.delete.label": "Delete",
    "interactions.conversation.draft.button.delete.title": "Delete the draft conversation.",
    "interactions.conversation.draft.message-info.error.delete": "An error occurred while deleting the conversation. Please try again later.",
    "interactions.conversation.draft.message-info.error.invalid-form": "The form is invalid, please correct it and try again.",
    "interactions.conversation.draft.message-info.error.save.conversation": "An error occurred while saving the draft. Please try again later.",
    "interactions.conversation.draft.message-info.error.save.conversation-message": "An error occurred while saving the draft. Please try again later.",
    "interactions.conversation.draft.message-info.info.delete": "The conversation has been deleted.",
    "interactions.conversation.draft.message-info.info.pop-up.delete": "Are you sure you want to delete this draft?",
    "interactions.conversation.draft.message-info.info.save": "Your request has been saved as a draft.",
    "interactions.conversation.draft.title": "Edit a draft conversation",
    "interactions.conversation.errors.delete": "Failed to delete the draft. Please try again later",
    "interactions.conversation.errors.save": "An error occurred while saving the message. Please try again later.",
    "interactions.conversation.errors.send": "An error occurred while sending the message. Please try again later.",
    "interactions.conversation.header.message-number": "{nb, plural, =0 {none message} =1 {1 message} other {# messages}}",
    "interactions.conversation.header.status.CLOSED": "Archived",
    "interactions.conversation.header.status.DELETED": "Deleted",
    "interactions.conversation.header.status.INIT": "Draft",
    "interactions.conversation.header.status.IN_PROGRESS": "In process",
    "interactions.conversation.header.status.TO_ARCHIVE": "Permanently deleted",
    "interactions.conversation.header.status.title": "Status",
    "interactions.conversation.header.unread": "Unread",
    "interactions.conversation.message-info.confirm.pop-up.delete": "Do you really want to move this conversation to your Trash folder?",
    "interactions.conversation.message-info.error.archive": "An error occurred while archiving the conversation. Please try again later.",
    "interactions.conversation.message-info.error.categorize": "An error occurred while classifying the conversation. Please try again later.",
    "interactions.conversation.message-info.error.delete": "An error occurred while deleting the conversation. Please try again later.",
    "interactions.conversation.message-info.error.download": "An error occurred while downloading the conversation, please try again later.",
    "interactions.conversation.message-info.error.markAsRead": "An error occurred while marking the conversation as read. Please try again later.",
    "interactions.conversation.message-info.error.markAsUnread": "An error occurred while marking the conversation as unread. Please try again later.",
    "interactions.conversation.message-info.error.pop-up.archive": "Do you want to archive this conversation?",
    "interactions.conversation.message-info.error.pop-up.delete": "Are you sure you want to delete this conversation?",
    "interactions.conversation.message-info.error.pop-up.leaving-page": "Your changes have not been saved, do you want to leave this page?",
    "interactions.conversation.message-info.error.print": "An error occurred while printing the conversation, please try again later.",
    "interactions.conversation.message-info.error.removeFromTrash": "An error occurred while permanently deleting the conversation. Please try again later.",
    "interactions.conversation.message-info.error.restore": "An error occurred while retrieving the conversation. Please try again later.",
    "interactions.conversation.message-info.error.unarchive": "An error occurred while restoring the conversation. Please try again later.",
    "interactions.conversation.message-info.info.archive": "The conversation has been archived.",
    "interactions.conversation.message-info.info.categorize": "The conversation has been classified.",
    "interactions.conversation.message-info.info.delete": "The conversation has been deleted.",
    "interactions.conversation.message-info.info.markAsRead": "The conversation has been marked as read.",
    "interactions.conversation.message-info.info.markAsUnread": "The conversation has been marked as unread.",
    "interactions.conversation.message-info.info.pop-up-removeFromTrash": "Are you sure you want to permanently delete this conversation?",
    "interactions.conversation.message-info.info.readOnly": "This message is read-only.",
    "interactions.conversation.message-info.info.removeFromTrash": "The conversation has been permanently deleted.",
    "interactions.conversation.message-info.info.restore": "The conversation has been retrieved.",
    "interactions.conversation.message-info.info.unarchive": "The conversation has been restored.",
    "interactions.conversation.showMore.button": "Show older messages",
    "interactions.conversation.showMore.message-info.error.showMoreMessages": "An error occurred while loading messages. Please try again later.",
    "interactions.conversation.success.delete": "The draft has been deleted",
    "interactions.conversation.success.save": "The draft has been saved",
    "interactions.conversation.success.send": "The message has been sent.",
    "interactions.conversations.search.list.title": "Conversations",
    "interactions.conversations.search.message-info.error.archive": "An error occurred while archiving. Please try again later.",
    "interactions.conversations.search.message-info.error.categorize": "An error occurred while classifying the conversation. Please try again later.",
    "interactions.conversations.search.message-info.error.delete": "An error occurred while deleting the conversation. Please try again later.",
    "interactions.conversations.search.message-info.error.markAsRead": "An error occurred while marking as read. Please try again later.",
    "interactions.conversations.search.message-info.error.markAsUnread": "An error occurred while marking as unread. Please try again later.",
    "interactions.conversations.search.message-info.error.removeFromTrash": "An error occurred while deleting the conversation. Please try again later.",
    "interactions.conversations.search.message-info.error.restore": "An error occurred while restoring. Please try again later.",
    "interactions.conversations.search.message-info.error.unarchive": "An error occurred while restoring. Please try again later.",
    "interactions.conversations.search.message-info.info.archive": "Archiving has been done.",
    "interactions.conversations.search.message-info.info.categorize": "Classification has been done.",
    "interactions.conversations.search.message-info.info.delete": "Deletion has been done.",
    "interactions.conversations.search.message-info.info.markAsRead": "Marking as read has been done.",
    "interactions.conversations.search.message-info.info.markAsUnread": "Marking as unread has been done.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "Are you sure you want to delete it?",
    "interactions.conversations.search.message-info.info.removeFromTrash": "Permanent deletion has been done.",
    "interactions.conversations.search.message-info.info.restore": "Restoration has been done.",
    "interactions.conversations.search.message-info.info.unarchive": "Restoration has been done.",
    "interactions.conversations.sort.lastMessageReceivedDate.asc": "The oldest",
    "interactions.conversations.sort.lastMessageReceivedDate.desc": "The most recent",
    "interactions.forbidden-interlocutor": "Access to this user is not allowed",
    "interactions.label.categorize-component.buttons.close": "Close",
    "interactions.label.categorize-component.buttons.manage": "Manage my personal folders",
    "interactions.label.categorize-component.buttons.save": "Save",
    "interactions.label.categorize-component.header.text": "My personal folders",
    "interactions.label.categorize-component.header.title": "My personal folders",
    "interactions.label.categorize-component.loading": "Loading",
    "interactions.label.categorize-component.no-data": "No personal folder to display",
    "interactions.label.popup-edit-component.buttons.close": "Cancel",
    "interactions.label.popup-edit-component.buttons.save": "Save",
    "interactions.label.popup-edit-component.fields.color.label-color-1": "Color 1",
    "interactions.label.popup-edit-component.fields.color.label-color-2": "Color 2",
    "interactions.label.popup-edit-component.fields.color.label-color-3": "Color 3",
    "interactions.label.popup-edit-component.fields.color.label-color-4": "Color 4",
    "interactions.label.popup-edit-component.fields.color.label-color-5": "Color 5",
    "interactions.label.popup-edit-component.fields.color.text": "Color:",
    "interactions.label.popup-edit-component.fields.color.title": "Color",
    "interactions.label.popup-edit-component.fields.description.text": "Description:",
    "interactions.label.popup-edit-component.fields.description.title": "Description",
    "interactions.label.popup-edit-component.fields.name.text": "Name:",
    "interactions.label.popup-edit-component.fields.name.title": "Name",
    "interactions.label.popup-edit-component.header.edit.text": "Personal folder: [{name}]",
    "interactions.label.popup-edit-component.header.edit.title": "Personal folder",
    "interactions.label.popup-edit-component.header.new.text": "New personal folder",
    "interactions.label.popup-edit-component.header.new.title": "New personal folder",
    "interactions.label.popup-edit-component.message-info.error.duplication": "The name is already used for another personal folder.",
    "interactions.label.popup-edit-component.message-info.error.invalid-form": "The form is invalid, please correct it and try again.",
    "interactions.label.settings.buttons.add.label": "Add",
    "interactions.label.settings.buttons.add.title": "Add",
    "interactions.label.settings.buttons.delete.label": "Delete",
    "interactions.label.settings.buttons.delete.title": "Delete",
    "interactions.label.settings.buttons.edit.label": "Edit",
    "interactions.label.settings.buttons.edit.title": "Edit",
    "interactions.label.settings.fields.defaultText.text": "Aa",
    "interactions.label.settings.fields.defaultText.title": "Label example",
    "interactions.label.settings.fields.description.title": "Description",
    "interactions.label.settings.fields.name.title": "Name",
    "interactions.label.settings.message-info.error.create": "An error occurred, the personal folder has not been created. Please try again later.",
    "interactions.label.settings.message-info.error.delete": "An error occurred, the personal folder has not been deleted. Please try again later.",
    "interactions.label.settings.message-info.error.edit": "An error occurred, the personal folder has not been updated. Please try again later.",
    "interactions.label.settings.message-info.error.get-404": "An error occurred, the label was not found. Please try again later.",
    "interactions.label.settings.message-info.error.unknow": "An error occurred when loading the page. Please check and try again later.",
    "interactions.label.settings.message-info.info.create": "The personal folder has been created.",
    "interactions.label.settings.message-info.info.delete": "The personal folder has been deleted.",
    "interactions.label.settings.message-info.info.deleteConfirm": "Do you want to delete the personal folder [{name}]?",
    "interactions.label.settings.message-info.info.edit": "The personal folder has been updated.",
    "interactions.label.settings.search.results.title": "Personal folders",
    "interactions.message-attachment.message-info.error.download": "An error occurred while downloading the attachment, please try again later.",
    "interactions.message-composer-attachments.multiple-upload.error": "{nbUploadsFailed, plural, one {# attachment} other {# attachments}} could not be uploaded.",
    "interactions.message-composer-attachments.multiple-upload.success": "{nbUploadsPassed, plural, one {The attachment has} other {# attachments have}} been uploaded.",
    "interactions.message-editor.message-info.info.pop-up.replace-subject": "Are you sure you want to delete the current message and replace it with the new selected subject?",
    "interactions.preview-component.buttons.close": "Back",
    "interactions.preview-component.error": "An error occurred while downloading the picture, please try again later.",
    "interactions.preview-component.loading": "Loading",
    "interactions.preview-component.title.uploadDate": "Uploaded on",
    "interactions.preview-component.title.uploader": "Uploaded by",
    "interactions.side-menu.conversations.label": "Conversations",
    "interactions.side-menu.conversations.lines.archived": "Archived",
    "interactions.side-menu.conversations.lines.draft": "Drafts",
    "interactions.side-menu.conversations.lines.mine": "My conversations",
    "interactions.side-menu.conversations.lines.sent": "Sent",
    "interactions.side-menu.conversations.lines.trashed": "Trash",
    "interactions.side-menu.conversations.sr-only": "conversations",
    "interactions.side-menu.labels.configurationButton": "Configuration",
    "interactions.side-menu.labels.label": "Personal folders",
    "interactions.side-menu.new-conversation": "New conversation",
    "interlocutors": {
      "breadcrumb": {
        "title": "Interlocutors"
      },
      "interlocutor": {
        "projects": {
          "breadcrumb": {
            "title": "Projects"
          },
          "search": {
            "closed": "Closed Projects",
            "open": "Current Projects"
          }
        }
      }
    },
    "profile": {
      "coordinates": {
        "edit": {
          "cancel": "Cancel",
          "email": {
            "clear": "Clear the external email field.",
            "label": "External email:",
            "title": "External email"
          },
          "mobile-phone": {
            "clear": "Clear the mobile phone field.",
            "invalid-message": "The provided phone number is invalid.",
            "label": "Mobile phone :",
            "title": "Mobile phone"
          },
          "save": "Save"
        },
        "header": "Contact details",
        "message-info": {
          "error": {
            "invalid-form": "The form is invalid, please corect it and try again.",
            "save": "An error occurred, your contact details have not been updated. Please try again later."
          },
          "info": {
            "save": "Your contact details have been updated."
          }
        },
        "view": {
          "edit": "Modify",
          "email": {
            "label": "External email:"
          },
          "mobile-phone": {
            "label": "Mobile phone :"
          }
        }
      },
      "infos": {
        "field": {
          "firstname": {
            "label": "First name:",
            "title": "First name"
          },
          "name": {
            "label": "Last name:",
            "title": "Last name"
          }
        },
        "header": "Personal information"
      },
      "notifications": {
        "NEW_CAMPAIGN_MESSAGE_RECEIVED": "New campaign message received",
        "NEW_DOCUMENT_UPLOADED_BY_ADVISER": "New document uploaded by adviser",
        "NEW_FILE_AVAILABLE_TO_SIGN": "New file to sign",
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "New message received",
        "PROJECT_PUBLICATION_STOPPED": "Project sharing stopped",
        "PROJECT_PUBLISHED_TO_INTERLOCUTOR": "New project shared with me",
        "SEND_DOCUMENT_REJECT_TO_CUSTOMER": "Document rejected by adviser",
        "SEND_UPDATE_TO_CUSTOMER": "Project updated"
      },
      "preferences": {
        "authorizeAudioMessage": {
          "label": "Authorized audio messages:",
          "title": "Authorized audio messages:"
        },
        "authorizeAudioMessages": {
          "false": "No",
          "true": "Yes"
        },
        "availableLangages": {
          "en-gb": "English",
          "fr-fr": "Français"
        },
        "closeErrorMessage": {
          "label": "Closing the error messages:",
          "title": "Closing the error messages:"
        },
        "closeErrorMessages": {
          "false": "Automatic",
          "true": "Manual"
        },
        "edit": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "header": "Preferences",
        "language": {
          "label": "Interface language:",
          "title": "Language"
        },
        "message-info": {
          "error": {
            "save": "An error occurred whilst updating your preferences. Please try again later."
          },
          "info": {
            "save": "The preferences have been updated."
          }
        },
        "spellchecker-language": {
          "label": "Spellchecker language by default:",
          "title": "Spellchecker language by default"
        },
        "view": {
          "edit": "Modify"
        }
      },
      "side-bar": {
        "sign-out": "Sign out",
        "title": "Account settings"
      },
      "side-menu": {
        "personal-informations": "Personal information",
        "preferences": "Preferences",
        "subscriptions": "Notifications"
      },
      "subscriptions": {
        "edit": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "header": "My subscriptions",
        "message-info": {
          "error": {
            "loading": "An error occurred while loading subscriptions. Please try again later.",
            "save": "An error occurred while updating subscriptions. Please try again later."
          },
          "info": {
            "save": "Your subscriptions have been updated."
          }
        },
        "view": {
          "edit": "Edit",
          "state": {
            "disabled": "Disabled",
            "enabled": "Enabled"
          }
        }
      }
    },
    "profile.infos.header": "Personal preferences",
    "profile.infos.howto.messaging": "To return to your secure messaging system, click on the 'Profile' drop-down menu on the side menu and select 'Messaging'.",
    "profile.infos.howto.notifications": "To modify the reception of external notifications, click on Notifications in the side menu.",
    "profile.infos.howto.warnings": "To change the way warning messages work, click on Preferences in the side menu.",
    "profile.infos.introduction": "You are in the personal data management module.",
    "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "New message received",
    "profile.preferences.closeErrorMessage.label": "Closing error messages:",
    "profile.preferences.closeErrorMessage.title": "Closing error messages",
    "profile.preferences.closeErrorMessages.false": "Automatic",
    "profile.preferences.closeErrorMessages.true": "Manual",
    "profile.preferences.edit.cancel": "Cancel",
    "profile.preferences.edit.save": "Save",
    "profile.preferences.header": "Preferences",
    "profile.preferences.message-info.info.save": "The preferences have been updated.",
    "profile.preferences.view.edit": "Modify",
    "profile.side-bar.sign-out": "Log out",
    "profile.side-bar.title": "Account settings",
    "profile.side-menu.preferences": "Preferences",
    "profile.side-menu.subscriptions": "Notifications",
    "profile.subscriptions.edit.cancel": "Cancel",
    "profile.subscriptions.edit.save": "Save",
    "profile.subscriptions.message-info.error.loading": "An error occurred while loading notification subscriptions. Please try again later.",
    "profile.subscriptions.message-info.error.save": "An error occurred while saving notification subscriptions. Please try again later.",
    "profile.subscriptions.message-info.info.save": "Your notification subscriptions have been updated.",
    "profile.subscriptions.view.edit": "Edit",
    "profile.subscriptions.view.state.disabled": "Disabled",
    "profile.subscriptions.view.state.enabled": "Enabled",
    "projects": {
      "breadcrumb": {
        "choose-project": "Project Choice",
        "create": {
          "free": {
            "title": "Free Project"
          },
          "title": "New Project"
        },
        "edit": {
          "free": {
            "title": "Edit Project"
          }
        },
        "project-details": "Details",
        "projects-status": {
          "CLOSED": "Closed Projects",
          "IN_PROGRESS": "Current Projects"
        }
      },
      "create": {
        "project": {
          "buttons": {
            "cancel": "Cancel",
            "preview": "Preview",
            "save": "Save"
          },
          "fields": {
            "assigned-to": "Assigned to",
            "date-format": "MM/DD/YYYY",
            "description": "Description",
            "enddate": "End date",
            "nature": "Nature",
            "title": "Name"
          },
          "folder": {
            "no-data": "Project does not have any folders.",
            "status": {
              "CLOSED": "Closed",
              "INIT": "New",
              "OPENED": "Opened"
            },
            "title": "Folders"
          },
          "message-info": {
            "error": {
              "create": "An error occurred while creating the project, please try again later.",
              "duplicate-title": "The name already exists for this interlocutor.",
              "invalid-form": "The form is invalid, correct it and try again."
            },
            "info": {
              "create": "The project has been created successfully."
            }
          },
          "pop-up": {
            "not-saved-transition": "Your changes were not saved. Are you sure you want to leave this page ?"
          }
        }
      },
      "document": {
        "no-data": "This part has no document."
      },
      "edit": {
        "project": {
          "buttons": {
            "cancel": "Cancel",
            "preview": "Preview",
            "save": "Save"
          },
          "fields": {
            "assigned-to": "Assigned to",
            "date-format": "MM/DD/YYYY",
            "description": "Description",
            "enddate": "End date",
            "nature": "Nature",
            "title": "Name"
          },
          "folder": {
            "no-data": "Project does not have any folders.",
            "status": {
              "CLOSED": "Closed",
              "INIT": "New",
              "OPENED": "Opened"
            },
            "title": "Folders"
          },
          "message-info": {
            "error": {
              "duplicate-title": "The name already exists for this interlocutor.",
              "edit": "An error occurred while editing the project, please try again later.",
              "invalid-form": "The form is invalid, correct it and try again."
            },
            "info": {
              "edit": "The project has been updated successfully."
            }
          },
          "pop-up": {
            "not-saved-transition": "Your changes were not saved. Are you sure you want to leave this page ?"
          }
        }
      },
      "list": {
        "message-info": {
          "error": {
            "get-404": "An error occurred, the project was not found. Please retry later.",
            "unknown": "An error occurred on the page. Please retry later."
          }
        },
        "title": "Projects"
      },
      "new-project": {
        "buttons": {
          "choose-btn": "Choose"
        },
        "free": {
          "description": "In a free project, you can freely add documents.",
          "title": "New Free Project"
        }
      },
      "project": {
        "interlocutor-new": {
          "help": "Create a new project for {interlocutor}",
          "title": "New project for {interlocutor}"
        },
        "new": {
          "help": "Create a new project",
          "title": "New project"
        }
      },
      "project-group": {
        "no-data": "This group of project is empty."
      },
      "project-group-title": {
        "projects": "Projects owned by {interlocutorFullName}",
        "projects-shared": "Projects shared with {interlocutorFullName}"
      },
      "project-part": {
        "no-data": "This project has no part."
      },
      "search": {
        "filter": {
          "label": {
            "assignedTo": "Assigned to:",
            "interlocutor": "Interlocutor:"
          }
        },
        "legend": "Search",
        "message-info": {
          "error": {
            "project-load": "Failed to load the projects list"
          }
        }
      },
      "side-menu": {
        "closed-projects": "Closed",
        "in-progress-projects": "In progress",
        "interlocutor-menu-label": "Projects of {name}",
        "my-projects": "Mine",
        "projects": "Projects"
      },
      "signature": {
        "spinner": {
          "primary-error": "There was an error",
          "primary-success": "Document signed",
          "primary-wait": "Checking document signature...",
          "secondary-error": "The document was not signed",
          "secondary-success": "You may close this tab",
          "secondary-wait": "Please wait..."
        }
      },
      "title": {
        "empty": "Projects",
        "mine": "Projects"
      }
    },
    "shared": {
      "components": {
        "a11y": {
          "modals": {
            "alert-modal": {
              "closeButtonText": "Close",
              "closeButtonTitle": "Close the window"
            }
          }
        },
        "absence-table": {
          "absence-backend-error": "Error when retreiving absence date for an adviser",
          "granularity-dateformat-day": "DD/MM/YYYY",
          "granularity-dateformat-month": "MMMM YYYY",
          "granularity-dateformat-week": "Week {week} - {year}",
          "granularity-day": "day",
          "granularity-legend": "Display by",
          "granularity-month": "month",
          "granularity-week": "week",
          "grid-caption": "List of absences",
          "next-day-button-label": "Next day",
          "next-month-button-label": "Next month",
          "next-week-button-label": "Next week",
          "prev-day-button-label": "Previous day",
          "prev-month-button-label": "Previous month",
          "prev-week-button-label": "Previous week",
          "range-label": "From {start} to {end}",
          "range-label-backup": "Backup is {backup}",
          "today-button-label": "Today"
        },
        "charts": {
          "adviser-dashboard-chart": {
            "ACKNOWLEDGE": "Acknowledged conversations",
            "CLOSED": "Closed conversations",
            "CLOSED-AFTER-CRITICAL-DUE-DATE": "Closed conversations after critical due date",
            "CLOSED-BEFORE-CRITICAL-DUE-DATE": "Closed conversations before critical due date",
            "CLOSED-BEFORE-DUE-DATE": "Closed conversations before due date",
            "OPEN": "Opened conversations",
            "REOPEN": "Reopened conversations",
            "nb-conversations": "Number of conversations"
          }
        },
        "date": {
          "cancel": "Cancel",
          "dow": {
            "abbr": {
              "Fr": "Friday",
              "Mo": "Monday",
              "Sa": "Saturday",
              "Su": "Sunday",
              "Th": "Thursday",
              "Tu": "Tuedsay",
              "We": "Wednesday"
            },
            "text": {
              "Fr": "Fr",
              "Mo": "Mo",
              "Sa": "Sa",
              "Su": "Su",
              "Th": "Th",
              "Tu": "Tu",
              "We": "We"
            }
          },
          "format": "DD/MM/YYYY",
          "formatWithTime": "DD/MM/YYYY HH:mm",
          "month": {
            "april": "April",
            "august": "August",
            "december": "December",
            "february": "February",
            "january": "January",
            "july": "July",
            "june": "June",
            "march": "March",
            "may": "May",
            "november": "November",
            "october": "October",
            "september": "September"
          },
          "month-year": "Month Year",
          "next-month": "Next month",
          "next-year": "Next year",
          "ok": "OK",
          "picker-button-alt": "Choose a date",
          "placeholderFormat": "DD/MM/YYYY",
          "placeholderFormatWithTime": "DD/MM/YYYY hh:mm",
          "previous-month": "Previous month",
          "previous-year": "Previous year"
        },
        "dropdown": {
          "default-button-title": "Actions"
        },
        "form-component": {
          "form-fields-required-label": "All fields marked with an * are required."
        },
        "form-group-component": {
          "missing-text": "This field is required."
        },
        "modals": {
          "audio-preview": {
            "loading": "Loading...",
            "modal-title": "Audio play"
          },
          "document-preview": {
            "error-message": "An error occurred while downloading the document, please try again later.",
            "modal-title": "Document preview",
            "select-page": "Select page",
            "upload-by": "Uploaded by {actor} on {date}",
            "zoom-in": "Zoom in",
            "zoom-out": "Zoom out"
          },
          "image-preview": {
            "error-message": "An error occurred while downloading the picture, please try again later.",
            "loading-message": "Loading, please wait...",
            "modal-title": "File preview",
            "upload-by": "Uploaded by",
            "upload-date": "Uploaded on",
            "zoom-in": "Zoom in",
            "zoom-out": "Zoom out"
          },
          "modal-component": {
            "modal-cancel": "Cancel",
            "modal-confirm": "Confirm"
          },
          "title": {
            "closeButton": "Close this message"
          }
        },
        "multi-selectors": {
          "cc-multi-selector": {
            "error": "An error occurred during the search.",
            "error-external-on-secured": "Cannot add external email on secured email channel",
            "error-secured-on-external": "Cannot add secured email on secured email channel",
            "errors": {
              "no-account-found-external": "No account with external mail '{email}' has been found",
              "no-account-found-secured": "No account with secured mail '{email}' has been found"
            },
            "loading": "Loading result(s)...",
            "no-results": "No match",
            "type": {
              "actor": "Advisor: ",
              "entity": "Entity: ",
              "external": "Ext.",
              "interlocutor": "",
              "trustedInterlocutor": "Trusted interlocutor"
            }
          },
          "tags-multi-selector": {
            "error": "An error occurred during the search.",
            "loading": "Loading result(s)...",
            "no-results": "No match"
          }
        },
        "panels": {
          "showLess": "Show less",
          "showMore": "Show more"
        },
        "presets-selector": {
          "title": "Preset"
        },
        "rating": {
          "message-info": {
            "error": {
              "rate": "An error occurred while saving rate, please try again later."
            },
            "info": {
              "rate": "The rate has been successfully saved."
            }
          },
          "title": "Do you want to change your grade?",
          "title-new": "Are you satisfied by this answer?"
        },
        "result-list": {
          "results": {
            "error": "An error occurred while loading, please try again later.",
            "loading": "Loading...",
            "none": "No result available",
            "wait": "Please wait"
          }
        },
        "text-editor": {
          "actions-list": {
            "f-ai": {
              "choose-template": "Choose a reply template:",
              "ko-declaration": "Declare the templates as not satisfying:",
              "show-templates": "Show semantic analysis reply templates"
            },
            "f-aligncenter": "Align center",
            "f-alignleft": "Align left",
            "f-alignright": "Align right",
            "f-attachments": {
              "help": "Copy attachments"
            },
            "f-bold": "Bold",
            "f-color": {
              "help": "Color",
              "label": "Change text color"
            },
            "f-font": {
              "help": "Font",
              "label": "Choose font family"
            },
            "f-gallery": "Add image",
            "f-italic": "Italic",
            "f-link": {
              "help": "Add link",
              "ok": "OK",
              "url": "URL"
            },
            "f-list": "Switch from/to list",
            "f-signature": {
              "apply": "Apply signature",
              "choose": "Choose signature",
              "label": "Signature..."
            },
            "f-size": {
              "help": "Font size",
              "label": "Choose font size"
            },
            "f-spellCheck": {
              "check-spelling": "Check spelling",
              "choose-language": "Choose language",
              "ignore": "Ignore",
              "ignore-all": "Ignore all"
            },
            "f-underline": "Underline",
            "f-upload": {
              "help": "Add an attachment"
            }
          },
          "initial-attachments": {
            "copy-attachment": "Copy an attachment",
            "message-info": {
              "error": {
                "copy-attachment": "An error occurred while copying the attachment, please try again later."
              },
              "info": {
                "file-removed": "The attachment has been removed.",
                "file-uploaded": "The attachment has been copied."
              }
            }
          },
          "inline-images": {
            "message-info": {
              "error": {
                "empty-file": "You can't upload empty files. Please try again with a valid file.",
                "preview": "An error occurred while downloading the picture, please try again later."
              }
            }
          },
          "message-composer-attachments": {
            "delete": "Delete this attachment",
            "download": "Download this attachment",
            "dropdown-button-title": "Actions",
            "message-info": {
              "error": {
                "bad-content-type": "The type of attachment is not allowed.",
                "download": "An error occurred while downloading the attachment, please try again later.",
                "empty-file": "You can't upload empty files. Please try again with a valid file.",
                "failed-to-upload": "Failed to upload the attachment. Please try again later",
                "file-name-already-used": "An attachment with the same name already exists.",
                "maxAttachmentReached": "You have reached the maximum number of attachments allowed.",
                "message-size-limit": "You have exceeded the max size of message attachments.",
                "preview": "An error occurred while downloading the picture, please try again later."
              },
              "info": {
                "file-uploaded": "The attachment has been uploaded",
                "file-uploading": "Upload in progress ..."
              }
            },
            "preview": "Preview this attachment"
          }
        }
      },
      "file-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "You can't upload empty files. Please try again with a valid file.",
          "NOT_ALLOWED_CONTENT_TYPE": "The type of attachment is not allowed.",
          "addFile": "The file has not been uploaded. Please try again later.",
          "contentType": "The type of attachment is not allowed.",
          "length": "The size of the attachment is greater than the maximum size allowed."
        }
      },
      "helpers": {
        "date-short-view": {
          "today": "Today, {hour}"
        }
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Adviser }  CLIENT {Interlocutor } other {}}{gender, select, MR {Mr }  MME {Ms } other {}}{lastname} {firstname}",
        "unknown-person": "Unknown person"
      },
      "inline-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "You can't upload empty files. Please try again with a valid file.",
          "length": "The size of the inline image is greater than the maximum size allowed."
        }
      },
      "numeric-unit": {
        "billion": "G",
        "million": "M",
        "thousand": "K"
      },
      "pagination": "pagination",
      "skip-link": "Navigation (Skip menu)",
      "spellchecker": {
        "languages": {
          "de": "German",
          "en": "English",
          "fr": "French",
          "nl": "Dutch",
          "ru": "Russian"
        },
        "message-info": {
          "error": {
            "unknown": "An error occurred."
          }
        }
      },
      "theme": {
        "criticalDueDateDelay": {
          "error": {
            "message": "This field must be greater than Late in"
          }
        }
      },
      "treeview": {
        "button": {
          "list.label": "Show the result list only",
          "search.label": "Search",
          "select.default.label": "Choisir",
          "tree.label": "Show the complete tree"
        },
        "message": {
          "noresult": "Search returned no result"
        }
      }
    },
    "shared.components.a11y.modals.alert-modal.closeButtonText": "Close",
    "shared.components.a11y.modals.alert-modal.closeButtonTitle": "Close the window",
    "shared.components.dropdown.default-button-title": "Actions",
    "shared.components.form-component.form-fields-required-label": "All fields marked with an * are required.",
    "shared.components.form-group-component.missing-text": "This field is required.",
    "shared.components.modals.document-preview.error-message": "An error occurred while loading the picture. Please try again later.",
    "shared.components.modals.document-preview.modal-title": "Document preview",
    "shared.components.modals.document-preview.select-page": "Select page",
    "shared.components.modals.document-preview.upload-by": "Uploaded by {actor} on {date}",
    "shared.components.modals.document-preview.zoom-in": "Zoom in",
    "shared.components.modals.document-preview.zoom-out": "Zoom out",
    "shared.components.modals.image-preview.error-message": "An error occurred while loading the picture. Please try again later.",
    "shared.components.modals.image-preview.loading-message": "Loading",
    "shared.components.modals.image-preview.modal-title": "Preview",
    "shared.components.modals.image-preview.upload-by": "Uploaded by",
    "shared.components.modals.image-preview.upload-date": "Uploaded on",
    "shared.components.modals.image-preview.zoom-in": "Zoom in",
    "shared.components.modals.image-preview.zoom-out": "Zoom out",
    "shared.components.modals.modal-component.modal-cancel": "Cancel",
    "shared.components.modals.modal-component.modal-confirm": "Confirm",
    "shared.components.modals.title.closeButton": "Close this message",
    "shared.components.result-list.results.error": "An error occurred when loading the page. Please check and try again later.",
    "shared.components.result-list.results.loading": "Loading",
    "shared.components.result-list.results.none": "No result",
    "shared.components.result-list.results.wait": "Please wait",
    "shared.components.text-editor.actions-list.f-aligncenter": "Align center",
    "shared.components.text-editor.actions-list.f-alignleft": "Align left",
    "shared.components.text-editor.actions-list.f-alignright": "Align right",
    "shared.components.text-editor.actions-list.f-bold": "Bold",
    "shared.components.text-editor.actions-list.f-color.help": "Color",
    "shared.components.text-editor.actions-list.f-color.label": "Change text color",
    "shared.components.text-editor.actions-list.f-font.help": "Font",
    "shared.components.text-editor.actions-list.f-font.label": "Choose font family",
    "shared.components.text-editor.actions-list.f-gallery": "Add image",
    "shared.components.text-editor.actions-list.f-italic": "Italic",
    "shared.components.text-editor.actions-list.f-link.help": "Add link",
    "shared.components.text-editor.actions-list.f-link.ok": "OK",
    "shared.components.text-editor.actions-list.f-link.url": "URL",
    "shared.components.text-editor.actions-list.f-list": "Switch from/to list",
    "shared.components.text-editor.actions-list.f-size.help": "Font size",
    "shared.components.text-editor.actions-list.f-size.label": "Choose font size",
    "shared.components.text-editor.actions-list.f-spellCheck.check-spelling": "Check spelling",
    "shared.components.text-editor.actions-list.f-spellCheck.choose-language": "Choose language",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore": "Ignore",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore-all": "Ignore all",
    "shared.components.text-editor.actions-list.f-underline": "Underline",
    "shared.components.text-editor.actions-list.f-upload.help": "Add an attachment",
    "shared.components.text-editor.message-composer-attachments.delete": "Delete this attachment",
    "shared.components.text-editor.message-composer-attachments.download": "Download this attachment",
    "shared.components.text-editor.message-composer-attachments.dropdown-button-title": "Actions",
    "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type": "The type of attachment is not allowed.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.download": "Communication problem, upload failed. Please try again later.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file": "Communication problem, upload failed. Please try again later.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload": "Communication problem, upload failed. Please try again later.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used": "You can't upload empty files. Please try again with a valid file.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached": "The maximum number of attachments allowed has been reached.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit": "Attachments exceed size limit.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.preview": "An error occurred while loading the picture, please try again later.",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded": "The attachment has been saved",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading": "Saving in progress...",
    "shared.components.text-editor.message-composer-attachments.preview": "View the attachment",
    "shared.file-uploader.error.EMPTY_ATTACHMENT": "You can't upload empty files. Please try again with a valid file.",
    "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE": "The type of attachment is not allowed.",
    "shared.file-uploader.error.addFile": "The file has not been uploaded. Please try again later.",
    "shared.file-uploader.error.contentType": "The type of attachment is not allowed.",
    "shared.file-uploader.error.length": "File exceeds size limit. ",
    "shared.helpers.date-short-view.today": "Today, {hour}",
    "shared.numeric-unit.billion": "G",
    "shared.numeric-unit.million": "M",
    "shared.numeric-unit.thousand": "K",
    "shared.pagination": "pagination",
    "shared.skip-link": "Go to main page (Skip menu)",
    "side-panel": {
      "accessibility": {
        "buttons": {
          "close": {
            "title": "Close"
          }
        },
        "contrast": {
          "enhanced": "Enhanced",
          "standard": "Standard"
        },
        "disparities": "Disparities",
        "message-info": {
          "error": {
            "unknown": "An error occurred while changing the accessibility contrast, please try again later."
          }
        },
        "title": "Accessibility"
      },
      "burger": {
        "label": "Show / Hide the Menu"
      },
      "hide-menu": "Hide side menu",
      "logo": {
        "by": "by",
        "ti": "Trusted Interactions",
        "worldline": "Worldline"
      },
      "logo-alt": "WL Trusted Interactions",
      "module": {
        "title": "Module"
      },
      "settings": {
        "myprofile": "Settings",
        "name": "Settings",
        "preferences": "Preferences"
      },
      "show-menu": "Show side menu",
      "toggle-title": "Opens/Closes the menu"
    },
    "side-panel.burger.label": "Show / Hide menu",
    "side-panel.hide-menu": "Hide side menu",
    "side-panel.show-menu": "Show side menu",
    "side-panel.toggle-title": "Opens/Closes menu",
    "sso-error.text": "An error has occurred during connection. Close this page and try again from your personal space.",
    "sso-error.title": "",
    "topbar": {
      "burger": {
        "label": "Show / Hide the Menu"
      },
      "interlocutors": {
        "myself": "My account"
      },
      "message-info": {
        "log-out-confirmation": "Do you want to sign out? All the unsaved data will be lost."
      },
      "module": {
        "administration": "Administration",
        "interactions": "Messages",
        "profile": "Profile",
        "projects": "Projects"
      },
      "nav": {
        "label": "Multi module topbar"
      },
      "organisation": {
        "name": "equensWorldline"
      },
      "settings": {
        "profile": "Parameters",
        "sign-out": "Sign Out"
      }
    },
    "topbar.message-info.log-out-confirmation": "Are you sure you want to log out?",
    "topbar.module.interactions": "Messages",
    "topbar.module.profile": "Profile",
    "topbar.nav.label": "Multi item topbar",
    "topbar.organisation.name": "Member secure messaging"
  };
  _exports.default = _default;
});