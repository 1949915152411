define("ticust-s2h-web-application/templates/app/ticust-web-module-profile/informations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "x0GQKAKY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ti-web-shared/a11y/focusable\",null,null,{\"statements\":[[0,\"  \"],[7,\"h2\"],[11,\"tabindex\",\"0\"],[9],[1,[27,\"t\",[\"profile.infos.header\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"p\"],[11,\"style\",\"margin-bottom:0.5em\"],[9],[1,[27,\"t\",[\"profile.infos.introduction\"],null],false],[10],[0,\"\\n\"],[7,\"p\"],[11,\"style\",\"margin-bottom:0.5em\"],[9],[1,[27,\"t\",[\"profile.infos.howto.warnings\"],null],false],[10],[0,\"\\n\"],[7,\"p\"],[11,\"style\",\"margin-bottom:0.5em\"],[9],[1,[27,\"t\",[\"profile.infos.howto.notifications\"],null],false],[10],[0,\"\\n\"],[7,\"p\"],[11,\"style\",\"margin-bottom:0.5em\"],[9],[1,[27,\"t\",[\"profile.infos.howto.messaging\"],null],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ticust-s2h-web-application/templates/app/ticust-web-module-profile/informations.hbs"
    }
  });
  _exports.default = _default;
});