define("ticust-s2h-web-application/serializers/simple-part-text", ["exports", "ticust-web-common/serializers/simple-part-text"], function (_exports, _simplePartText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _simplePartText.default;
    }
  });
});