define("ticust-s2h-web-application/services/sdh-service", ["exports", "ember-uuid", "moment", "lodash"], function (_exports, _emberUuid, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var STATUS_INIT = 'init';
  var STATUS_LOADED = 'loaded';
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    // Service status : init, loaded
    status: STATUS_INIT,
    // S2H context
    contextPromise: undefined,
    context: undefined,
    load: function load() {
      var _this = this;
      this.set('status', STATUS_LOADED);
      this.tiLogger.info('Load S2H context');
      return this.checkExistingContext().finally(function () {
        return _this.initContext();
      });
    },
    initContext: function initContext() {
      var _this2 = this;
      if (!this.session.data || _lodash.default.isEmpty(this.session.data.authenticated)) {
        // New OTU given, call WS for Context
        this.tiLogger.debug('Get S2H context from backend endpoint');
        this.set('contextPromise', this.getSdhContext());
      } else if (this.context === undefined) {
        // Otherwise load previous context from local storage
        this.tiLogger.debug('Get S2H Context from local storage');
        this.set('contextPromise', new Ember.RSVP.Promise(function (resolve, reject) {
          var savedContext = _this2.saveLocalContext();
          if (savedContext) {
            resolve(savedContext);
          } else {
            reject(new Error('Cannot load'));
          }
        }));
      }
      if (this.contextPromise) {
        return this.contextPromise.catch(function () {
          _this2.tiLogger.warn('Failed getting context,' + ' keepalive will not be called');
        });
      }
    },
    checkExistingContext: function checkExistingContext() {
      var _this3 = this;
      if (this.get('session.isAuthenticated')) {
        return this.ajax.raw('/api/customer/authentication/sso/has-context', {
          method: 'GET',
          headers: this.identity.ajaxHeaders
        }).then(function () {
          _this3.session.invalidate('authenticator:sdh-token-auth');
        }).catch(function () {
          _this3.tiLogger.debug("No context found");
        });
      }
      return Ember.RSVP.Promise.resolve();
    },
    saveLocalContext: function saveLocalContext() {
      var context = _lodash.default.clone(this.session.data ? this.session.data.authenticated : undefined);
      if (context) {
        delete context.authenticator;
        this.set('context', context);
      }
      return context;
    },
    getSdhContext: function getSdhContext() {
      var _this4 = this;
      // Set session ID
      var sessionId = _emberUuid.default.v4();
      var time = (0, _moment.default)(new Date().getTime()).format('HHmmss');
      this.session.set('data.sessionId', sessionId + '-' + time);

      // save JWT token and S2H context into local storage

      return this.session.authenticate('authenticator:sdh-token-auth').then(function () {
        var context = _this4.saveLocalContext();
        return context;
      }).catch(function (_ref) {
        var response = _ref.response,
          jqXHR = _ref.jqXHR,
          payload = _ref.payload;
        _this4.tiLogger.error('Failed S2H token Auth : HTTP ' + jqXHR.status + ' ' + response.message);
        // Chain catch
        var error = {
          response: response,
          jqXHR: jqXHR,
          payload: payload
        };
        throw error;
      });
    },
    dispatch: function dispatch(route) {
      // depending on context, go to different parts of the app.
      var context = this.context;
      if (!context) {
        this.tiLogger.error('unknow context, go to root');
        route.transitionTo('app');
        return;
      }

      // mark context as dispatched
      context.dispatched = true;
      if (context.target === 'LIST_CONVERSATIONS') {
        this._redirectToConversations(route);
      } else if (context.target === 'CONVERSATION') {
        this._redirectToConversation(route, context);
      } else if (context.target === 'NEW_CONVERSATION') {
        this._redirectToNewConversation(route, context);
      } else if (context.target === 'INTERLOCUTOR') {
        this._redirectToInterlocutor(route, context);
      } else if (context.target === 'LOGOUT') {
        this.session.invalidate('authenticator:sdh-token-auth');
        route.transitionTo('ticust-web-module-authentication.logout');
      } else {
        this.tiLogger.error('unknow cible ' + context.cible + ' go to root');
        route.transitionTo('app');
      }
    },
    _redirectToInterlocutor: function _redirectToInterlocutor(route, context) {
      var _this5 = this;
      if (context.contactEmail) {
        this._getInterlocutor(context.contactEmail).then(function (interlocutor) {
          if (interlocutor) {
            _this5.tiLogger.debug('Redirecting to interlocutor ' + interlocutor.id);
            route.transitionTo('app.ti-web-module-interactions.perimeters.perimeter.interlocutors.interlocutor', 'all', interlocutor.id);
          } else {
            _this5.tiLogger.debug('Interlocutor not found');
            route.transitionTo('app');
          }
        });
      } else {
        this.tiLogger.debug('Missing contactEmail parameter');
        route.transitionTo('app');
      }
    },
    _redirectToNewConversation: function _redirectToNewConversation(route, context) {
      var _this6 = this;
      if (context.contactEmail) {
        this._getInterlocutor(context.contactEmail).then(function (interlocutor) {
          if (interlocutor) {
            _this6.tiLogger.debug('Redirecting to new conversation with interlocutor ' + interlocutor.id);
            route.transitionTo('app.ti-web-module-interactions.perimeters.perimeter.conversations.create', 'all', {
              queryParams: {
                interlocutor: interlocutor.id
              }
            });
          } else {
            _this6.tiLogger.debug('Interlocutor for new conversation not found');
            route.transitionTo('app');
          }
        });
      } else {
        this.tiLogger.debug('Missing contactEmail parameter');
        route.transitionTo('app');
      }
    },
    _redirectToConversation: function _redirectToConversation(route, context) {
      var _this7 = this;
      if (context.conversationId) {
        this.store.find('conversation', context.conversationId).then(function () {
          route.transitionTo('app.ti-web-module-interactions.perimeters.perimeter.conversations.conversation', 'all', context.conversationId);
        }).catch(function () {
          _this7.tiLogger.debug('Conversation with id ' + context.conversationId + ' not found');
          _this7._redirectToConversations(route);
        });
      } else {
        this.tiLogger.debug('Missing conversationId parameter');
        this._redirectToConversations(route);
      }
    },
    _redirectToConversations: function _redirectToConversations(route) {
      route.transitionTo('app.ti-web-module-interactions.perimeters.perimeter.conversations.index', 'all', {
        queryParams: {
          search: true
        }
      });
    },
    _getInterlocutor: function _getInterlocutor(contactEmail) {
      var _this8 = this;
      this.tiLogger.debug('Search contact with id ' + contactEmail);
      return this._searchInterlocutor(contactEmail, 'externalEmailAddress').then(function (interlocutor) {
        return interlocutor ? interlocutor : _this8._searchInterlocutor(contactEmail, 'internalEmailAddress');
      });
    },
    _searchInterlocutor: function _searchInterlocutor(contactEmail, emailField) {
      return this.store.query('interlocutor', _defineProperty({}, emailField, contactEmail)).then(function (result) {
        return result.get('firstObject');
      });
    }
  });
  _exports.default = _default;
});