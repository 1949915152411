define("ticust-s2h-web-application/initializers/components/ticust-web-module-interactions/conversation/conversation-message-header", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-header", "moment"], function (_exports, _conversationMessageHeader, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-header',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationMessageHeader.default.reopen({
    identity: Ember.inject.service('identity-service'),
    interlocutor: Ember.computed('identity', function () {
      return this.get('identity.selectedInterlocutor');
    })
  });
});