define("ticust-s2h-web-application/services/idle-user", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IDLE_TIMEOUT = 3000;
  var _default = _userIdle.default.extend({
    IDLE_TIMEOUT: 600000 // ten minutes
  });
  _exports.default = _default;
});