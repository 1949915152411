define("ticust-s2h-web-application/templates/components/ticust-web-module-authentication/auth-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "S8Z8Su2Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\"],[11,\"class\",\"login\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"img\"],[12,\"src\",[27,\"path\",[\"assets/msh.png\"],null]],[12,\"alt\",[27,\"t\",[\"side-panel.logo-alt\"],null]],[9],[10],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"main\"],[9],[0,\"\\n    \"],[7,\"header\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ticust-s2h-web-application/templates/components/ticust-web-module-authentication/auth-wrapper.hbs"
    }
  });
  _exports.default = _default;
});