define("ticust-s2h-web-application/components/ticust-web-module-interactions/interlocutors/interlocutor/conversations/theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    path: "",
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setCheckedStatusOfThemesAndFolders();
    },
    setCheckedStatusOfThemesAndFolders: function setCheckedStatusOfThemesAndFolders() {
      if (this.get("conversation.subject")) {
        // Especialy for draft case (subject is already set and must be shown) :
        var subject = this.get("conversation.subject");
        var theme = this.get("theme");
        this.set("theme.checked", this.mustAppearChecked(theme, subject));
      } else {
        // Create a new convesation case : safe uncheck
        this.set("theme.checked", false);
      }
    },
    mustAppearChecked: function mustAppearChecked(theme, subject) {
      var _this = this;
      if (theme.children && theme.children.length != 0) {
        var _checked = theme.children.some(function (element) {
          return _this.mustAppearChecked(element, subject);
        });
        if (_checked) {
          return _checked;
        }
      } else {
        if (theme.libelle_complet && theme.libelle_complet == subject) {
          return true;
        } else return false;
      }
    },
    actions: {
      selection: function selection(theme) {
        if (this.get('selection')) {
          this.get('selection')(theme);
        }
      },
      keyPress: function keyPress(e) {
        if (e.keyCode === 13) {
          // Prevent default submit otherwise submit gets called twice.
          e.preventDefault();
          document.getElementById(e.target.parentElement.firstElementChild.id).click();
        }
      }
    }
  });
  _exports.default = _default;
});