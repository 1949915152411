define("ticust-s2h-web-application/routes/ticust-web-module-authentication/sso-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    sdhService: Ember.inject.service(),
    session: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    beforeModel: function beforeModel() {
      // transition to requested page.
      this.tiLogger.debug('SSO login');
      this._loadContextAndDispatch(this.sdhService.get('contextPromise'));
    },
    _loadContextAndDispatch: function _loadContextAndDispatch(promise) {
      var _this = this;
      var sdhService = this.sdhService;
      promise.then(function (context) {
        if (context.dispatched) {
          _this.tiLogger.debug('Current context already dispatched, refreshing from backend');
          _this._loadContextAndDispatch(sdhService.getSdhContext());
        } else {
          sdhService.dispatch(_this);
        }
      }).catch(function () {
        sdhService.dispatch(_this);
      });
    }
  });
  _exports.default = _default;
});