define("ticust-s2h-web-application/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XcLn1IWM",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"toastr-a11y-container\"],[11,\"aria-live\",\"assertive\"],[11,\"role\",\"alert\"],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"modal-container\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ticust-s2h-web-application/templates/application.hbs"
    }
  });
  _exports.default = _default;
});