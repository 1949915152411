define("ticust-s2h-web-application/templates/app/ticust-web-module-profile/subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Aao2I6SB",
    "block": "{\"symbols\":[\"subscription\"],\"statements\":[[4,\"ti-web-shared/a11y/focusable\",null,null,{\"statements\":[[0,\"  \"],[7,\"h2\"],[11,\"tabindex\",\"0\"],[9],[1,[27,\"t\",[\"profile.subscriptions.header\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"page-content-main content\"],[9],[0,\"\\n  \"],[7,\"form\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"subscriptionsSorted\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"notification\",\"id\"]],\"NEW_MESSAGE_INTERLOCUTOR_RECEIVED\"],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"ticust-web-module-profile/subscription-editor\",null,[[\"subscription\",\"readonly\"],[[22,1,[]],[23,[\"isInViewMode\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"displayEditButton\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"buttons-bar\"],[9],[0,\"\\n      \"],[7,\"button\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"profile.subscriptions.view.edit\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"edit\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"not\",[[23,[\"isInViewMode\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"buttons-bar\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"form-submit\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"profile.subscriptions.edit.save\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"form-reset\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"profile.subscriptions.edit.cancel\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[21,\"ti-web-shared/a11y/focusable\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ticust-s2h-web-application/templates/app/ticust-web-module-profile/subscriptions.hbs"
    }
  });
  _exports.default = _default;
});