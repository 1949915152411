define("ticust-s2h-web-application/models/simple-part-s3", ["exports", "ticust-web-common/models/simple-part-s3"], function (_exports, _simplePartS) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _simplePartS.default;
    }
  });
});