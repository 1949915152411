define("ticust-s2h-web-application/initializers/ti-web-shared/side-panel/side-panel", ["exports", "ti-web-shared/components/ti-web-shared/side-panel/side-panel", "ticust-s2h-web-application/templates/components/ti-web-shared/side-panel/side-panel"], function (_exports, _sidePanel, _sidePanel2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
  _sidePanel.default.reopen({
    layout: _sidePanel2.default
  });
});