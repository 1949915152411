define("ticust-s2h-web-application/components/ticust-web-module-profile/subscription-editor", ["exports", "ticust-web-module-profile/components/ticust-web-module-profile/subscription-editor"], function (_exports, _subscriptionEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _subscriptionEditor.default.extend({
    availableChannels: Ember.computed('subscription.notification.{hasChannelSms,hasChannelExternalMail}', function () {
      var availableChannels = [];
      if (this.get('subscription.notification.hasChannelExternalMail')) {
        availableChannels.pushObject(this.get('externalMailChannel'));
      }
      return availableChannels;
    }),
    actions: {
      channelChanged: function channelChanged(channel) {
        if (channel) {
          this.set('subscription.channel', channel.id);
        } else {
          // Default channel is EXTERNAL_MAIL
          this.set('subscription.channel', 'EXTERNAL_MAIL');
        }
      }
    }
  });
  _exports.default = _default;
});