define("ticust-s2h-web-application/translations/it-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "common-projects.selectors.actor-selector.error": "Si è verificato un errore, la ricerca non è stata eseguita. Riprova più tardi.",
    "common.application.title": "Trusted Interactions",
    "common.file-upload.error.addFile": "Si è verificato un errore, l'allegato non è stato aggiunto. Riprova più tardi.",
    "common.file-upload.error.contentType": "Il tipo di allegato non è autorizzato.",
    "common.file-upload.error.length": "Il file che desideri scaricare è troppo voluminoso.",
    "common.message-info.error.403": "Non hai i diritti per visualizzare questa pagina.",
    "common.message-info.error.unknown": "Si è verificato un errore. Riprova più tardi.",
    "common.page-error-message": "Si è verificato un errore. Riprova più tardi.",
    "common.pagination-component.currentPage": "Pagina corrente",
    "common.pagination-component.firstPage": "Prima pagina",
    "common.pagination-component.lastPage": "Ultima pagina",
    "common.pagination-component.nextPage": "Pagina seguente",
    "common.pagination-component.page": "Pagina",
    "common.pagination-component.previousPage": "Pagina precedente",
    "common.result-list.results.error": "Si è verificato un errore durante il caricamento della pagina. Verifica e riprova.",
    "common.result-list.results.none": "Nessun risultato",
    "common.result-list.sort.by": "Ordina per",
    "common.sidemenu.label": "Menu",
    "common.sidemenu.minimize": "Chiudi il menu",
    "common.sidemenu.pulldown": "Apri il menu",
    "common.sidemenu.search": "Cerca",
    "common.spellchecker.languages.de": "Tedesco",
    "common.spellchecker.languages.en": "Inglese",
    "common.spellchecker.languages.es": "Spagnolo",
    "common.spellchecker.languages.fr": "Francese",
    "common.spellchecker.languages.it": "Italiano",
    "common.spellchecker.languages.nl": "Neerlandese",
    "common.spellchecker.languages.pt": "Portoghese",
    "common.spellchecker.message-info.error.unknown": "Si è verificato un errore.",
    "common.variables.notificationCommon.EVENT_DATE": "EVENT_DATE",
    "common.variables.notificationCommon.RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
    "common.variables.notificationCommon.RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
    "common.variables.notificationCommon.RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME",
    "disconnection.text.explanation": "La sessione di messaggistica protetta è terminata. Ora puoi chiudere la pagina.",
    "disconnection.text.reminder": "Se desideri accedere nuovamente al sistema di messaggistica protetta, clicca sul link di accesso presente nel tuo spazio assicurato protetto o nella tua app mobile.",
    "disconnection.title": "Chiudi la sessione di messaggistica protetta",
    "interactions.conversation-action-bar-component.actions.archive.display": "Archivia",
    "interactions.conversation-action-bar-component.actions.archive.title": "Archivia tutte le conversazioni selezionate",
    "interactions.conversation-action-bar-component.actions.categorize.display": "Classifica in ...",
    "interactions.conversation-action-bar-component.actions.categorize.labelSelected": "Classifica tutte le conversazioni selezionate in {label}",
    "interactions.conversation-action-bar-component.actions.categorize.title": "Classifica tutte le conversazioni selezionate in ...",
    "interactions.conversation-action-bar-component.actions.categorize.toggle": "Apri il menu “Classifica in ...”",
    "interactions.conversation-action-bar-component.actions.checkAll": "Seleziona tutto",
    "interactions.conversation-action-bar-component.actions.delete.display": "Cancella",
    "interactions.conversation-action-bar-component.actions.delete.title": "Elimina tutte le conversazioni selezionate",
    "interactions.conversation-action-bar-component.actions.markAs.display": "Segna come ...",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display": "Segna come letta",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title": "Segna tutte le conversazioni selezionate come lette",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display": "Segna come non letta",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title": "Segna tutte le conversazioni selezionate come non lette.",
    "interactions.conversation-action-bar-component.actions.markAs.toggle": "Apri il menu “Segna come ...”",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.display": "Elimina dal cestino",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.title": "Elimina dal cestino tutte le conversazioni selezionate",
    "interactions.conversation-action-bar-component.actions.restore.display": "Recupera",
    "interactions.conversation-action-bar-component.actions.restore.title": "Recupera tutte le conversazioni selezionate",
    "interactions.conversation-action-bar-component.actions.unarchive.display": "Ripristina",
    "interactions.conversation-action-bar-component.actions.unarchive.title": "Ripristina tutte le conversazioni selezionate",
    "interactions.conversation-action-bar-component.actions.uncheckAll": "Deseleziona tutto",
    "interactions.conversation-action-bar-component.search.button": "Filtra le conversazioni con il testo inserito",
    "interactions.conversation-action-bar-component.search.input": "Inserisci il testo da cercare",
    "interactions.conversation-action-bar-component.sort.sortBy": "Ordina per:",
    "interactions.conversation-line-component.buttons.check": "Seleziona la conversazione.",
    "interactions.conversation-line-component.buttons.uncheck": "Deseleziona la conversazione.",
    "interactions.conversation-line-component.conversationSubject": "Oggetto della conversazione.",
    "interactions.conversation-line-component.hasAttachments": "La conversazione ha almeno un messaggio con un allegato.",
    "interactions.conversation-line-component.isRead.read": "L’ultimo messaggio della conversazione è letto.",
    "interactions.conversation-line-component.isRead.unread": "L’ultimo messaggio della conversazione non è letto.",
    "interactions.conversation-line-component.lastMessageReceivedDate": "Data dell’ultimo messaggio ricevuto.",
    "interactions.conversation-line-component.lastMessageReceivedcontent": "Contenuto dell’ultimo messaggio ricevuto.",
    "interactions.conversation-line-component.lastMessageStatus.DRAFT": "L’ultimo messaggio della conversazione è una bozza.",
    "interactions.conversation-line-component.lastMessageStatus.RECEIVED": "L'ultimo messaggio della conversazione è di un consulente.",
    "interactions.conversation-line-component.lastMessageStatus.SENT": "L’ultimo messaggio della conversazione è tuo.",
    "interactions.conversation-message-attachment.attachment": "Allegati",
    "interactions.conversation-message-attachment.download": "Scarica",
    "interactions.conversation-message-attachment.dropdown-button-title": "Azioni",
    "interactions.conversation-message-attachment.preview": "Vedi anteprima",
    "interactions.conversation-message.body.buttons.show-less.text": "--- vedi il messaggio parziale ---",
    "interactions.conversation-message.body.buttons.show-less.title": "Visualizza il messaggio parziale",
    "interactions.conversation-message.body.buttons.show-more.text": "--- vedi il messaggio completo ---",
    "interactions.conversation-message.body.buttons.show-more.title": "Visualizza il messaggio completo",
    "interactions.conversation-message.header.expand-button.label-expand": "Espandi l’intestazione",
    "interactions.conversation-message.header.expand-button.label-shrink": "Comprimi l’intestazione",
    "interactions.conversation-message.header.expanded.channel": "Canale:",
    "interactions.conversation-message.header.expanded.channels.MAIL": "E-mail",
    "interactions.conversation-message.header.expanded.from": "Da:",
    "interactions.conversation-message.header.expanded.receiveDate": "Ricevuto il:",
    "interactions.conversation-message.header.expanded.sendDate": "Inviato il:",
    "interactions.conversation-message.header.expanded.subject": "Oggetto:",
    "interactions.conversation-message.header.expanded.tags": "Etichette:",
    "interactions.conversation-message.header.expanded.to": "A:",
    "interactions.conversation-message.header.expanded.virus-scan": "Scansione antivirus:",
    "interactions.conversation-message.header.shrunken.from": "Da:",
    "interactions.conversation-message.header.shrunken.to": "A:",
    "interactions.conversation-message.header.title": "Informazioni sul messaggio",
    "interactions.conversation.action-bar.archive": "Archivia la conversazione",
    "interactions.conversation.action-bar.categorize": "Classifica la conversazione",
    "interactions.conversation.action-bar.delete": "Elimina la conversazione.",
    "interactions.conversation.action-bar.download": "Esporta la conversazione",
    "interactions.conversation.action-bar.expiring-date": "Questa conversazione scade il:",
    "interactions.conversation.action-bar.mark-as-read": "Segna la conversazione come letta",
    "interactions.conversation.action-bar.mark-as-unread": "Segna la conversazione come non letta ",
    "interactions.conversation.action-bar.print": "Stampa la conversazione",
    "interactions.conversation.action-bar.removeFromTrash": "Elimina definitivamente la conversazione",
    "interactions.conversation.action-bar.restore": "Recupera la conversazione",
    "interactions.conversation.action-bar.sort-asc": "Filtra i messaggi per data dal meno recente al più recente",
    "interactions.conversation.action-bar.sort-desc": "Filtra i messaggi per data dal più recente al meno recente",
    "interactions.conversation.action-bar.unarchive": "Recupera la conversazione",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Elimina questo allegato",
    "interactions.conversation.composer.attachments.buttons.download.title": "Scarica questo allegato",
    "interactions.conversation.composer.attachments.buttons.preview.title": "Visualizza l'allegato",
    "interactions.conversation.composer.attachments.upload-in-progress": "(in corso)",
    "interactions.conversation.composer.buttons.add-attachment.title": "Aggiungi un allegato",
    "interactions.conversation.composer.buttons.cancel.title": "Annulla le modifiche",
    "interactions.conversation.composer.buttons.cancel.value": "Annulla",
    "interactions.conversation.composer.buttons.save.title": "Bozza",
    "interactions.conversation.composer.buttons.save.value": "Bozza",
    "interactions.conversation.composer.buttons.send.title": "Invia il nuovo messaggio",
    "interactions.conversation.composer.buttons.send.value": "Invia",
    "interactions.conversation.composer.notifications.attachment-uploaded": "L'allegato è stato caricato",
    "interactions.conversation.composer.notifications.empty-file": "Non è possibile caricare allegati vuoti. Riprova con un file valido.",
    "interactions.conversation.composer.notifications.file-name-already-used": "Esiste già un allegato con lo stesso nome.",
    "interactions.conversation.composer.notifications.message-size-limit": "Gli allegati superano le dimensioni consentite.",
    "interactions.conversation.composer.notifications.upload-in-progress": "È in corso il caricamento di un allegato, aspetta che finisca.",
    "interactions.conversation.create.message-info.error.invalid-form": "Il modulo non è valido, correggilo e riprova.",
    "interactions.conversation.create.message-info.error.pop-up.leaving-page": "Le modifiche non sono state salvate. Vuoi lasciare la pagina?",
    "interactions.conversation.create.message-info.error.save.conversation": "Si è verificato un errore, la conversazione non è stata aggiornata. Riprova più tardi.",
    "interactions.conversation.create.message-info.error.save.conversation-message": "Si è verificato un errore, il messaggio non è stato creato. Riprova più tardi.",
    "interactions.conversation.create.message-info.error.subject": "Devi selezionare un tema.",
    "interactions.conversation.create.message-info.info.save": "La conversazione è stata creata.",
    "interactions.conversation.create.subject": "Oggetto:",
    "interactions.conversation.create.title": "Nuova conversazione",
    "interactions.conversation.draft.button.delete.label": "Cancella",
    "interactions.conversation.draft.button.delete.title": "Elimina la conversazione in bozza.",
    "interactions.conversation.draft.message-info.error.delete": "Si è verificato un errore, la conversazione non è stata eliminata. Riprova più tardi.",
    "interactions.conversation.draft.message-info.error.invalid-form": "Il modulo non è valido, correggilo e riprova.",
    "interactions.conversation.draft.message-info.error.save.conversation": "Si è verificato un errore, la bozza non è stata salvata. Riprova più tardi.",
    "interactions.conversation.draft.message-info.error.save.conversation-message": "Si è verificato un errore, la bozza non è stata salvata. Riprova più tardi.",
    "interactions.conversation.draft.message-info.info.delete": "La conversazione è stata eliminata.",
    "interactions.conversation.draft.message-info.info.pop-up.delete": "Sei sicuro di voler eliminare questa bozza?",
    "interactions.conversation.draft.message-info.info.save": "La richiesta è stata salvata come bozza.",
    "interactions.conversation.draft.title": "Modifica di una conversazione in bozza",
    "interactions.conversation.errors.delete": "Impossibile eliminare la bozza. Riprova più tardi",
    "interactions.conversation.errors.save": "Si è verificato un errore, il messaggio non è stato salvato. Riprova più tardi.",
    "interactions.conversation.errors.send": "Si è verificato un errore, il messaggio non è stato inviato. Riprova più tardi.",
    "interactions.conversation.header.message-number": "{nb, plural, =0 {nessun messaggio} =1 {1 messaggio} other {# messaggi}}",
    "interactions.conversation.header.status.CLOSED": "Archiviata",
    "interactions.conversation.header.status.DELETED": "Eliminata",
    "interactions.conversation.header.status.INIT": "Bozza",
    "interactions.conversation.header.status.IN_PROGRESS": "In corso",
    "interactions.conversation.header.status.TO_ARCHIVE": "Eliminata definitivamente",
    "interactions.conversation.header.status.title": "Stato",
    "interactions.conversation.header.unread": "Non letto",
    "interactions.conversation.message-info.confirm.pop-up.delete": "Sei sicuro di voler eliminare questa conversazione?",
    "interactions.conversation.message-info.error.archive": "Si è verificato un errore durante l’archiviazione. Riprova più tardi.",
    "interactions.conversation.message-info.error.categorize": "Si è verificato un errore durante la classificazione. Riprova più tardi.",
    "interactions.conversation.message-info.error.delete": "Si è verificato un errore, la conversazione non è stata eliminata. Riprova più tardi.",
    "interactions.conversation.message-info.error.download": "Si è verificato un errore durante l'esportazione della conversazione, riprova più tardi.",
    "interactions.conversation.message-info.error.markAsRead": "Si è verificato un errore durante l’azione di segnare come letto. Riprova più tardi.",
    "interactions.conversation.message-info.error.markAsUnread": "Si è verificato un errore durante l’azione di segnare come da leggere. Riprova più tardi.",
    "interactions.conversation.message-info.error.pop-up.archive": "Vuoi archiviare questa conversazione?",
    "interactions.conversation.message-info.error.pop-up.delete": "Vuoi davvero eliminare questa conversazione? Questa azione è irreversibile.",
    "interactions.conversation.message-info.error.pop-up.leaving-page": "Le modifiche non sono state salvate. Vuoi lasciare la pagina?",
    "interactions.conversation.message-info.error.print": "Si è verificato un errore durante la stampa della conversazione. Riprova più tardi.",
    "interactions.conversation.message-info.error.removeFromTrash": "Si è verificato un errore durante l’eliminazione definitiva. Riprova più tardi.",
    "interactions.conversation.message-info.error.restore": "Si è verificato un errore durante il recupero. Riprova più tardi.",
    "interactions.conversation.message-info.error.unarchive": "Si è verificato un errore durante il ripristino. Riprova più tardi.",
    "interactions.conversation.message-info.info.archive": "La conversazione è stata archiviata.",
    "interactions.conversation.message-info.info.categorize": "La conversazione è stata classificata.",
    "interactions.conversation.message-info.info.delete": "La conversazione è stata eliminata.",
    "interactions.conversation.message-info.info.markAsRead": "La conversazione è stata segnata come letta.",
    "interactions.conversation.message-info.info.markAsUnread": "La conversazione è stata segnata come da leggere.",
    "interactions.conversation.message-info.info.pop-up-removeFromTrash": "Sei sicuro di voler eliminare definitivamente questa conversazione?",
    "interactions.conversation.message-info.info.readOnly": "Questo messaggio è di sola lettura.",
    "interactions.conversation.message-info.info.removeFromTrash": "La conversazione è stata eliminata definitivamente.",
    "interactions.conversation.message-info.info.restore": "La conversazione è stata recuperata.",
    "interactions.conversation.message-info.info.unarchive": "La conversazione è stata ripristinata.",
    "interactions.conversation.showMore.button": "Vedi messaggi più vecchi",
    "interactions.conversation.showMore.message-info.error.showMoreMessages": "Si è verificato un errore, i messaggi non sono stati caricati. Riprova più tardi.",
    "interactions.conversation.success.delete": "La bozza è stata eliminata",
    "interactions.conversation.success.save": "La bozza è stata salvata",
    "interactions.conversation.success.send": "Il messaggio è stato inviato.",
    "interactions.conversations.search.list.title": "Conversazioni",
    "interactions.conversations.search.message-info.error.archive": "Si è verificato un errore durante l’archiviazione. Riprova più tardi.",
    "interactions.conversations.search.message-info.error.categorize": "Si è verificato un errore durante la classificazione. Riprova più tardi.",
    "interactions.conversations.search.message-info.error.delete": "Si è verificato un errore, la conversazione non è stata eliminata. Riprova più tardi.",
    "interactions.conversations.search.message-info.error.markAsRead": "Si è verificato un errore durante l'assegnazione: già letta. Riprova più tardi. ",
    "interactions.conversations.search.message-info.error.markAsUnread": "Si è verificato un errore durante l'assegnazione: da leggere. Riprova più tardi.",
    "interactions.conversations.search.message-info.error.removeFromTrash": "Si è verificato un errore, la conversazione non è stata eliminata. Riprova più tardi.",
    "interactions.conversations.search.message-info.error.restore": "Si è verificato un errore durante il ripristino. Riprova più tardi. ",
    "interactions.conversations.search.message-info.error.unarchive": "Si è verificato un errore durante il ripristino. Riprova più tardi. ",
    "interactions.conversations.search.message-info.info.archive": "L’archiviazione è stata effettuata.",
    "interactions.conversations.search.message-info.info.categorize": "La classificazione è stata effettuata.",
    "interactions.conversations.search.message-info.info.delete": "L’eliminazione è stata effettuata.",
    "interactions.conversations.search.message-info.info.markAsRead": "L’azione di segnare come letto è stata effettuata.",
    "interactions.conversations.search.message-info.info.markAsUnread": "L’azione di segnare come da leggere è stata effettuata.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "Vuoi davvero effettuare l’eliminazione?",
    "interactions.conversations.search.message-info.info.removeFromTrash": "L’eliminazione definitiva è stata effettuata.",
    "interactions.conversations.search.message-info.info.restore": "Il ripristino è stato effettuato.",
    "interactions.conversations.search.message-info.info.unarchive": "Il ripristino è stato effettuato.",
    "interactions.conversations.sort.lastMessageReceivedDate.asc": "Meno recente",
    "interactions.conversations.sort.lastMessageReceivedDate.desc": "Più recente",
    "interactions.forbidden-interlocutor": "Accesso non autorizzato per questo utente",
    "interactions.label.categorize-component.buttons.close": "Chiudi",
    "interactions.label.categorize-component.buttons.manage": "Gestisci le mie cartelle personali",
    "interactions.label.categorize-component.buttons.save": "Salva",
    "interactions.label.categorize-component.header.text": "Le mie cartelle personali",
    "interactions.label.categorize-component.header.title": "Le mie cartelle personali",
    "interactions.label.categorize-component.loading": "Caricamento in corso",
    "interactions.label.categorize-component.no-data": "Nessuna cartella personale da visualizzare",
    "interactions.label.popup-edit-component.buttons.close": "Annulla",
    "interactions.label.popup-edit-component.buttons.save": "Salva",
    "interactions.label.popup-edit-component.fields.color.label-color-1": "Colore 1",
    "interactions.label.popup-edit-component.fields.color.label-color-2": "Colore 2",
    "interactions.label.popup-edit-component.fields.color.label-color-3": "Colore 3",
    "interactions.label.popup-edit-component.fields.color.label-color-4": "Colore 4",
    "interactions.label.popup-edit-component.fields.color.label-color-5": "Colore 5",
    "interactions.label.popup-edit-component.fields.color.text": "Colore:",
    "interactions.label.popup-edit-component.fields.color.title": "Colore",
    "interactions.label.popup-edit-component.fields.description.text": "Descrizione:",
    "interactions.label.popup-edit-component.fields.description.title": "Descrizione",
    "interactions.label.popup-edit-component.fields.name.text": "Nome:",
    "interactions.label.popup-edit-component.fields.name.title": "Nome",
    "interactions.label.popup-edit-component.header.edit.text": "Cartella personale: [{name}]",
    "interactions.label.popup-edit-component.header.edit.title": "Cartella personale",
    "interactions.label.popup-edit-component.header.new.text": "Nuova cartella personale",
    "interactions.label.popup-edit-component.header.new.title": "Nuova cartella personale",
    "interactions.label.popup-edit-component.message-info.error.duplication": "Questo nome è già utilizzato per un’altra cartella personale.",
    "interactions.label.popup-edit-component.message-info.error.invalid-form": "Il modulo non è valido, correggilo e riprova.",
    "interactions.label.settings.buttons.add.label": "Aggiungi",
    "interactions.label.settings.buttons.add.title": "Aggiungi",
    "interactions.label.settings.buttons.delete.label": "Cancella",
    "interactions.label.settings.buttons.delete.title": "Cancella",
    "interactions.label.settings.buttons.edit.label": "Modifica",
    "interactions.label.settings.buttons.edit.title": "Modifica",
    "interactions.label.settings.fields.defaultText.text": "Aa",
    "interactions.label.settings.fields.defaultText.title": "Esempio di etichetta",
    "interactions.label.settings.fields.description.title": "Descrizione",
    "interactions.label.settings.fields.name.title": "Nome della cartella personale",
    "interactions.label.settings.message-info.error.create": "Si è verificato un errore, la cartella personale non è stata aggiunta. Riprova più tardi.",
    "interactions.label.settings.message-info.error.delete": "Si è verificato un errore, la cartella personale non è stata eliminata. Riprova più tardi.",
    "interactions.label.settings.message-info.error.edit": "Si è verificato un errore, la cartella personale non è stata modificata. Riprova più tardi.",
    "interactions.label.settings.message-info.error.get-404": "Si è verificato un errore, la cartella personale non è stata trovata. Riprova più tardi.",
    "interactions.label.settings.message-info.error.unknow": "Si è verificato un errore durante il caricamento della pagina. Verifica e riprova.",
    "interactions.label.settings.message-info.info.create": "La cartella personale è stata creata.",
    "interactions.label.settings.message-info.info.delete": "La cartella personale è stata eliminata.",
    "interactions.label.settings.message-info.info.deleteConfirm": "Vuoi eliminare la cartella personale [{name}]?",
    "interactions.label.settings.message-info.info.edit": "La cartella personale è stata aggiornata.",
    "interactions.label.settings.search.results.title": "Cartelle personali",
    "interactions.message-attachment.message-info.error.download": "Si è verificato un errore durante il download. Riprova più tardi.",
    "interactions.message-composer-attachments.multiple-upload.error": "Non è stato possibile scaricare {nbUploadsFailed, plural, one {# allegato} other {# allegati}}.",
    "interactions.message-composer-attachments.multiple-upload.success": "{nbUploadsPassed, plural, one {L’allegato è stato scaricato} other {# allegati sono stati scaricati}} con successo.",
    "interactions.message-editor.message-info.info.pop-up.replace-subject": "Confermi di voler eliminare il messaggio in corso di modifica per sostituirlo con il nuovo tema selezionato?",
    "interactions.preview-component.buttons.close": "Indietro",
    "interactions.preview-component.error": "Si è verificato un errore durante il download dell'immagine. Riprova più tardi.",
    "interactions.preview-component.loading": "Caricamento in corso",
    "interactions.preview-component.title.uploadDate": "Caricato il",
    "interactions.preview-component.title.uploader": "Caricato da",
    "interactions.side-menu.conversations.label": "Conversazioni",
    "interactions.side-menu.conversations.lines.archived": "Archiviate",
    "interactions.side-menu.conversations.lines.draft": "Bozza",
    "interactions.side-menu.conversations.lines.mine": "Le mie conversazioni",
    "interactions.side-menu.conversations.lines.sent": "Inviate",
    "interactions.side-menu.conversations.lines.trashed": "Cestino",
    "interactions.side-menu.conversations.sr-only": "conversazioni",
    "interactions.side-menu.labels.configurationButton": "Configurazione",
    "interactions.side-menu.labels.label": "Cartelle personali",
    "interactions.side-menu.new-conversation": "Nuova conversazione",
    "profile.infos.header": "Preferenze personali",
    "profile.infos.howto.messaging": "Per tornare al sistema di messaggistica protetto, clicca sul menu a discesa 'Profilo' nel menu laterale e seleziona 'I tuoi messaggi'.",
    "profile.infos.howto.notifications": "Per modificare la modalità di ricezione delle notifiche esterne, clicca su Notifiche nel menu laterale.",
    "profile.infos.howto.warnings": "Per modificare il funzionamento dei messaggi d'avviso, clicca su Preferenze nel menu laterale.",
    "profile.infos.introduction": "Sei nel modulo di gestione dei tuoi dati personali.",
    "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nuovo messaggio ricevuto",
    "profile.preferences.closeErrorMessage.label": "Chiusura dei messaggi di errore:",
    "profile.preferences.closeErrorMessage.title": "Chiusura dei messaggi di errore ",
    "profile.preferences.closeErrorMessages.false": "Automatica",
    "profile.preferences.closeErrorMessages.true": "Manuale",
    "profile.preferences.edit.cancel": "Annulla",
    "profile.preferences.edit.save": "Salva",
    "profile.preferences.header": "Preferenze",
    "profile.preferences.message-info.info.save": "Le preferenze sono state aggiornate.",
    "profile.preferences.view.edit": "Modifica",
    "profile.side-bar.sign-out": "Esci",
    "profile.side-bar.title": "Impostazioni dell’account",
    "profile.side-menu.preferences": "Preferenze",
    "profile.side-menu.subscriptions": "Notifiche",
    "profile.subscriptions.edit.cancel": "Annulla",
    "profile.subscriptions.edit.save": "Salva",
    "profile.subscriptions.message-info.error.loading": "Si è verificato un errore durante il caricamento delle sottoscrizioni. Riprova più tardi.",
    "profile.subscriptions.message-info.error.save": "Si è verificato un errore durante il salvataggio delle sottoscrizioni. Riprova più tardi.",
    "profile.subscriptions.message-info.info.save": "Le tue sottoscrizioni sono state aggiornate.",
    "profile.subscriptions.view.edit": "Modifica",
    "profile.subscriptions.view.state.disabled": "Disattivato",
    "profile.subscriptions.view.state.enabled": "Attivato",
    "shared.components.a11y.modals.alert-modal.closeButtonText": "Chiudi",
    "shared.components.a11y.modals.alert-modal.closeButtonTitle": "Chiudi la finestra",
    "shared.components.dropdown.default-button-title": "Azioni",
    "shared.components.form-component.form-fields-required-label": "I campi contrassegnati con * sono obbligatori.",
    "shared.components.form-group-component.missing-text": "Il campo è obbligatorio.",
    "shared.components.modals.document-preview.error-message": "Si è verificato un errore durante il caricamento dell’immagine. Riprova più tardi.",
    "shared.components.modals.document-preview.modal-title": "Anteprima del file",
    "shared.components.modals.document-preview.select-page": "Seleziona una pagina",
    "shared.components.modals.document-preview.upload-by": "Caricato da {actor} il {date}",
    "shared.components.modals.document-preview.zoom-in": "Ingrandisci",
    "shared.components.modals.document-preview.zoom-out": "Riduci",
    "shared.components.modals.image-preview.error-message": "Si è verificato un errore durante il caricamento dell’immagine. Riprova più tardi.",
    "shared.components.modals.image-preview.loading-message": "Caricamento in corso",
    "shared.components.modals.image-preview.modal-title": "Vedi anteprima",
    "shared.components.modals.image-preview.upload-by": "Caricato da",
    "shared.components.modals.image-preview.upload-date": "Caricato il",
    "shared.components.modals.image-preview.zoom-in": "Ingrandisci",
    "shared.components.modals.image-preview.zoom-out": "Riduci",
    "shared.components.modals.modal-component.modal-cancel": "Annulla",
    "shared.components.modals.modal-component.modal-confirm": "Conferma",
    "shared.components.modals.title.closeButton": "Chiudi questo messaggio",
    "shared.components.result-list.results.error": "Si è verificato un errore durante il caricamento della pagina. Verifica e riprova.",
    "shared.components.result-list.results.loading": "Caricamento in corso",
    "shared.components.result-list.results.none": "Nessun risultato",
    "shared.components.result-list.results.wait": "Attendi",
    "shared.components.text-editor.actions-list.f-aligncenter": "Centra",
    "shared.components.text-editor.actions-list.f-alignleft": "Allinea a sinistra",
    "shared.components.text-editor.actions-list.f-alignright": "Allinea a destra",
    "shared.components.text-editor.actions-list.f-bold": "Grassetto",
    "shared.components.text-editor.actions-list.f-color.help": "Colore",
    "shared.components.text-editor.actions-list.f-color.label": "Cambia il colore del testo",
    "shared.components.text-editor.actions-list.f-font.help": "Font",
    "shared.components.text-editor.actions-list.f-font.label": "Scegli la famiglia di font",
    "shared.components.text-editor.actions-list.f-gallery": "Aggiungi un’immagine",
    "shared.components.text-editor.actions-list.f-italic": "Corsivo",
    "shared.components.text-editor.actions-list.f-link.help": "Aggiungi un link",
    "shared.components.text-editor.actions-list.f-link.ok": "OK",
    "shared.components.text-editor.actions-list.f-link.url": "URL",
    "shared.components.text-editor.actions-list.f-list": "Passa dall’elenco/all’elenco",
    "shared.components.text-editor.actions-list.f-size.help": "Dimensioni del font",
    "shared.components.text-editor.actions-list.f-size.label": "Scegli le dimensioni del font",
    "shared.components.text-editor.actions-list.f-spellCheck.check-spelling": "Controlla l’ortografia",
    "shared.components.text-editor.actions-list.f-spellCheck.choose-language": "Scegli una lingua",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore": "Ignora",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore-all": "Ignora tutto",
    "shared.components.text-editor.actions-list.f-underline": "Sottolineato",
    "shared.components.text-editor.actions-list.f-upload.help": "Aggiungi un allegato",
    "shared.components.text-editor.message-composer-attachments.delete": "Elimina questo allegato",
    "shared.components.text-editor.message-composer-attachments.download": "Scarica questo allegato",
    "shared.components.text-editor.message-composer-attachments.dropdown-button-title": "Azioni",
    "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type": "Il tipo di allegato non è autorizzato.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.download": "Problema di comunicazione, download interrotto. Riprova più tardi.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file": "Problema di comunicazione, download interrotto. Riprova più tardi.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload": "Problema di comunicazione, upload interrotto. Riprova più tardi.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used": "Non è possibile caricare allegati vuoti. Riprova con un file valido.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached": "Hai raggiunto il numero massimo di allegati consentito.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit": "Gli allegati superano le dimensioni consentite.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.preview": "Il caricamento dell'immagine non è riuscito, riprova più tardi.",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded": "L'allegato è stato caricato",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading": "Caricamento in corso...",
    "shared.components.text-editor.message-composer-attachments.preview": "Visualizza l'allegato",
    "shared.file-uploader.error.EMPTY_ATTACHMENT": "Non è possibile caricare allegati vuoti. Riprova con un file valido.",
    "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE": "Il tipo di allegato non è autorizzato.",
    "shared.file-uploader.error.addFile": "Si è verificato un errore, l'allegato non è stato aggiunto. Riprova più tardi.",
    "shared.file-uploader.error.contentType": "Il tipo di allegato non è autorizzato.",
    "shared.file-uploader.error.length": "Il file che desideri scaricare è troppo voluminoso.",
    "shared.helpers.date-short-view.today": "Oggi, {hour}",
    "shared.numeric-unit.billion": "G",
    "shared.numeric-unit.million": "M",
    "shared.numeric-unit.thousand": "K",
    "shared.pagination": "paginazione",
    "shared.skip-link": "Vai alla pagina principale (Esci dal menu)",
    "side-panel.burger.label": "Visualizza / Nascondi il menu",
    "side-panel.hide-menu": "Nascondi il menu laterale",
    "side-panel.show-menu": "Visualizza il menu laterale",
    "side-panel.toggle-title": "Apri/Chiudi il menu",
    "sso-error.text": "Si è verificato un errore durante la connessione. Chiudere questa finestra e riprovare dal proprio spazio personale.",
    "sso-error.title": "Errore di connessione",
    "topbar.message-info.log-out-confirmation": "Disconnettersi adesso?",
    "topbar.module.interactions": "Messaggi",
    "topbar.module.profile": "Profilo",
    "topbar.nav.label": "Barra plurimodulo",
    "topbar.organisation.name": "Messaggistica protetta Assicurato"
  };
  _exports.default = _default;
});