define("ticust-s2h-web-application/services/modules-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    routing: Ember.inject.service('-routing'),
    intl: Ember.inject.service(),
    routeMapping: [{
      routeName: 'app.ticust-web-module-interactions',
      module: 'interactions'
    }, {
      routeName: 'app.ticust-web-module-profile',
      module: 'profile'
    }, {
      routeName: 'app.ticust-web-module-projects',
      module: 'projects'
    }],
    currentModule: Ember.computed('routing.currentRouteName', function () {
      var currentRouteName = this.get('routing.currentRouteName');
      var routesSplit = currentRouteName.split('.');
      var moduleRoute = routesSplit[0] + '.' + routesSplit[1];
      var moduleSelected = this.get('routeMapping').filterBy('routeName', moduleRoute)[0];
      return moduleSelected ? {
        name: moduleSelected.module,
        module: moduleSelected.module
      } : null;
    })
  });
  _exports.default = _default;
});