define("ticust-s2h-web-application/components/ti-web-shared/side-menu/side-menu-section", ["exports", "ti-web-shared/components/ti-web-shared/side-menu/side-menu-section"], function (_exports, _sideMenuSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sideMenuSection.default;
    }
  });
});