define("ticust-s2h-web-application/initializers/ticust-web-module-profile/preferences-main", ["exports", "ticust-web-module-profile/components/ticust-web-module-profile/preferences-main", "ticust-s2h-web-application/templates/components/ticust-web-module-profile/preferences-main"], function (_exports, _preferencesMain, _preferencesMain2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
  _preferencesMain.default.reopen({
    layout: _preferencesMain2.default
  });
});