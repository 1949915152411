define("ticust-s2h-web-application/helpers/left-pad", ["exports", "ti-web-shared/helpers/left-pad"], function (_exports, _leftPad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _leftPad.default;
    }
  });
});