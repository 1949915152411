define("ticust-s2h-web-application/translations/es-es", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "common-projects.selectors.actor-selector.error": "Se ha producido un error al buscar conversaciones. Por favor, inténtelo de nuevo más tarde.",
    "common.application.title": "Trusted Interactions",
    "common.file-upload.error.addFile": "Se ha producido un error, no se ha añadido el archivo adjunto. Por favor, inténtelo de nuevo más tarde.",
    "common.file-upload.error.contentType": "El tipo de archivo adjunto no está autorizado.",
    "common.file-upload.error.length": "El archivo que desea cargar pesa demasiado.",
    "common.message-info.error.403": "No está autorizado a ver esta página.",
    "common.message-info.error.unknown": "Se ha producido un error. Por favor, inténtelo de nuevo más tarde.",
    "common.page-error-message": "Se ha producido un error. Por favor, inténtelo de nuevo más tarde.",
    "common.pagination-component.currentPage": "Página actual",
    "common.pagination-component.firstPage": "Primera página",
    "common.pagination-component.lastPage": "Última página",
    "common.pagination-component.nextPage": "Página siguiente",
    "common.pagination-component.page": "Página",
    "common.pagination-component.previousPage": "Página anterior",
    "common.result-list.results.error": "Se ha producido un error al cargar la página. Por favor, compruébelo e inténtelo de nuevo más tarde.",
    "common.result-list.results.none": "Ningún resultado",
    "common.result-list.sort.by": "Ordenar",
    "common.sidemenu.label": "Menú",
    "common.sidemenu.minimize": "Reducir el menú",
    "common.sidemenu.pulldown": "Desplegar el menú",
    "common.sidemenu.search": "Buscar",
    "common.spellchecker.languages.de": "Alemán",
    "common.spellchecker.languages.en": "Inglés ",
    "common.spellchecker.languages.es": "Español ",
    "common.spellchecker.languages.fr": "Francés ",
    "common.spellchecker.languages.it": "Italiano ",
    "common.spellchecker.languages.nl": "Neerlandés",
    "common.spellchecker.languages.pt": "Portugués",
    "common.spellchecker.message-info.error.unknown": "Se ha producido un error.",
    "common.variables.notificationCommon.EVENT_DATE": "EVENT_DATE",
    "common.variables.notificationCommon.RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
    "common.variables.notificationCommon.RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
    "common.variables.notificationCommon.RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME",
    "disconnection.text.explanation": "Su sesión de mensajería segura ha terminado. Puede cerrar esta página.",
    "disconnection.text.reminder": "Si desea acceder de nuevo a su mensajería segura, utilice el enlace de acceso que encontrará en su Espacio de Asegurado o su aplicación móvil. ",
    "disconnection.title": "Desconexión de su sesión de mensajería segura",
    "interactions.conversation-action-bar-component.actions.archive.display": "Archivar",
    "interactions.conversation-action-bar-component.actions.archive.title": "Archivar todas las conversaciones seleccionadas",
    "interactions.conversation-action-bar-component.actions.categorize.display": "Clasificar en...",
    "interactions.conversation-action-bar-component.actions.categorize.labelSelected": "Clasificar todas las conversaciones seleccionadas en {label}",
    "interactions.conversation-action-bar-component.actions.categorize.title": "Clasificar todas las conversaciones seleccionadas en...",
    "interactions.conversation-action-bar-component.actions.categorize.toggle": "Abrir el menú 'Clasificar en... '",
    "interactions.conversation-action-bar-component.actions.checkAll": "Seleccionar todo",
    "interactions.conversation-action-bar-component.actions.delete.display": "Eliminar",
    "interactions.conversation-action-bar-component.actions.delete.title": "Eliminar todas las conversaciones seleccionadas",
    "interactions.conversation-action-bar-component.actions.markAs.display": "Marcar como...",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display": "Marcar como leída",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title": "Marcar todas las conversaciones seleccionadas como leídas",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display": "Marcar como no leída",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title": "Marcar todas las conversaciones seleccionadas como no leídas",
    "interactions.conversation-action-bar-component.actions.markAs.toggle": "Abrir el menú 'Marcar como... '",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.display": "Eliminar de la papelera",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.title": "Eliminar todas las conversaciones seleccionadas de la papelera",
    "interactions.conversation-action-bar-component.actions.restore.display": "Restablecer",
    "interactions.conversation-action-bar-component.actions.restore.title": "Restablecer todas las conversaciones seleccionadas",
    "interactions.conversation-action-bar-component.actions.unarchive.display": "Restablecer",
    "interactions.conversation-action-bar-component.actions.unarchive.title": "Restablecer todas las conversaciones seleccionadas",
    "interactions.conversation-action-bar-component.actions.uncheckAll": "Deseleccionar todo",
    "interactions.conversation-action-bar-component.search.button": "Filtrar las conversaciones con el texto introducido",
    "interactions.conversation-action-bar-component.search.input": "Introducir el texto que desea buscar",
    "interactions.conversation-action-bar-component.sort.sortBy": "Ordenar:",
    "interactions.conversation-line-component.buttons.check": "Seleccionar la conversación.",
    "interactions.conversation-line-component.buttons.uncheck": "Deseleccionar la conversación.",
    "interactions.conversation-line-component.conversationSubject": "Tema de la conversación.",
    "interactions.conversation-line-component.hasAttachments": "La conversación tiene al menos un mensaje con un archivo adjunto.",
    "interactions.conversation-line-component.isRead.read": "El último mensaje de la conversación ha sido leído.",
    "interactions.conversation-line-component.isRead.unread": "El último mensaje de la conversación no ha sido leído.",
    "interactions.conversation-line-component.lastMessageReceivedDate": "Fecha del último mensaje recibido.",
    "interactions.conversation-line-component.lastMessageReceivedcontent": "Contenido del último mensaje recibido.",
    "interactions.conversation-line-component.lastMessageStatus.DRAFT": "El último mensaje de la conversación es un borrador.",
    "interactions.conversation-line-component.lastMessageStatus.RECEIVED": "El último mensaje de la conversación procede de un consejero.",
    "interactions.conversation-line-component.lastMessageStatus.SENT": "El último mensaje de conversación procede de usted.",
    "interactions.conversation-message-attachment.attachment": "Archivos",
    "interactions.conversation-message-attachment.download": "Descargar",
    "interactions.conversation-message-attachment.dropdown-button-title": "Acciones",
    "interactions.conversation-message-attachment.preview": "Previsualizar",
    "interactions.conversation-message.body.buttons.show-less.text": " --- ver el mensaje parcial ---",
    "interactions.conversation-message.body.buttons.show-less.title": "Ver el mensaje parcial",
    "interactions.conversation-message.body.buttons.show-more.text": " --- ver el mensaje completo ---",
    "interactions.conversation-message.body.buttons.show-more.title": "Ver el mensaje completo",
    "interactions.conversation-message.header.expand-button.label-expand": "Ampliar el encabezado",
    "interactions.conversation-message.header.expand-button.label-shrink": "Reducir el encabezado",
    "interactions.conversation-message.header.expanded.channel": "Canal:",
    "interactions.conversation-message.header.expanded.channels.MAIL": "Correo electrónico",
    "interactions.conversation-message.header.expanded.from": "Por:",
    "interactions.conversation-message.header.expanded.receiveDate": "Recibido el:",
    "interactions.conversation-message.header.expanded.sendDate": "Enviado el:",
    "interactions.conversation-message.header.expanded.subject": "Asunto:",
    "interactions.conversation-message.header.expanded.tags": "Etiquetas:",
    "interactions.conversation-message.header.expanded.to": "Para:",
    "interactions.conversation-message.header.expanded.virus-scan": "Escáner antivirus:",
    "interactions.conversation-message.header.shrunken.from": "Por:",
    "interactions.conversation-message.header.shrunken.to": "Para:",
    "interactions.conversation-message.header.title": "Información sobre el mensaje",
    "interactions.conversation.action-bar.archive": "Archivar la conversación",
    "interactions.conversation.action-bar.categorize": "Clasificar la conversación",
    "interactions.conversation.action-bar.delete": "Eliminar la conversación.",
    "interactions.conversation.action-bar.download": "Exportar la conversación",
    "interactions.conversation.action-bar.expiring-date": "Esta conversación expira el:",
    "interactions.conversation.action-bar.mark-as-read": "Marcar la conversación como leída",
    "interactions.conversation.action-bar.mark-as-unread": "Marcar la conversación como no leída",
    "interactions.conversation.action-bar.print": "Imprimir la conversación",
    "interactions.conversation.action-bar.removeFromTrash": "Eliminar definitivamente la conversación",
    "interactions.conversation.action-bar.restore": "Restablecer la conversación",
    "interactions.conversation.action-bar.sort-asc": "Ordenar los mensajes por fecha del más antiguo al más reciente",
    "interactions.conversation.action-bar.sort-desc": "Ordenar los mensajes por fecha del más reciente al más antiguo",
    "interactions.conversation.action-bar.unarchive": "Restablecer la conversación",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Eliminar este archivo adjunto",
    "interactions.conversation.composer.attachments.buttons.download.title": "Descargar este archivo adjunto",
    "interactions.conversation.composer.attachments.buttons.preview.title": "Visualizar documento adjunto",
    "interactions.conversation.composer.attachments.upload-in-progress": "(en curso)",
    "interactions.conversation.composer.buttons.add-attachment.title": "Añadir un archivo adjunto",
    "interactions.conversation.composer.buttons.cancel.title": "Cancelar los cambios",
    "interactions.conversation.composer.buttons.cancel.value": "Cancelar",
    "interactions.conversation.composer.buttons.save.title": "Modo borrador",
    "interactions.conversation.composer.buttons.save.value": "Modo borrador",
    "interactions.conversation.composer.buttons.send.title": "Enviar este nuevo mensaje",
    "interactions.conversation.composer.buttons.send.value": "Enviar",
    "interactions.conversation.composer.notifications.attachment-uploaded": "Se ha guardado el archivo adjunto",
    "interactions.conversation.composer.notifications.empty-file": "No puede cargar archivos vacíos. Por favor, inténtelo de nuevo con un archivo válido.",
    "interactions.conversation.composer.notifications.file-name-already-used": "Ya existe un archivo adjunto con el mismo nombre.",
    "interactions.conversation.composer.notifications.message-size-limit": "Los archivos adjuntos superan el límite de tamaño.",
    "interactions.conversation.composer.notifications.upload-in-progress": "Se está guardando un archivo adjunto. Por favor, espere a que finalice la transferencia.",
    "interactions.conversation.create.message-info.error.invalid-form": "El formulario no es válido. Por favor, corríjalo e inténtelo de nuevo.",
    "interactions.conversation.create.message-info.error.pop-up.leaving-page": "Sus cambios no se han guardado. ¿Desea salir de la página?",
    "interactions.conversation.create.message-info.error.save.conversation": "Se ha producido un error al actualizar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.create.message-info.error.save.conversation-message": "Se ha producido un error al crear un mensaje. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.create.message-info.error.subject": "Debe seleccionar un tema.",
    "interactions.conversation.create.message-info.info.save": "La conversación ha sido creada.",
    "interactions.conversation.create.subject": "Asunto:",
    "interactions.conversation.create.title": "Nueva conversación",
    "interactions.conversation.draft.button.delete.label": "Eliminar",
    "interactions.conversation.draft.button.delete.title": "Eliminar la conversación en borrador.",
    "interactions.conversation.draft.message-info.error.delete": "Se ha producido un error al eliminar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.draft.message-info.error.invalid-form": "El formulario no es válido. Por favor, corríjalo e inténtelo de nuevo.",
    "interactions.conversation.draft.message-info.error.save.conversation": "Se ha producido un error al guardar el borrador. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.draft.message-info.error.save.conversation-message": "Se ha producido un error al guardar el borrador. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.draft.message-info.info.delete": "La conversación ha sido eliminada.",
    "interactions.conversation.draft.message-info.info.pop-up.delete": "¿Está seguro de que quiere eliminar este borrador?",
    "interactions.conversation.draft.message-info.info.save": "Su solicitud se ha guardado como borrador.",
    "interactions.conversation.draft.title": "Editar el borrador de una conversación",
    "interactions.conversation.errors.delete": "No se pudo eliminar el borrador. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.errors.save": "Se ha producido un error al guardar el mensaje. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.errors.send": "Se ha producido un error al enviar el mensaje. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.header.message-number": "{nb, plural, =0 {ningún mensaje} =1 {1 mensaje} other {# mensajes}}",
    "interactions.conversation.header.status.CLOSED": "Archivada",
    "interactions.conversation.header.status.DELETED": "Eliminada",
    "interactions.conversation.header.status.INIT": "Borrador",
    "interactions.conversation.header.status.IN_PROGRESS": "En curso",
    "interactions.conversation.header.status.TO_ARCHIVE": "Eliminada definitivamente",
    "interactions.conversation.header.status.title": "Estatus",
    "interactions.conversation.header.unread": "Sin leer",
    "interactions.conversation.message-info.confirm.pop-up.delete": "¿Está seguro de que quiere mover esta conversación a su papelera?",
    "interactions.conversation.message-info.error.archive": "Se ha producido un error al archivar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.categorize": "Se ha producido un error al clasificar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.delete": "Se ha producido un error al eliminar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.download": "Se ha producido un error al descargar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.markAsRead": "Se ha producido un error al marcar la conversación como leída. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.markAsUnread": "Se ha producido un error al marcar la conversación como no leída. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.pop-up.archive": "¿Está seguro de que quiere archivar esta conversación?",
    "interactions.conversation.message-info.error.pop-up.delete": "¿Está seguro de que quiere eliminar esta conversación? Esta acción es irreversible.",
    "interactions.conversation.message-info.error.pop-up.leaving-page": "Sus cambios no se han guardado. ¿Desea salir de la página?",
    "interactions.conversation.message-info.error.print": "Se ha producido un error al imprimir la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.removeFromTrash": "Se ha producido un error al eliminar definitivamente la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.restore": "Se ha producido un error al restablecer la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.error.unarchive": "Se ha producido un error al restablecer la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversation.message-info.info.archive": "La conversación ha sido archivada.",
    "interactions.conversation.message-info.info.categorize": "La conversación ha sido clasificada.",
    "interactions.conversation.message-info.info.delete": "La conversación ha sido eliminada.",
    "interactions.conversation.message-info.info.markAsRead": "La conversación ha sido marcada como leída.",
    "interactions.conversation.message-info.info.markAsUnread": "La conversación ha sido marcada como no leída.",
    "interactions.conversation.message-info.info.pop-up-removeFromTrash": "¿Está seguro de que quiere eliminar definitivamente esta conversación?",
    "interactions.conversation.message-info.info.readOnly": "Este mensaje es de sólo lectura.",
    "interactions.conversation.message-info.info.removeFromTrash": "La conversación ha sido eliminada definitivamente.",
    "interactions.conversation.message-info.info.restore": "La conversación ha sido restablecida.",
    "interactions.conversation.message-info.info.unarchive": "La conversación ha sido restablecida.",
    "interactions.conversation.showMore.button": "Ver mensajes anteriores",
    "interactions.conversation.showMore.message-info.error.showMoreMessages": "Se ha producido un error al cargar los mensajes. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.conversation.success.delete": "El borrador ha sido eliminado",
    "interactions.conversation.success.save": "El borrador ha sido guardado",
    "interactions.conversation.success.send": "El mensaje ha sido enviado.",
    "interactions.conversations.search.list.title": "Conversaciones",
    "interactions.conversations.search.message-info.error.archive": "Se ha producido un error al archivar la conversación. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.conversations.search.message-info.error.categorize": "Se ha producido un error al clasificar la conversación. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.conversations.search.message-info.error.delete": "Se ha producido un error al eliminar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversations.search.message-info.error.markAsRead": "Se ha producido un error al marcar la conversación como leída. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.conversations.search.message-info.error.markAsUnread": " Se ha producido un error al marcar la conversación como no leída. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.conversations.search.message-info.error.removeFromTrash": "Se ha producido un error al eliminar la conversación. Por favor, inténtelo de nuevo más tarde.",
    "interactions.conversations.search.message-info.error.restore": "Se ha producido un error al restablecer la conversación. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.conversations.search.message-info.error.unarchive": "Se ha producido un error al restablecer la conversación. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.conversations.search.message-info.info.archive": "Se ha completado el archivo.",
    "interactions.conversations.search.message-info.info.categorize": "Se ha completado la clasificación.",
    "interactions.conversations.search.message-info.info.delete": "Se ha completado la eliminación.",
    "interactions.conversations.search.message-info.info.markAsRead": "Se ha completado el marcado como leído.",
    "interactions.conversations.search.message-info.info.markAsUnread": "Se ha completado el marcado como no leído.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "¿Está seguro de que quiere eliminarlo?",
    "interactions.conversations.search.message-info.info.removeFromTrash": "Se ha completado la eliminación definitiva.",
    "interactions.conversations.search.message-info.info.restore": "Se ha completado el restablecimiento.",
    "interactions.conversations.search.message-info.info.unarchive": "Se ha completado el restablecimiento.",
    "interactions.conversations.sort.lastMessageReceivedDate.asc": "El más antiguo",
    "interactions.conversations.sort.lastMessageReceivedDate.desc": "El más reciente",
    "interactions.forbidden-interlocutor": "El acceso a este usuario no está permitido",
    "interactions.label.categorize-component.buttons.close": "Cerrar",
    "interactions.label.categorize-component.buttons.manage": "Gestionar mis carpetas",
    "interactions.label.categorize-component.buttons.save": "Guardar",
    "interactions.label.categorize-component.header.text": "Mis carpetas personales",
    "interactions.label.categorize-component.header.title": "Mis carpetas personales",
    "interactions.label.categorize-component.loading": "Cargando…",
    "interactions.label.categorize-component.no-data": "No carpeta personal que visualizar",
    "interactions.label.popup-edit-component.buttons.close": "Cancelar",
    "interactions.label.popup-edit-component.buttons.save": "Guardar",
    "interactions.label.popup-edit-component.fields.color.label-color-1": "Color 1",
    "interactions.label.popup-edit-component.fields.color.label-color-2": "Color 2",
    "interactions.label.popup-edit-component.fields.color.label-color-3": "Color 3",
    "interactions.label.popup-edit-component.fields.color.label-color-4": "Color 4",
    "interactions.label.popup-edit-component.fields.color.label-color-5": "Color 5",
    "interactions.label.popup-edit-component.fields.color.text": "Color:",
    "interactions.label.popup-edit-component.fields.color.title": "Color",
    "interactions.label.popup-edit-component.fields.description.text": "Descripción:",
    "interactions.label.popup-edit-component.fields.description.title": "Descripción",
    "interactions.label.popup-edit-component.fields.name.text": "Nombre: ",
    "interactions.label.popup-edit-component.fields.name.title": "Nombre",
    "interactions.label.popup-edit-component.header.edit.text": "Carpeta personal: [{name}]",
    "interactions.label.popup-edit-component.header.edit.title": "Carpeta personal ",
    "interactions.label.popup-edit-component.header.new.text": "Nueva carpeta personal",
    "interactions.label.popup-edit-component.header.new.title": "Nueva carpeta personal",
    "interactions.label.popup-edit-component.message-info.error.duplication": "El nombre ya es utilizado para otra carpeta personal.",
    "interactions.label.popup-edit-component.message-info.error.invalid-form": "El formulario no es válido. Por favor, corríjalo e inténtelo de nuevo.",
    "interactions.label.settings.buttons.add.label": "Añadir",
    "interactions.label.settings.buttons.add.title": "Añadir",
    "interactions.label.settings.buttons.delete.label": "Eliminar",
    "interactions.label.settings.buttons.delete.title": "Eliminar",
    "interactions.label.settings.buttons.edit.label": "Editar",
    "interactions.label.settings.buttons.edit.title": "Editar",
    "interactions.label.settings.fields.defaultText.text": "Aa",
    "interactions.label.settings.fields.defaultText.title": "Ejemplo de etiqueta",
    "interactions.label.settings.fields.description.title": "Descripción",
    "interactions.label.settings.fields.name.title": "Nombre de la carpeta personal",
    "interactions.label.settings.message-info.error.create": "Se ha producido un error al crear la carpeta personal. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.label.settings.message-info.error.delete": "Se ha producido un error al eliminar la carpeta personal. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.label.settings.message-info.error.edit": "Se ha producido un error al actualizar la carpeta personal. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.label.settings.message-info.error.get-404": "Se ha producido un error al encontrar la carpeta personal. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.label.settings.message-info.error.unknow": "Se ha producido un error al cargar la página. Por favor, compruébelo e inténtelo de nuevo más tarde.",
    "interactions.label.settings.message-info.info.create": "La carpeta personal ha sido creada.",
    "interactions.label.settings.message-info.info.delete": "La carpeta personal ha sido eliminada.",
    "interactions.label.settings.message-info.info.deleteConfirm": "¿Desea eliminar la carpeta personal [{name}]?",
    "interactions.label.settings.message-info.info.edit": "La carpeta personal ha sido actualizada.",
    "interactions.label.settings.search.results.title": "Carpetas personales",
    "interactions.message-attachment.message-info.error.download": "Se ha producido un error al descargar el archivo adjunto. Por favor, inténtelo de nuevo más tarde. ",
    "interactions.message-composer-attachments.multiple-upload.error": "{nbUploadsFailed, plural, one {# archivo adjunto no ha} other {# archivos adjuntos no han}} sido {nbUploadsFailed, plural, one {subido} other {subidos}}.",
    "interactions.message-composer-attachments.multiple-upload.success": "{nbUploadsPassed, plural, one {El archivo adjunto ha sido subido} other {# archivos adjuntos subidos}} correctamente.",
    "interactions.message-editor.message-info.info.pop-up.replace-subject": "¿Desea reemplazar el contenido del mensaje actual para sustituirlo por el nuevo tema seleccionado?",
    "interactions.preview-component.buttons.close": "Volver",
    "interactions.preview-component.error": "Se ha producido un error al cargar la imagen. Por favor, inténtelo de nuevo más tarde.",
    "interactions.preview-component.loading": "Cargando…",
    "interactions.preview-component.title.uploadDate": "Subido el",
    "interactions.preview-component.title.uploader": "Subido por",
    "interactions.side-menu.conversations.label": "Conversaciones",
    "interactions.side-menu.conversations.lines.archived": "Archivadas",
    "interactions.side-menu.conversations.lines.draft": "Borrador",
    "interactions.side-menu.conversations.lines.mine": "Mis conversaciones",
    "interactions.side-menu.conversations.lines.sent": "Enviadas",
    "interactions.side-menu.conversations.lines.trashed": "Papelera",
    "interactions.side-menu.conversations.sr-only": "conversaciones",
    "interactions.side-menu.labels.configurationButton": "Configuración",
    "interactions.side-menu.labels.label": "Carpetas personales",
    "interactions.side-menu.new-conversation": "Nueva conversación",
    "profile.infos.header": "Preferencias personales",
    "profile.infos.howto.messaging": "Para volver a su mensajería segura, haga clic en el menú desplegable 'Perfil' del menú lateral y seleccione 'Mensajería'.",
    "profile.infos.howto.notifications": "Para cambiar la recepción de notificaciones externas, haga clic en 'Notificaciones' en el menú lateral.",
    "profile.infos.howto.warnings": "Para cambiar el funcionamiento de los mensajes de advertencia, haga clic en 'Preferencias' en el menú lateral.",
    "profile.infos.introduction": "Se encuentra en el módulo de gestión de sus datos personales.",
    "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nuevo mensaje recibido",
    "profile.preferences.closeErrorMessage.label": "Cerrar los mensajes de error:",
    "profile.preferences.closeErrorMessage.title": "Cerrar los mensajes de error",
    "profile.preferences.closeErrorMessages.false": "Automático",
    "profile.preferences.closeErrorMessages.true": "Manual",
    "profile.preferences.edit.cancel": "Cancelar",
    "profile.preferences.edit.save": "Guardar",
    "profile.preferences.header": "Preferencias",
    "profile.preferences.message-info.info.save": "Las preferencias han sido actualizadas.",
    "profile.preferences.view.edit": "Cambiar",
    "profile.side-bar.sign-out": "Desconexión",
    "profile.side-bar.title": "Configuración de la cuenta",
    "profile.side-menu.preferences": "Preferencias",
    "profile.side-menu.subscriptions": "Notificaciones",
    "profile.subscriptions.edit.cancel": "Cancelar",
    "profile.subscriptions.edit.save": "Guardar",
    "profile.subscriptions.message-info.error.loading": "Se ha producido un error al cargar las suscripciones. Por favor, inténtelo de nuevo más tarde.",
    "profile.subscriptions.message-info.error.save": "Se ha producido un error al actualizar las suscripciones. Por favor, inténtelo de nuevo más tarde.",
    "profile.subscriptions.message-info.info.save": "Sus suscripciones han sido actualizadas.",
    "profile.subscriptions.view.edit": "Modificar",
    "profile.subscriptions.view.state.disabled": "Inactiva",
    "profile.subscriptions.view.state.enabled": "Activa",
    "shared.components.a11y.modals.alert-modal.closeButtonText": "Cerrar",
    "shared.components.a11y.modals.alert-modal.closeButtonTitle": "Cerrar la ventana",
    "shared.components.dropdown.default-button-title": "Acciones",
    "shared.components.form-component.form-fields-required-label": "Los campos marcados con * son obligatorios.",
    "shared.components.form-group-component.missing-text": "El campo es obligatorio.",
    "shared.components.modals.document-preview.error-message": "Se ha producido un error al cargar la imagen.  Por favor, inténtelo de nuevo más tarde. ",
    "shared.components.modals.document-preview.modal-title": "Previsualizar el archivo",
    "shared.components.modals.document-preview.select-page": "Seleccionar la página",
    "shared.components.modals.document-preview.upload-by": "Subido por {actor} el {date}",
    "shared.components.modals.document-preview.zoom-in": "Acercar",
    "shared.components.modals.document-preview.zoom-out": " Alejar",
    "shared.components.modals.image-preview.error-message": "Se ha producido un error al cargar la imagen. Por favor, inténtelo de nuevo más tarde.",
    "shared.components.modals.image-preview.loading-message": "Cargando…",
    "shared.components.modals.image-preview.modal-title": "Previsualizar",
    "shared.components.modals.image-preview.upload-by": "Subido por",
    "shared.components.modals.image-preview.upload-date": "Subido el",
    "shared.components.modals.image-preview.zoom-in": "Acercar",
    "shared.components.modals.image-preview.zoom-out": " Alejar",
    "shared.components.modals.modal-component.modal-cancel": "Cancelar",
    "shared.components.modals.modal-component.modal-confirm": "Confirmar",
    "shared.components.modals.title.closeButton": "Cerrar este mensaje",
    "shared.components.result-list.results.error": "Se ha producido un error al cargar la página. Por favor, compruébelo e inténtelo de nuevo más tarde.",
    "shared.components.result-list.results.loading": "Cargando…",
    "shared.components.result-list.results.none": "Ningún resultado",
    "shared.components.result-list.results.wait": "Por favor, espere",
    "shared.components.text-editor.actions-list.f-aligncenter": "Centrar",
    "shared.components.text-editor.actions-list.f-alignleft": "Alinear a la izquierda",
    "shared.components.text-editor.actions-list.f-alignright": "Alinear a la derecha",
    "shared.components.text-editor.actions-list.f-bold": "Negrita",
    "shared.components.text-editor.actions-list.f-color.help": "Color",
    "shared.components.text-editor.actions-list.f-color.label": "Cambiar el color del texto",
    "shared.components.text-editor.actions-list.f-font.help": "Fuente",
    "shared.components.text-editor.actions-list.f-font.label": "Seleccione la familia de fuentes",
    "shared.components.text-editor.actions-list.f-gallery": "Añadir una imagen",
    "shared.components.text-editor.actions-list.f-italic": "Cursiva",
    "shared.components.text-editor.actions-list.f-link.help": "Añadir un enlace",
    "shared.components.text-editor.actions-list.f-link.ok": "OK",
    "shared.components.text-editor.actions-list.f-link.url": "URL",
    "shared.components.text-editor.actions-list.f-list": "Pasar de/a la lista",
    "shared.components.text-editor.actions-list.f-size.help": "Tamaño de la fuente",
    "shared.components.text-editor.actions-list.f-size.label": "Seleccione el tamaño de la fuente",
    "shared.components.text-editor.actions-list.f-spellCheck.check-spelling": "Comprobar la ortografía ",
    "shared.components.text-editor.actions-list.f-spellCheck.choose-language": "Seleccione un idioma",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore": "Ignorar",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore-all": "Ignorar todo",
    "shared.components.text-editor.actions-list.f-underline": "Subrayar",
    "shared.components.text-editor.actions-list.f-upload.help": "Añadir un archivo adjunto",
    "shared.components.text-editor.message-composer-attachments.delete": "Eliminar este archivo adjunto",
    "shared.components.text-editor.message-composer-attachments.download": "Descargar este archivo adjunto",
    "shared.components.text-editor.message-composer-attachments.dropdown-button-title": "Acciones",
    "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type": "El tipo de archivo adjunto no está autorizado.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.download": "Problema de comunicación, descarga interrumpida. Por favor, inténtelo de nuevo más tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file": "Problema de comunicación, descarga interrumpida. Por favor, inténtelo de nuevo más tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload": "Problema de comunicación, descarga interrumpida. Por favor, inténtelo de nuevo más tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used": "No puede cargar archivos vacíos. Por favor, inténtelo de nuevo con un archivo válido.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached": "Se ha alcanzado el número máximo de archivos adjuntos permitido.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit": "Los archivos adjuntos superan el límite de tamaño.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.preview": "Se ha producido un error al cargar la imagen. Por favor, inténtelo de nuevo más tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded": "Se ha guardado el archivo adjunto",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading": "Carga en curso…",
    "shared.components.text-editor.message-composer-attachments.preview": "Visualizar documento adjunto",
    "shared.file-uploader.error.EMPTY_ATTACHMENT": "No puede cargar archivos vacíos. Por favor, inténtelo de nuevo con un archivo válido.",
    "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE": "El tipo de archivo adjunto no está autorizado.",
    "shared.file-uploader.error.addFile": "Se ha producido un error, no se ha añadido el archivo adjunto. Por favor, inténtelo de nuevo más tarde.",
    "shared.file-uploader.error.contentType": "El tipo de archivo adjunto no está autorizado.",
    "shared.file-uploader.error.length": "El archivo que desea cargar pesa demasiado.",
    "shared.helpers.date-short-view.today": "Hoy, {hour}",
    "shared.numeric-unit.billion": "G",
    "shared.numeric-unit.million": "M",
    "shared.numeric-unit.thousand": "K",
    "shared.pagination": "paginación ",
    "shared.skip-link": "Ir a la página principal (Salir del menú)",
    "side-panel.burger.label": "Visualizar / Ocultar el menú",
    "side-panel.hide-menu": "Ocultar el menú lateral",
    "side-panel.show-menu": "Visualizar el menú lateral",
    "side-panel.toggle-title": "Abrir/Cerrar el menú",
    "sso-error.text": "Se ha producido un error durante la conexión. Cierre esta ventana e inténtelo de nuevo desde su espacio personal.",
    "sso-error.title": "Error de conexión",
    "topbar.message-info.log-out-confirmation": "¿Está seguro de que quiere desconectarse?",
    "topbar.module.interactions": "Mensajes",
    "topbar.module.profile": "Perfil",
    "topbar.nav.label": "Barra superior multi módula",
    "topbar.organisation.name": "Mensajería segura del Asegurado"
  };
  _exports.default = _default;
});