define("ticust-s2h-web-application/translations/pt-pt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "common-projects.selectors.actor-selector.error": "Ocorreu um erro, a pesquisa não foi efetuada. Volte a tentar mais tarde.",
    "common.application.title": "Trusted Interactions",
    "common.file-upload.error.addFile": "Ocorreu um erro, o anexo não foi adicionado. Volte a tentar mais tarde.",
    "common.file-upload.error.contentType": "O tipo do anexo não é autorizado.",
    "common.file-upload.error.length": "O ficheiro que pretende carregar é demasiado grande. ",
    "common.message-info.error.403": "Não tem direitos para visualizar esta página.",
    "common.message-info.error.unknown": "Ocorreu um erro. Volte a tentar mais tarde.",
    "common.page-error-message": "Ocorreu um erro. Volte a tentar mais tarde.",
    "common.pagination-component.currentPage": "Página atual",
    "common.pagination-component.firstPage": "Página inicial",
    "common.pagination-component.lastPage": "Última página",
    "common.pagination-component.nextPage": "Página seguinte",
    "common.pagination-component.page": "Página",
    "common.pagination-component.previousPage": "Página anterior",
    "common.result-list.results.error": "Ocorreu um erro ao carregar a página . Verifique e volte a tentar mais tarde.",
    "common.result-list.results.none": "Nenhum resultado",
    "common.result-list.sort.by": "Ordenar por",
    "common.sidemenu.label": "Menu",
    "common.sidemenu.minimize": "Minimizar o menu",
    "common.sidemenu.pulldown": "Abrir o menu",
    "common.sidemenu.search": "Pesquisar",
    "common.spellchecker.languages.de": "Alemão",
    "common.spellchecker.languages.en": "Inglês",
    "common.spellchecker.languages.es": "Espanhol",
    "common.spellchecker.languages.fr": "Francês",
    "common.spellchecker.languages.it": "Italiano",
    "common.spellchecker.languages.nl": "Neerlandês",
    "common.spellchecker.languages.pt": "Português",
    "common.spellchecker.message-info.error.unknown": "Ocorreu um erro. ",
    "common.variables.notificationCommon.EVENT_DATE": "EVENT_DATE",
    "common.variables.notificationCommon.RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
    "common.variables.notificationCommon.RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
    "common.variables.notificationCommon.RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME",
    "disconnection.text.explanation": "A sessão iniciada no sistema de mensagens seguro terminou. Pode fechar esta página.",
    "disconnection.text.reminder": "Se pretender aceder novamente ao seu sistema de mensagens seguro, utilize a ligação de acesso a partir do seu Espaço Segurado ou da sua aplicação móvel ",
    "disconnection.title": "Terminar a sessão iniciada no sistema de mensagens seguro",
    "interactions.conversation-action-bar-component.actions.archive.display": "Arquivar",
    "interactions.conversation-action-bar-component.actions.archive.title": "Arquivar todas as conversas selecionadas",
    "interactions.conversation-action-bar-component.actions.categorize.display": "Classificar em …",
    "interactions.conversation-action-bar-component.actions.categorize.labelSelected": "Classificar todas as conversas selecionadas em {label}",
    "interactions.conversation-action-bar-component.actions.categorize.title": "Classificar todas as conversas selecionadas em …",
    "interactions.conversation-action-bar-component.actions.categorize.toggle": "Abrir o menu 'Classificar em …'",
    "interactions.conversation-action-bar-component.actions.checkAll": "Selecionar tudo",
    "interactions.conversation-action-bar-component.actions.delete.display": "Eliminar",
    "interactions.conversation-action-bar-component.actions.delete.title": "Eliminar todas as conversas selecionadas",
    "interactions.conversation-action-bar-component.actions.markAs.display": "Assinalar como …",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.display": "Assinalar como lida",
    "interactions.conversation-action-bar-component.actions.markAs.markAsRead.title": "Assinalar todas as conversas selecionadas como lidas",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.display": "Assinalar como Não lida",
    "interactions.conversation-action-bar-component.actions.markAs.markAsUnread.title": "Assinalar todas as conversas selecionadas como não lidas",
    "interactions.conversation-action-bar-component.actions.markAs.toggle": "Abrir o menu 'Assinalar como …'",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.display": "Eliminar da reciclagem",
    "interactions.conversation-action-bar-component.actions.removeFromTrash.title": "Eliminar todas as conversas selecionadas da reciclagem",
    "interactions.conversation-action-bar-component.actions.restore.display": "Recuperar",
    "interactions.conversation-action-bar-component.actions.restore.title": "Recuperar todas as conversas selecionadas",
    "interactions.conversation-action-bar-component.actions.unarchive.display": "Restaurar",
    "interactions.conversation-action-bar-component.actions.unarchive.title": "Restaurar todas as conversas selecionadas",
    "interactions.conversation-action-bar-component.actions.uncheckAll": "Desselecionar tudo",
    "interactions.conversation-action-bar-component.search.button": "Filtrar as conversas pelo texto introduzido",
    "interactions.conversation-action-bar-component.search.input": "Introduzir o texto a ser pesquisado",
    "interactions.conversation-action-bar-component.sort.sortBy": "Ordenar por:",
    "interactions.conversation-line-component.buttons.check": "Selecionar a conversa.",
    "interactions.conversation-line-component.buttons.uncheck": "Desselecionar a conversa.",
    "interactions.conversation-line-component.conversationSubject": "Assunto da conversa.",
    "interactions.conversation-line-component.hasAttachments": "A conversa tem, pelo menos, uma mensagem com um anexo.",
    "interactions.conversation-line-component.isRead.read": "A última mensagem da conversa está como lida.",
    "interactions.conversation-line-component.isRead.unread": "A última mensagem da conversa está como não lida.",
    "interactions.conversation-line-component.lastMessageReceivedDate": "Data da última mensagem recebida.",
    "interactions.conversation-line-component.lastMessageReceivedcontent": "Conteúdo da última mensagem recebida.",
    "interactions.conversation-line-component.lastMessageStatus.DRAFT": "A última mensagem da conversa é um rascunho.",
    "interactions.conversation-line-component.lastMessageStatus.RECEIVED": "A última mensagem da conversa é de um conselheiro.",
    "interactions.conversation-line-component.lastMessageStatus.SENT": "A última mensagem na conversa é da sua autoria.",
    "interactions.conversation-message-attachment.attachment": "Anexos",
    "interactions.conversation-message-attachment.download": "Descarregar",
    "interactions.conversation-message-attachment.dropdown-button-title": "Ações",
    "interactions.conversation-message-attachment.preview": "Pré-visualizar",
    "interactions.conversation-message.body.buttons.show-less.text": "--- ver a mensagem parcial ---",
    "interactions.conversation-message.body.buttons.show-less.title": "Apresentar a mensagem parcial",
    "interactions.conversation-message.body.buttons.show-more.text": "--- ver a mensagem completa ---",
    "interactions.conversation-message.body.buttons.show-more.title": "Apresentar a mensagem completa",
    "interactions.conversation-message.header.expand-button.label-expand": "Maximizar o cabeçalho",
    "interactions.conversation-message.header.expand-button.label-shrink": "Minimizar o cabeçalho",
    "interactions.conversation-message.header.expanded.channel": "Canal:",
    "interactions.conversation-message.header.expanded.channels.MAIL": "E-mail",
    "interactions.conversation-message.header.expanded.from": "De:",
    "interactions.conversation-message.header.expanded.receiveDate": "Recebida a:",
    "interactions.conversation-message.header.expanded.sendDate": "Enviada a:",
    "interactions.conversation-message.header.expanded.subject": "Assunto:",
    "interactions.conversation-message.header.expanded.tags": "Etiquetas:",
    "interactions.conversation-message.header.expanded.to": "Para:",
    "interactions.conversation-message.header.expanded.virus-scan": "Verificação antivírus:",
    "interactions.conversation-message.header.shrunken.from": "De:",
    "interactions.conversation-message.header.shrunken.to": "Para:",
    "interactions.conversation-message.header.title": "Informação sobre a mensagem",
    "interactions.conversation.action-bar.archive": "Arquivar a conversa",
    "interactions.conversation.action-bar.categorize": "Classificar a conversa",
    "interactions.conversation.action-bar.delete": "Eliminar a conversa.",
    "interactions.conversation.action-bar.download": "Exportar a conversa",
    "interactions.conversation.action-bar.expiring-date": "Esta conversa expira em:",
    "interactions.conversation.action-bar.mark-as-read": "Assinalar a conversa como lida",
    "interactions.conversation.action-bar.mark-as-unread": "Assinalar a conversa como não lida",
    "interactions.conversation.action-bar.print": "Imprimir a conversa",
    "interactions.conversation.action-bar.removeFromTrash": "Eliminar definitivamente a conversa",
    "interactions.conversation.action-bar.restore": "Recuperar a conversa",
    "interactions.conversation.action-bar.sort-asc": "Ordenar as mensagens por data, da mais antiga para a mais recente",
    "interactions.conversation.action-bar.sort-desc": "Ordenar as mensagens por data, da mais recente para a mais antiga",
    "interactions.conversation.action-bar.unarchive": "Recuperar a conversa",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Eliminar este anexo",
    "interactions.conversation.composer.attachments.buttons.download.title": "Descarregar este anexo",
    "interactions.conversation.composer.attachments.buttons.preview.title": "Visualizar o anexo",
    "interactions.conversation.composer.attachments.upload-in-progress": "(em curso)",
    "interactions.conversation.composer.buttons.add-attachment.title": "Adicionar um anexo",
    "interactions.conversation.composer.buttons.cancel.title": "Cancelar as alterações",
    "interactions.conversation.composer.buttons.cancel.value": "Cancelar",
    "interactions.conversation.composer.buttons.save.title": "Rascunho",
    "interactions.conversation.composer.buttons.save.value": "Rascunho",
    "interactions.conversation.composer.buttons.send.title": "Enviar a nova mensagem",
    "interactions.conversation.composer.buttons.send.value": "Enviar",
    "interactions.conversation.composer.notifications.attachment-uploaded": "O anexo foi guardado",
    "interactions.conversation.composer.notifications.empty-file": "Não pode carregar anexos vazios. Tente novamente com um ficheiro válido.",
    "interactions.conversation.composer.notifications.file-name-already-used": "Já existe um anexo com o mesmo nome.",
    "interactions.conversation.composer.notifications.message-size-limit": "Os anexos excedem o tamanho limite.",
    "interactions.conversation.composer.notifications.upload-in-progress": "Está a ser guardado um anexo, aguarde pela conclusão da transferência",
    "interactions.conversation.create.message-info.error.invalid-form": "O formulário é inválido, corrija-o e tente novamente.",
    "interactions.conversation.create.message-info.error.pop-up.leaving-page": "As suas alterações não foram gravadas, pretende sair da página?",
    "interactions.conversation.create.message-info.error.save.conversation": "Ocorreu um erro, a conversa não foi atualizada. Volte a tentar mais tarde.",
    "interactions.conversation.create.message-info.error.save.conversation-message": "Ocorreu um erro, a mensagem não foi criada. Volte a tentar mais tarde.",
    "interactions.conversation.create.message-info.error.subject": "Deve selecionar um tema.",
    "interactions.conversation.create.message-info.info.save": "A conversa foi criada.",
    "interactions.conversation.create.subject": "Assunto:",
    "interactions.conversation.create.title": "Nova conversa",
    "interactions.conversation.draft.button.delete.label": "Eliminar",
    "interactions.conversation.draft.button.delete.title": "Eliminar o rascunho da conversa.",
    "interactions.conversation.draft.message-info.error.delete": "Ocorreu um erro, a conversa não foi eliminada. Volte a tentar mais tarde.",
    "interactions.conversation.draft.message-info.error.invalid-form": "O formulário é inválido, corrija-o e tente novamente.",
    "interactions.conversation.draft.message-info.error.save.conversation": "Ocorreu um erro, o rascunho não foi guardado. Volte a tentar mais tarde.",
    "interactions.conversation.draft.message-info.error.save.conversation-message": "Ocorreu um erro, o rascunho não foi guardado. Volte a tentar mais tarde.",
    "interactions.conversation.draft.message-info.info.delete": "A conversa foi eliminada.",
    "interactions.conversation.draft.message-info.info.pop-up.delete": "Pretende realmente eliminar este rascunho?",
    "interactions.conversation.draft.message-info.info.save": "O seu pedido está guardado em rascunho.",
    "interactions.conversation.draft.title": "Editação de uma conversa em rascunho",
    "interactions.conversation.errors.delete": "Não é possível eliminar o rascunho. Volte a tentar mais tarde.",
    "interactions.conversation.errors.save": "Ocorreu um erro, a mensagem não foi gravada. Volte a tentar mais tarde.",
    "interactions.conversation.errors.send": "Ocorreu um erro, a mensagem não foi enviada. Volte a tentar mais tarde.",
    "interactions.conversation.header.message-number": "{nb, plural, =0 {nenhum mensagem} =1 {1 mensagem} other {# mensagems}}",
    "interactions.conversation.header.status.CLOSED": "Arquivada",
    "interactions.conversation.header.status.DELETED": "Eliminada",
    "interactions.conversation.header.status.INIT": "Rascunho",
    "interactions.conversation.header.status.IN_PROGRESS": "Em curso",
    "interactions.conversation.header.status.TO_ARCHIVE": "Eliminada definitivamente",
    "interactions.conversation.header.status.title": "Estatuto",
    "interactions.conversation.header.unread": "Não lida",
    "interactions.conversation.message-info.confirm.pop-up.delete": "Pretende realmente enviar esta conversa para a reciclagem?",
    "interactions.conversation.message-info.error.archive": "Ocorreu um erro ao arquivar. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.categorize": "Ocorreu um erro ao classificar. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.delete": "Ocorreu um erro, a conversa não foi eliminada. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.download": "Ocorreu um erro ao exportar a conversa, tente novamente mais tarde.",
    "interactions.conversation.message-info.error.markAsRead": "Ocorreu um erro ao assinalar a conversa como lida. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.markAsUnread": "Ocorreu um erro ao assinalar a conversa como não lida. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.pop-up.archive": "Pretende arquivar esta conversa?",
    "interactions.conversation.message-info.error.pop-up.delete": "Pretende realmente eliminar esta conversa? Esta ação é irreversível.",
    "interactions.conversation.message-info.error.pop-up.leaving-page": "As suas alterações não foram gravadas, pretende sair da página?",
    "interactions.conversation.message-info.error.print": "Ocorreu um erro ao imprimir a conversa, volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.removeFromTrash": "Ocorreu um erro ao eliminar definitivamente a conversa. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.restore": "Ocorreu um erro ao recuperar a conversa. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.error.unarchive": "Ocorreu um erro ao restaurar a conversa. Volte a tentar mais tarde.",
    "interactions.conversation.message-info.info.archive": "A conversa foi arquivada.",
    "interactions.conversation.message-info.info.categorize": "A conversa foi classificada.",
    "interactions.conversation.message-info.info.delete": "A conversa foi eliminada.",
    "interactions.conversation.message-info.info.markAsRead": "A conversa foi assinalada como lida.",
    "interactions.conversation.message-info.info.markAsUnread": "A conversa foi assinalada como não lida.",
    "interactions.conversation.message-info.info.pop-up-removeFromTrash": "Pretende realmente eliminar definitivamente esta conversa?",
    "interactions.conversation.message-info.info.readOnly": "Esta mensagem é somente leitura.",
    "interactions.conversation.message-info.info.removeFromTrash": "A conversa foi eliminada definitivamente.",
    "interactions.conversation.message-info.info.restore": "A conversa foi recuperada.",
    "interactions.conversation.message-info.info.unarchive": "A conversa foi restaurada.",
    "interactions.conversation.showMore.button": "Ver as mensagens mais antigas",
    "interactions.conversation.showMore.message-info.error.showMoreMessages": "Ocorreu um erro, as mensagens não foram carregadas.  Volte a tentar mais tarde.",
    "interactions.conversation.success.delete": "O rascunho foi eliminado",
    "interactions.conversation.success.save": "O rascunho foi guardado",
    "interactions.conversation.success.send": "A mensagem foi enviada.",
    "interactions.conversations.search.list.title": "Conversas",
    "interactions.conversations.search.message-info.error.archive": "Ocorreu um erro ao arquivar. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.error.categorize": "Ocorreu um erro ao classificar. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.error.delete": "Ocorreu um erro, a conversa não foi eliminada. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.error.markAsRead": "Ocorreu um erro ao assinalar como lida. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.error.markAsUnread": "Ocorreu um erro ao assinalar como não lida. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.error.removeFromTrash": "Ocorreu um erro, a conversa não foi eliminada. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.error.restore": "Ocorreu um erro ao restaurar. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.error.unarchive": "Ocorreu um erro ao restaurar. Volte a tentar mais tarde.",
    "interactions.conversations.search.message-info.info.archive": "O arquivamento foi efetuado.",
    "interactions.conversations.search.message-info.info.categorize": "A classificação foi efetuada.",
    "interactions.conversations.search.message-info.info.delete": "A eliminação foi efetuada.",
    "interactions.conversations.search.message-info.info.markAsRead": "A marcação como lida foi efetuada.",
    "interactions.conversations.search.message-info.info.markAsUnread": "A marcação como não lida foi efetuada.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "Pretende realmente efetuar a eliminação?",
    "interactions.conversations.search.message-info.info.removeFromTrash": "A eliminação definitiva foi efetuada.",
    "interactions.conversations.search.message-info.info.restore": "A restauração foi efetuada.",
    "interactions.conversations.search.message-info.info.unarchive": "A restauração foi efetuada.",
    "interactions.conversations.sort.lastMessageReceivedDate.asc": "A mais antiga",
    "interactions.conversations.sort.lastMessageReceivedDate.desc": "A mais recente",
    "interactions.forbidden-interlocutor": "O acesso a este utilizador não está autorizado",
    "interactions.label.categorize-component.buttons.close": "Fechar",
    "interactions.label.categorize-component.buttons.manage": "Gerir as minhas pastas pessoais",
    "interactions.label.categorize-component.buttons.save": "Guardar",
    "interactions.label.categorize-component.header.text": "As minhas pastas pessoais",
    "interactions.label.categorize-component.header.title": "As minhas pastas pessoais",
    "interactions.label.categorize-component.loading": "A carregar",
    "interactions.label.categorize-component.no-data": "Nenhuma pasta pessoal a ser apresentada",
    "interactions.label.popup-edit-component.buttons.close": "Cancelar",
    "interactions.label.popup-edit-component.buttons.save": "Guardar",
    "interactions.label.popup-edit-component.fields.color.label-color-1": "Cor 1",
    "interactions.label.popup-edit-component.fields.color.label-color-2": "Cor 2",
    "interactions.label.popup-edit-component.fields.color.label-color-3": "Cor 3",
    "interactions.label.popup-edit-component.fields.color.label-color-4": "Cor 4",
    "interactions.label.popup-edit-component.fields.color.label-color-5": "Cor 5",
    "interactions.label.popup-edit-component.fields.color.text": "Cor:",
    "interactions.label.popup-edit-component.fields.color.title": "Cor",
    "interactions.label.popup-edit-component.fields.description.text": "Descrição:",
    "interactions.label.popup-edit-component.fields.description.title": "Descrição",
    "interactions.label.popup-edit-component.fields.name.text": "Nome:",
    "interactions.label.popup-edit-component.fields.name.title": "Nome",
    "interactions.label.popup-edit-component.header.edit.text": "Pasta pessoal: [{name}]",
    "interactions.label.popup-edit-component.header.edit.title": "Pasta pessoal",
    "interactions.label.popup-edit-component.header.new.text": "Nova pasta pessoal",
    "interactions.label.popup-edit-component.header.new.title": "Nova pasta pessoal",
    "interactions.label.popup-edit-component.message-info.error.duplication": "Este nome já é utilizado para outra pasta pessoal.",
    "interactions.label.popup-edit-component.message-info.error.invalid-form": "O formulário é inválido, corrija-o e tente novamente.",
    "interactions.label.settings.buttons.add.label": "Adicionar",
    "interactions.label.settings.buttons.add.title": "Adicionar",
    "interactions.label.settings.buttons.delete.label": "Eliminar",
    "interactions.label.settings.buttons.delete.title": "Eliminar",
    "interactions.label.settings.buttons.edit.label": "Editar",
    "interactions.label.settings.buttons.edit.title": "Editar",
    "interactions.label.settings.fields.defaultText.text": "Aa",
    "interactions.label.settings.fields.defaultText.title": "Exemplo de etiqueta",
    "interactions.label.settings.fields.description.title": "Descrição",
    "interactions.label.settings.fields.name.title": "Nome da pasta pessoal",
    "interactions.label.settings.message-info.error.create": "Ocorreu um erro, a pasta pessoal não foi adicionada.Volte a tentar mais tarde.",
    "interactions.label.settings.message-info.error.delete": "Ocorreu um erro, a pasta pessoal não foi eliminada. Volte a tentar mais tarde.",
    "interactions.label.settings.message-info.error.edit": "Ocorreu um erro, a pasta pessoal não foi alterada. Volte a tentar mais tarde.",
    "interactions.label.settings.message-info.error.get-404": "Ocorreu um erro, a pasta pessoal não foi encontrada. Volte a tentar mais tarde.",
    "interactions.label.settings.message-info.error.unknow": "Ocorreu um erro ao carregar a página . Verifique e volte a tentar mais tarde.",
    "interactions.label.settings.message-info.info.create": "A pasta pessoal foi criada.",
    "interactions.label.settings.message-info.info.delete": "A pasta pessoal foi eliminada.",
    "interactions.label.settings.message-info.info.deleteConfirm": "Pretende eliminar a pasta pessoal [{name}]?",
    "interactions.label.settings.message-info.info.edit": "A pasta pessoal foi atualizada.",
    "interactions.label.settings.search.results.title": "Pastas pessoais",
    "interactions.message-attachment.message-info.error.download": "O carregamento falhou. Volte a tentar mais tarde.",
    "interactions.message-composer-attachments.multiple-upload.error": "{nbUploadsFailed, plural, one {# anexo não foi} other {# anexos não foram}} {nbUploadsFailed, plural, one {carregado} other {carregados}}.",
    "interactions.message-composer-attachments.multiple-upload.success": "{nbUploadsPassed, plural, one {O anexo foi carregado} other {# anexos carregados}} com sucesso.",
    "interactions.message-editor.message-info.info.pop-up.replace-subject": "Confirma a eliminação da mensagem em fase de edição para a substituir pelo novo tema selecionado?",
    "interactions.preview-component.buttons.close": "Voltar",
    "interactions.preview-component.error": "Ocorreu um erro ao carregar a imagem. Volte a tentar mais tarde.",
    "interactions.preview-component.loading": "A carregar",
    "interactions.preview-component.title.uploadDate": "Carregado a",
    "interactions.preview-component.title.uploader": "Carregado por",
    "interactions.side-menu.conversations.label": "Conversas",
    "interactions.side-menu.conversations.lines.archived": "Arquivadas",
    "interactions.side-menu.conversations.lines.draft": "Rascunho",
    "interactions.side-menu.conversations.lines.mine": "As minhas conversas",
    "interactions.side-menu.conversations.lines.sent": "Enviadas",
    "interactions.side-menu.conversations.lines.trashed": "Reciclagem",
    "interactions.side-menu.conversations.sr-only": "conversas",
    "interactions.side-menu.labels.configurationButton": "Configuração",
    "interactions.side-menu.labels.label": "Pastas pessoais",
    "interactions.side-menu.new-conversation": "Nova Conversa",
    "profile.infos.header": "Preferências pessoais",
    "profile.infos.howto.messaging": "Para voltar ao seu sistema de mensagens seguro, clique no menu pendente 'Perfil' no menu lateral e, a seguir, selecione 'Sistema de mensagens'.",
    "profile.infos.howto.notifications": "Para alterar a receção de notificações externas, clique em 'Notificações' no menu lateral.",
    "profile.infos.howto.warnings": "Para alterar o funcionamento das mensagens de aviso, clique em 'Preferências' no menu lateral.",
    "profile.infos.introduction": "Encontra-se no módulo de gestão dos seus dados pessoais.",
    "profile.notifications.NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nova mensagem recebida",
    "profile.preferences.closeErrorMessage.label": "Encerramento das mensagens de erro:",
    "profile.preferences.closeErrorMessage.title": "Encerramento das mensagens de erro ",
    "profile.preferences.closeErrorMessages.false": "Automático",
    "profile.preferences.closeErrorMessages.true": "Manual",
    "profile.preferences.edit.cancel": "Cancelar",
    "profile.preferences.edit.save": "Guardar",
    "profile.preferences.header": "Preferências",
    "profile.preferences.message-info.info.save": "As preferências foram atualizadas.",
    "profile.preferences.view.edit": "Alterar",
    "profile.side-bar.sign-out": "Desconexão",
    "profile.side-bar.title": "Parâmetros da conta",
    "profile.side-menu.preferences": "Preferências",
    "profile.side-menu.subscriptions": "Notificações",
    "profile.subscriptions.edit.cancel": "Cancelar",
    "profile.subscriptions.edit.save": "Guardar",
    "profile.subscriptions.message-info.error.loading": "Ocorreu um erro ao carregar as subscrições de notificações. Volte a tentar mais tarde.",
    "profile.subscriptions.message-info.error.save": "Ocorreu um erro ao guardar as subscrições de notificações. Volte a tentar mais tarde.",
    "profile.subscriptions.message-info.info.save": "As suas subscrições de notificações foram atualizadas.",
    "profile.subscriptions.view.edit": "Alterar",
    "profile.subscriptions.view.state.disabled": "Inativa",
    "profile.subscriptions.view.state.enabled": "Ativa",
    "shared.components.a11y.modals.alert-modal.closeButtonText": "Fechar",
    "shared.components.a11y.modals.alert-modal.closeButtonTitle": "Fechar a janela",
    "shared.components.dropdown.default-button-title": "Ações",
    "shared.components.form-component.form-fields-required-label": "Os campos assinalados com * são obrigatórios.",
    "shared.components.form-group-component.missing-text": "O campo é obrigatório.",
    "shared.components.modals.document-preview.error-message": "Ocorreu um erro ao carregar a imagem. Volte a tentar mais tarde.",
    "shared.components.modals.document-preview.modal-title": "Pré-visualizar o ficheiro",
    "shared.components.modals.document-preview.select-page": "Selecionar uma página",
    "shared.components.modals.document-preview.upload-by": "Carregado por {actor} em {date}",
    "shared.components.modals.document-preview.zoom-in": "Aumentar o zoom",
    "shared.components.modals.document-preview.zoom-out": "Diminuir o zoom",
    "shared.components.modals.image-preview.error-message": "Ocorreu um erro ao carregar a imagem. Volte a tentar mais tarde.",
    "shared.components.modals.image-preview.loading-message": "A carregar",
    "shared.components.modals.image-preview.modal-title": "Pré-visualizar",
    "shared.components.modals.image-preview.upload-by": "Carregado por",
    "shared.components.modals.image-preview.upload-date": "Carregado a",
    "shared.components.modals.image-preview.zoom-in": "Aumentar o zoom",
    "shared.components.modals.image-preview.zoom-out": "Diminuir o zoom",
    "shared.components.modals.modal-component.modal-cancel": "Cancelar",
    "shared.components.modals.modal-component.modal-confirm": "Confirmar",
    "shared.components.modals.title.closeButton": "Fechar esta mensagem",
    "shared.components.result-list.results.error": "Ocorreu um erro ao carregar a página . Verifique e volte a tentar mais tarde.",
    "shared.components.result-list.results.loading": "A carregar",
    "shared.components.result-list.results.none": "Nenhum resultado",
    "shared.components.result-list.results.wait": "Aguarde",
    "shared.components.text-editor.actions-list.f-aligncenter": "Centrar",
    "shared.components.text-editor.actions-list.f-alignleft": "Alinhar à esquerda",
    "shared.components.text-editor.actions-list.f-alignright": "Alinhar à direita",
    "shared.components.text-editor.actions-list.f-bold": "Negrito",
    "shared.components.text-editor.actions-list.f-color.help": "Cor",
    "shared.components.text-editor.actions-list.f-color.label": "Alterar a cor do texto",
    "shared.components.text-editor.actions-list.f-font.help": "Tipo de letra",
    "shared.components.text-editor.actions-list.f-font.label": "Escolha a família de tipos de letra",
    "shared.components.text-editor.actions-list.f-gallery": "Adicionar uma imagem",
    "shared.components.text-editor.actions-list.f-italic": "Itálico",
    "shared.components.text-editor.actions-list.f-link.help": "Adicionar uma ligação",
    "shared.components.text-editor.actions-list.f-link.ok": "OK",
    "shared.components.text-editor.actions-list.f-link.url": "URL",
    "shared.components.text-editor.actions-list.f-list": "Passar da/para a lista",
    "shared.components.text-editor.actions-list.f-size.help": "Tamanho do tipo de letra",
    "shared.components.text-editor.actions-list.f-size.label": "Escolha o tamanho do tipo de letra",
    "shared.components.text-editor.actions-list.f-spellCheck.check-spelling": "Verificar a ortografia",
    "shared.components.text-editor.actions-list.f-spellCheck.choose-language": "Escolha um idioma",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore": "Ignorar",
    "shared.components.text-editor.actions-list.f-spellCheck.ignore-all": "Ignorar tudo",
    "shared.components.text-editor.actions-list.f-underline": "Sublinhado",
    "shared.components.text-editor.actions-list.f-upload.help": "Adicionar um anexo",
    "shared.components.text-editor.message-composer-attachments.delete": "Eliminar este anexo",
    "shared.components.text-editor.message-composer-attachments.download": "Descarregar este anexo",
    "shared.components.text-editor.message-composer-attachments.dropdown-button-title": "Ações",
    "shared.components.text-editor.message-composer-attachments.message-info.error.bad-content-type": "O tipo do anexo não é autorizado.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.download": "Problema de comunicação, carregamento interrompido. Volte a tentar mais tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.empty-file": "Problema de comunicação, carregamento interrompido. Volte a tentar mais tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.failed-to-upload": "Problema de comunicação, carregamento interrompido. Volte a tentar mais tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.file-name-already-used": "Não pode carregar anexos vazios. Tente novamente com um ficheiro válido.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.maxAttachmentReached": "Atingiu o número máximo de anexos autorizado.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.message-size-limit": "Os anexos excedem o tamanho limite.",
    "shared.components.text-editor.message-composer-attachments.message-info.error.preview": "O carregamento da imagem falhou, volte a tentar mais tarde.",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploaded": "O anexo foi guardado",
    "shared.components.text-editor.message-composer-attachments.message-info.info.file-uploading": "A guardar…",
    "shared.components.text-editor.message-composer-attachments.preview": "Visualizar o anexo",
    "shared.file-uploader.error.EMPTY_ATTACHMENT": "Não pode carregar anexos vazios. Tente novamente com um ficheiro válido.",
    "shared.file-uploader.error.NOT_ALLOWED_CONTENT_TYPE": "O tipo do anexo não é autorizado.",
    "shared.file-uploader.error.addFile": "Ocorreu um erro, o anexo não foi adicionado. Volte a tentar mais tarde.",
    "shared.file-uploader.error.contentType": "O tipo do anexo não é autorizado.",
    "shared.file-uploader.error.length": "O ficheiro que pretende carregar é demasiado grande. ",
    "shared.helpers.date-short-view.today": "Hoje, {hour}",
    "shared.numeric-unit.billion": "G",
    "shared.numeric-unit.million": "M",
    "shared.numeric-unit.thousand": "K",
    "shared.pagination": "paginação",
    "shared.skip-link": "Ir para a página principal (Sair do menu)",
    "side-panel.burger.label": "Mostrar/Ocultar o menu",
    "side-panel.hide-menu": "Ocultar o menu lateral",
    "side-panel.show-menu": "Mostrar o menu lateral",
    "side-panel.toggle-title": "Abre/fecha o menu",
    "sso-error.text": "Ocorreu um erro durante a ligação. Feche esta janela e tente novamente a partir do seu espaço pessoal.",
    "sso-error.title": "Erro de conexão",
    "topbar.message-info.log-out-confirmation": "Tem a certeza de que pretende terminar a sessão?",
    "topbar.module.interactions": "Mensagens",
    "topbar.module.profile": "Perfil",
    "topbar.nav.label": "Barra multimódulos",
    "topbar.organisation.name": "Sistema de mensagens seguro Segurado"
  };
  _exports.default = _default;
});