define("ticust-s2h-web-application/templates/components/ticust-web-module-interactions/interlocutors/interlocutor/conversations/themes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qIl+Bj0j",
    "block": "{\"symbols\":[\"theme\"],\"statements\":[[7,\"fieldset\"],[9],[0,\"\\n  \"],[7,\"legend\"],[9],[1,[27,\"t\",[\"interactions.conversation.create.subject\"],null],false],[0,\" \"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"tree section\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"themes\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"ticust-web-module-interactions/interlocutors/interlocutor/conversations/theme\",null,[[\"theme\",\"selection\",\"conversation\"],[[22,1,[]],[23,[\"selection\"]],[23,[\"conversation\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ticust-s2h-web-application/templates/components/ticust-web-module-interactions/interlocutors/interlocutor/conversations/themes.hbs"
    }
  });
  _exports.default = _default;
});