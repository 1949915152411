define("ticust-s2h-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/conversation", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/conversation"], function (_exports, _conversation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/conversation',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversation.default.reopen({
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName != "ticust-web-module-authentication.logout" && this.get('controller.isDirty') && !this.get('controller.isArchiving') && !this.get('controller.isDeleting') && !confirm(this.get('intl').t('interactions.conversation.message-info.error.pop-up.leaving-page'))) {
          transition.abort();
          return;
        }
        this.get('controller.messages').forEach(function (message) {
          if (message.get('isNew')) {
            message.deleteRecord();
          } else if (message.get('hasDirtyAttributes')) {
            message.rollbackAttributes();
          }
        });
      }
    }
  });
});