define("ticust-s2h-web-application/services/config", ["exports", "ti-web-shared/mixins/config-service-mixin", "ticust-s2h-web-application/config/environment", "ticust-web-common/config/environment", "ti-web-shared/config/environment", "ticust-web-module-authentication/config/environment", "ticust-web-module-interactions/config/environment", "ticust-web-module-profile/config/environment", "ticust-web-module-projects/config/environment"], function (_exports, _configServiceMixin, _environment, _environment2, _environment3, _environment4, _environment5, _environment6, _environment7) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend(_configServiceMixin.default, {
    config: {},
    getConfigArray: function getConfigArray() {
      return [_environment3.default, _environment2.default, _environment4.default, _environment5.default, _environment6.default, _environment7.default, _environment.default];
    },
    getConfigOverride: function getConfigOverride(config) {
      return config;
    }
  });
  _exports.default = _default;
});