define("ticust-s2h-web-application/services/timeout-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    myUserIdle: Ember.inject.service('idle-user'),
    tiLogger: Ember.inject.service('logger-service'),
    session: Ember.inject.service(),
    load: function load() {
      var _this = this;
      this.myUserIdle.on('idleChanged', function (isIdle) {
        if (isIdle && _this.get('session').isAuthenticated) {
          _this.get('session').invalidate().then(function () {
            _this.get('ajax').raw('/api/customer/authentication/sso/logout', {
              method: 'POST'
            }).then(function () {
              _this.tiLogger.info("The idle user has been logged out.");
            });
          });
        }
      });
    }
  });
  _exports.default = _default;
});