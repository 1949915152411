define("ticust-s2h-web-application/models/enums/project-part-document-status", ["exports", "ti-web-shared/models/enums/project-part-document-status"], function (_exports, _projectPartDocumentStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _projectPartDocumentStatus.default;
    }
  });
});