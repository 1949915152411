define("ticust-s2h-web-application/templates/components/ticust-web-module-interactions/editor/message-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FuPCpS/M",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ti-web-shared/editor/editor\",null,[[\"onEditorLoaded\",\"text\",\"isReadOnly\",\"onTextChanged\",\"uploaderUrl\",\"downloadPath\",\"uploadAttachmentOnStartUpload\",\"uploadAttachmentOnDidUpload\",\"uploadAttachmentDidError\",\"deleteAttachment\",\"maxSizeOfAttachment\",\"maxNumOfAttachments\",\"maxSizeOfMessage\",\"allowedAttachmentTypes\",\"attachments\",\"displayGallery\",\"allowDuplicateFileName\",\"displayActionBar\"],[[23,[\"onEditorLoaded\"]],[23,[\"text\"]],[23,[\"isReadOnly\"]],[23,[\"onTextChanged\"]],[23,[\"uploaderUrl\"]],[23,[\"downloadPath\"]],[27,\"action\",[[22,0,[]],\"uploadAttachmentOnStartUpload\"],null],[27,\"action\",[[22,0,[]],\"uploadAttachmentOnDidUpload\"],null],[27,\"action\",[[22,0,[]],\"uploadAttachmentDidError\"],null],[27,\"action\",[[22,0,[]],\"deleteAttachment\"],null],[23,[\"maxSizeOfAttachment\"]],[23,[\"maxNumOfAttachments\"]],[23,[\"maxSizeOfMessage\"]],[23,[\"allowedAttachmentTypes\"]],[23,[\"attachments\"]],false,false,true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ticust-s2h-web-application/templates/components/ticust-web-module-interactions/editor/message-editor.hbs"
    }
  });
  _exports.default = _default;
});