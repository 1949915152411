define("ticust-s2h-web-application/initializers/ti-web-shared/mixins/message-composer-attachments", ["exports", "ti-web-shared/mixins/message-composer-attachments"], function (_exports, _messageComposerAttachments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'mixin/ti-web-shared/message-composer-attachments',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _messageComposerAttachments.default.reopen({
    uploadFile: function uploadFile(attachment, file) {
      var serverUrl = this.get('serverUrl') ? this.get('serverUrl') : window.location.protocol + '//' + window.location.host + this.get('uploaderUrl');
      var xhr = new XMLHttpRequest();
      var formData = new FormData();
      var authorization = this.get('identity.ajaxHeaders');
      var self = this;
      xhr.open('POST', serverUrl, true);
      Object.keys(authorization).forEach(function (header) {
        xhr.setRequestHeader(header, authorization[header]);
      });
      xhr.addEventListener('readystatechange', function (e) {
        if (xhr.readyState == 4 && xhr.status >= 200 && xhr.status < 300) {
          var event = JSON.parse(xhr.responseText);
          attachment.set('isUploaded', true);
          attachment.set('s3Id', event.contentRef);
          self.uploadAttachmentOnDidUpload(file, event, attachment);
          self.showSuccess('shared.components.text-editor.' + 'message-composer-attachments.message-info.info.file-uploaded');
        } else if (xhr.readyState == 4) {
          var _event = JSON.parse(xhr.responseText);
          var code = _event.metadatas && _event.metadatas.ATTACHMENT_STATUS ? _event.metadatas.ATTACHMENT_STATUS : _event.code;
          self.showError('shared.file-uploader.error.' + code);
          self.get('_attachments').removeObject(attachment);
          self.uploadAttachmentDidError(file, _event, attachment);
        }
      });
      formData.append('data', file, file.name);
      xhr.send(formData);
    }
  });
});