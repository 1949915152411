define("ticust-s2h-web-application/utils/part-utils", ["exports", "libmime", "ticust-web-common/utils/part-utils"], function (_exports, _libmime, _partUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _partUtils.default;
    }
  });
});