define("ticust-s2h-web-application/models/enums/owner-type", ["exports", "ti-web-shared/models/enums/owner-type"], function (_exports, _ownerType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ownerType.default;
    }
  });
});