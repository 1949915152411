define("ticust-s2h-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/drafts/draft", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/drafts/draft"], function (_exports, _draft) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/drafts/draft',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _draft.default.reopen({
    actions: {
      updateConversationInRoute: function updateConversationInRoute(messageContent, status) {
        var _this = this;
        this.set('controller.isSaving', true);
        var conversation = this.get('controller.conversation');
        var message = conversation.get('messages.lastObject');

        // Update conversation
        if (status === 'SENT') {
          conversation.set('status', 'IN_PROGRESS');
        }

        // Update message
        message.set('status', status);
        message.set('messageContent', messageContent);
        message.set('receiveDate', new Date());

        // Save conversation & message
        conversation.save().then(function (conversation) {
          return message.save().then(function () {
            if (status === 'SENT') {
              _this.showSuccess('interactions.conversation.create.message-info.info.save');
              _this.transitionTo('interlocutors.interlocutor.conversations.conversation', _this.modelFor('interlocutors.interlocutor'), conversation.id);
            }
          }).catch(function (error) {
            _this.showErrorAndLog('interactions.conversation.draft.message-info.error.save.conversation-message', 'An error occurred while saving conversation-message in conversation / draft.', error);
            message.rollbackAttributes();
          });
        }).catch(function (error) {
          _this.showErrorAndLog('interactions.conversation.draft.message-info.error.save.conversation', 'An error occurred while saving conversation in conversation / draft.', error);
          conversation.rollbackAttributes();
          message.rollbackAttributes();
        }).finally(function () {
          _this.set('controller.isSaving', false);
        });
      },
      willTransition: function willTransition(transition) {
        if (transition.targetName != "ticust-web-module-authentication.logout" && this.get('controller.isDirty') && !this.get('controller.isDeleting')) {
          if (!confirm(this.get('intl').t('interactions.conversation.create.message-info.error.pop-up.leaving-page'))) {
            transition.abort();
            return;
          }
          this.get('controller.conversation').rollbackAttributes();
          if (this.get('controller.newMessage')) {
            this.get('controller.newMessage').rollbackAttributes();
          }
        }
      }
    }
  });
});