define("ticust-s2h-web-application/components/ti-web-shared/img-avatar", ["exports", "ti-web-shared/components/ti-web-shared/img-avatar"], function (_exports, _imgAvatar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _imgAvatar.default;
    }
  });
});